import TextField from "../../components/ds/Textfield";
import EyeSlashO from "../../assets/icons/Eye-slash-o";
import EyeO from "../../assets/icons/Eye-o";
import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { useTheme } from "styled-components";
import Button from "../../components/ds/Button";
import AccountService from "../../services/account.service";
import CheckB from "../../assets/icons/Check-b";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import CopyO from "../../assets/icons/Copy-o";
import ReactCodeInput from "react-verification-code-input";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect";
import useAuth from "../../useAuth";
import { useCommon } from "../../context/commonContext";
import toast from "react-hot-toast";

export default function Security() {
  const navigate = useNavigate();
  const [showCurrentPass, setShowCurrentPass] = React.useState(false);
  const [showNewtPass, setShowNewPass] = React.useState(false);
  const [showConfirmPass, setShowConfirmPass] = React.useState(false);
  const text80Body = useTheme().colors.text80Body;
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const text90 = useTheme().colors.text90;
  const text20 = useTheme().colors.text20;
  const surface80Divider = useTheme().colors.surface80Divider;
  const error50Main = useTheme().colors.error50Main;
  const success50Main = useTheme().colors.success50Main;
  const primary50Main = useTheme().colors.primary50Main;
  const surface70 = useTheme().colors.surface70;
  const surface50Background = useTheme().colors.surface50Background;
  const warning40 = useTheme().colors.warning40;
  const [toggle, setToggle] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [step, setStep] = React.useState("first");
  const [secret_key, setSecret_key] = React.useState("");
  const [qr_code, setQr_code] = React.useState("");
  const [copy, setCopy] = useState<any>("");
  const [otp, setOTP] = useState<any>("");
  const { token } = useAuth();
  const { profile } = useCommon();
  useEffect(() => {
    profile && setToggle(profile.google_2fa_status);
  }, []);


  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
      maxWidth: "600px"
    },
  };

  function TwoFactorAuth() {
    if (!toggle) {
      setIsOpen(true);
      setStep("first");
    } else {
      setIsOpen2(true);
    }
  }

  function handleChange(vals: any) {
    if (vals.length >= 6) {
      setOTP(vals);
    } else if (vals.length === 0) {
    }
  }

  function createMarkup() {
    return { __html: qr_code };
  }

  function Stepper() {
    if (step === "first") {
      return (
        <>
          <div
            className={"text-base font-normal mb-2 " + `text-[${text80Body}]`}
          >
            To activate two-factor authentication, you must install Google
            Authenticator on your phone.
          </div>

          <div className={"flex gap-3 my-3"}>
            <a
              className={"w-1/2"}
              href={
                "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              }
              target={"_blank"}
            >
              <div
                className={
                  "w-full flex items-center border border-solid rounded-2xl py-4 px-3 " +
                  `border-[${surface80Divider}]`
                }
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.20612 0.490569C1.8464 0.877185 1.63965 1.47224 1.63965 2.24547V29.749C1.63965 30.5222 1.8464 31.1173 2.21957 31.4904L2.31707 31.5728L17.7279 16.162V15.8157L2.30362 0.408203L2.20612 0.490569Z"
                    fill="url(#paint0_linear_1405_46437)"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.8542 21.3175L17.7139 16.1755V15.8158L22.8559 10.6738L22.9668 10.7427L29.0484 14.1988C30.7899 15.1804 30.7899 16.7975 29.0484 17.7926L22.9668 21.2486C22.9651 21.2486 22.8542 21.3175 22.8542 21.3175Z"
                    fill="url(#paint1_linear_1405_46437)"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.9657 21.251L17.7144 15.998L2.20605 31.5064C2.77253 32.1149 3.72562 32.1838 4.79134 31.5888L22.9657 21.251Z"
                    fill="url(#paint2_linear_1405_46437)"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.9657 10.7475L4.79134 0.423135C3.7273 -0.185365 2.77253 -0.102999 2.20605 0.505501L17.7127 15.9987L22.9657 10.7475Z"
                    fill="url(#paint3_linear_1405_46437)"
                  />
                  <path
                    opacity="0.2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.854 21.1387L4.80403 31.3941C3.79547 31.974 2.89617 31.9337 2.31624 31.4075L2.21875 31.505L2.31624 31.5874C2.89617 32.1118 3.79547 32.1539 4.80403 31.5739L22.9784 21.2496L22.854 21.1387Z"
                    fill="black"
                  />
                  <path
                    opacity="0.12"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.0478 17.6151L22.8418 21.14L22.9527 21.251L29.0344 17.795C29.9051 17.2974 30.3337 16.6486 30.3337 15.998C30.2783 16.5931 29.8362 17.1596 29.0478 17.6151Z"
                    fill="black"
                  />
                  <path
                    opacity="0.25"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.79141 0.601637L29.0474 14.382C29.8357 14.8241 30.2778 15.404 30.3467 15.999C30.3467 15.3502 29.9181 14.6997 29.0474 14.2021L4.79141 0.421777C3.04996 -0.57334 1.63965 0.255364 1.63965 2.2456V2.42546C1.63965 0.435224 3.04996 -0.380032 4.79141 0.601637Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1405_46437"
                      x1="16.3441"
                      y1="1.94983"
                      x2="-8.34143"
                      y2="8.52731"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#00A0FF" />
                      <stop offset="0.007" stop-color="#00A1FF" />
                      <stop offset="0.26" stop-color="#00BEFF" />
                      <stop offset="0.512" stop-color="#00D2FF" />
                      <stop offset="0.76" stop-color="#00DFFF" />
                      <stop offset="1" stop-color="#00E3FF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1405_46437"
                      x1="31.3145"
                      y1="15.9964"
                      x2="1.2158"
                      y2="15.9964"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFE000" />
                      <stop offset="0.409" stop-color="#FFBD00" />
                      <stop offset="0.775" stop-color="#FFA500" />
                      <stop offset="1" stop-color="#FF9C00" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1405_46437"
                      x1="20.1102"
                      y1="18.8557"
                      x2="0.292547"
                      y2="52.21"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF3A44" />
                      <stop offset="1" stop-color="#C31162" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1405_46437"
                      x1="-1.69778"
                      y1="-8.65695"
                      x2="7.14347"
                      y2="6.23964"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#32A071" />
                      <stop offset="0.069" stop-color="#2DA771" />
                      <stop offset="0.476" stop-color="#15CF74" />
                      <stop offset="0.801" stop-color="#06E775" />
                      <stop offset="1" stop-color="#00F076" />
                    </linearGradient>
                  </defs>
                </svg>

                <div className={"pl-3"}>
                  <div className={"text-sm font-medium " + `text-[${text50}]`}>
                    Download from
                  </div>
                  <div
                    className={"text-base font-semibold " + `text-[${text90}]`}
                  >
                    Google Play
                  </div>
                </div>
              </div>
            </a>
            <a
              className={"w-1/2"}
              href={
                "https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
              }
              target={"_blank"}
            >
              <div
                className={
                  "w-full flex items-center border border-solid rounded-2xl py-4 px-3 " +
                  `border-[${surface80Divider}]`
                }
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.1928 10.9088C28.0072 11.0528 24.7304 12.8992 24.7304 17.0048C24.7304 21.7536 28.9 23.4336 29.0248 23.4752C29.0056 23.5776 28.3624 25.776 26.8264 28.016C25.4568 29.9872 24.0264 31.9552 21.8504 31.9552C19.6744 31.9552 19.1144 30.6912 16.6024 30.6912C14.1544 30.6912 13.284 31.9968 11.2936 31.9968C9.30323 31.9968 7.91443 30.1728 6.31763 27.9328C4.46803 25.3024 2.97363 21.216 2.97363 17.3376C2.97363 11.1168 7.01843 7.8176 10.9992 7.8176C13.1144 7.8176 14.8776 9.2064 16.2056 9.2064C17.4696 9.2064 19.4408 7.7344 21.8472 7.7344C22.7592 7.7344 26.036 7.8176 28.1928 10.9088ZM20.7048 5.1008C21.7 3.92 22.404 2.2816 22.404 0.6432C22.404 0.416 22.3848 0.1856 22.3432 0C20.724 0.0608 18.7976 1.0784 17.636 2.4256C16.724 3.4624 15.8728 5.1008 15.8728 6.7616C15.8728 7.0112 15.9144 7.2608 15.9336 7.3408C16.036 7.36 16.2024 7.3824 16.3688 7.3824C17.8216 7.3824 19.6488 6.4096 20.7048 5.1008Z"
                    fill="black"
                  />
                </svg>

                <div className={"pl-3"}>
                  <div className={"text-sm font-medium " + `text-[${text50}]`}>
                    Download from
                  </div>
                  <div
                    className={"text-base font-semibold " + `text-[${text90}]`}
                  >
                    Apple Store
                  </div>
                </div>
              </div>
            </a>
          </div>

          <div
            className={`w-full p-4 flex items-center my-3 rounded-2xl gap-4 bg-[${warning40}]`}
          >
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M29.0133 21.228L20.48 5.86797C19.3333 3.8013 17.7466 2.66797 16 2.66797C14.2533 2.66797 12.6666 3.8013 11.52 5.86797L2.98664 21.228C1.90664 23.188 1.78664 25.068 2.6533 26.548C3.51997 28.028 5.22664 28.8413 7.46664 28.8413H24.5333C26.7733 28.8413 28.48 28.028 29.3466 26.548C30.2133 25.068 30.0933 23.1746 29.0133 21.228Z"
                  fill="#ECBF1D"
                />
                <path
                  d="M16.0001 19.6667C15.4534 19.6667 15.0001 19.2133 15.0001 18.6667V12C15.0001 11.4533 15.4534 11 16.0001 11C16.5467 11 17.0001 11.4533 17.0001 12V18.6667C17.0001 19.2133 16.5467 19.6667 16.0001 19.6667Z"
                  fill="#ECBF1D"
                />
                <path
                  d="M16.0001 24C15.9201 24 15.8267 23.9867 15.7334 23.9733C15.6534 23.96 15.5734 23.9333 15.4934 23.8933C15.4134 23.8667 15.3334 23.8267 15.2534 23.7733C15.1867 23.72 15.1201 23.6667 15.0534 23.6133C14.8134 23.36 14.6667 23.0133 14.6667 22.6667C14.6667 22.32 14.8134 21.9733 15.0534 21.72C15.1201 21.6667 15.1867 21.6133 15.2534 21.56C15.3334 21.5067 15.4134 21.4667 15.4934 21.44C15.5734 21.4 15.6534 21.3733 15.7334 21.36C15.9067 21.32 16.0934 21.32 16.2534 21.36C16.3467 21.3733 16.4267 21.4 16.5067 21.44C16.5867 21.4667 16.6667 21.5067 16.7467 21.56C16.8134 21.6133 16.8801 21.6667 16.9467 21.72C17.1867 21.9733 17.3334 22.32 17.3334 22.6667C17.3334 23.0133 17.1867 23.36 16.9467 23.6133C16.8801 23.6667 16.8134 23.72 16.7467 23.7733C16.6667 23.8267 16.5867 23.8667 16.5067 23.8933C16.4267 23.9333 16.3467 23.96 16.2534 23.9733C16.1734 23.9867 16.0801 24 16.0001 24Z"
                  fill="#ECBF1D"
                />
              </svg>
            </div>

            <div className={"text-xs xl:text-base font-normal" + `text-[${text50}]`}>
              Please make sure you install the app from safe and trustable
              sources because you may lose access to your account and money.
            </div>
          </div>

          <Button
            text={"Next"}
            className={
              "flex w-full text-xs xl:text-base  mb-6 h-12 items-center justify-center "
            }
            buttonStyleType={"BPrimary"}
            onClick={() => {
              setStep("second");
              AccountService.generateKey(token)
                .then((response: any) => {
                  setSecret_key(response.data.secret_key);
                  setQr_code(response.data.qr_code);
                })
                .catch((err) => {
                  toast.error(err.response.data.message)
                });
            }}
          />
        </>
      );
    } else if (step === "second") {
      return (
        <>
          <div
            className={"text-base font-normal mb-2 " + `text-[${text80Body}]`}
          >
            Scan the QR-Code in Google Authenticator or just paste the code
            below.
          </div>

          <div className={"my-6 mx-auto"}>
            <div
              className={"flex justify-center mx-auto"}
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>
          <div className={"flex my-3 gap-3"}>
            <div
              className={
                "py-2 px-4 flex items-center xl:px-6 rounded-3xl text-xs xl:text-base font-medium h-9 xl:h-12 w-full " +
                `bg-[${surface70}]` +
                ` text-[${text50}]`
              }
            >
              {secret_key}
            </div>

            <CopyToClipboard
              text={secret_key}
              onCopy={() => setCopy({ copied: true })}
            >
              <Button
                text={"Copy"}
                buttonStyleType={"BPrimary"}
                className={
                  "flex text-xs xl:text-base items-center py-2 px-4 xl:px-6 h-9 xl:h-12 "
                }
                icon={<CopyO fill={text20} />}
              />
            </CopyToClipboard>
          </div>

          <div className={"flex my-6 gap-3"}>
            <Button
              text={"Previous"}
              className={"h-12 w-full font-medium"}
              buttonStyleType={"MSecondary"}
              onClick={() => setStep("first")}
            />
            <Button
              text={"Next"}
              className={"h-12 w-full font-medium"}
              buttonStyleType={"BPrimary"}
              status={!secret_key}
              onClick={() => setStep("third")}
            />
          </div>
        </>
      );
    } else if (step === "third") {
      return (
        <>
          <div
            className={"text-base font-normal mb-2 " + `text-[${text80Body}]`}
          >
            Enter the validation code in the Google Authenticator.
          </div>

          <ReactCodeInput
            onChange={handleChange}
            onComplete={(val) => {
              setOTP(val);
            }}
            fields={6}
          />

          <div className={"flex my-6 gap-3"}>
            <Button
              text={"Previous"}
              className={"h-12 w-full font-medium"}
              buttonStyleType={"MSecondary"}
              onClick={() => setStep("second")}
            />
            <Button
              text={"Confirm"}
              className={"h-12 w-full font-medium"}
              buttonStyleType={"BPrimary"}
              onClick={() => {
                let params = new URLSearchParams();
                params.append("otp", otp);
                AccountService.enable2fa(params, token)
                  .then((response) => {
                    toast.success("2FA activated")
                    setToggle(true);
                    setIsOpen(false);
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message)
                    navigate("/")
                  });
              }}
            />
          </div>
        </>
      );
    }
  }

  function disable() {
    AccountService.disable2fa(token)
      .then((response) => {
        if (response) {
          setIsOpen2(false);
          toast.error(response.data.message)

        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  function renderModal() {
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div className={"flex justify-between items-center mb-6"}>
            <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
              Two-Factor Authentication
            </div>

            <button onClick={() => setIsOpen(false)}>
              <CloseO fill={text60} />
            </button>
          </div>

          {Stepper()}
        </Modal>

        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={() => setIsOpen2(false)}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div className={"flex justify-between items-center mb-6"}>
            <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
              Disable Two-Factor Authentication
            </div>

            <button onClick={() => setIsOpen(false)}>
              <CloseO fill={text60} />
            </button>
          </div>
          <div
            className={"text-base font-normal mb-2 " + `text-[${text80Body}]`}
          >
            Are you sure to disable Two-Factor Authentication?
          </div>

          <div className={"flex justify-end mt-6 gap-2"}>
            <Button
              text={"Cancel"}
              onClick={() => setIsOpen2(false)}
              className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 text-[${text50}] border-[${surface80Divider}]`}
            />
            <Button
              buttonStyleType={"MWarning"}
              text={"Disable"}
              className={"flex h-12 justify-center items-center"}
              onClick={disable}
            />
          </div>
        </Modal>
      </>
    )
  }

  return (
    <div className={"w-full lg:w-4/5 2xl:w-4/5"}>
      <Formik
        initialValues={{
          current_password: "",
          password: "",
          password_confirmation: "",
        }}
        validate={(values) => {
          const errors: any = {};

          if (!values.current_password) {
            errors.current_password = "Required";
          }
          if (!values.password) {
            errors.password = "Required";
          }
          if (!values.password_confirmation) {
            errors.password_confirmation = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          AccountService.changePassword(values, token)
            .then((response) => {
              toast.success("Password changed")
              navigate("/login");
            })
            .catch((err) => {
              toast.error(err.response.data.message)
            });
        }}
      >
        {({
          values,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={"xl:mb-6 mb-4"}>
              <TextField
                type={showCurrentPass ? "text" : "password"}
                name={"current_password"}
                onChange={handleChange}
                required={true}
                onBlur={handleBlur}
                value={values.current_password}
                size={"Big"}
                label={"Current Password"}
                suffixOnClick={() => setShowCurrentPass(!showCurrentPass)}
                suffixIcon={
                  showCurrentPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />
              <ErrorMessage
                name="current_password"
                component="div"
                className={`text-[${error50Main}]`}
              />
            </div>
            <div className={"xl:mb-6 mb-4"}>
              <TextField
                type={showNewtPass ? "text" : "password"}
                name={"password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                size={"Big"}
                label={"New Password"}
                required={true}
                hint={"Minimum 6 characters"}
                suffixOnClick={() => setShowNewPass(!showNewtPass)}
                suffixIcon={
                  showNewtPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className={`text-[${error50Main}]`}
              />
            </div>
            <div className={"xl:mb-6 mb-4"}>
              <TextField
                type={showConfirmPass ? "text" : "password"}
                name={"password_confirmation"}
                onChange={handleChange}
                required={true}
                onBlur={handleBlur}
                value={values.password_confirmation}
                size={"Big"}
                label={"Confirm Password"}
                suffixOnClick={() => setShowConfirmPass(!showConfirmPass)}
                suffixIcon={
                  showConfirmPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />
              <ErrorMessage
                name="password_confirmation"
                component="div"
                className={`text-[${error50Main}]`}
              />
            </div>

            <div className={"flex justify-end mt-4"}>
              <Button
                text={"Save changes"}
                className={"h-12 font-medium"}
                status={!isValid}
                buttonStyleType={"BPrimary"}
              />
            </div>

            <div
              className={`mb-6 mt-6 w-full h-px bg-[${surface80Divider}]`}
            ></div>

            <div className={"flex justify-between"}>
              <div>
                <div
                  className={`font-semibold text-xs xl:text-base text-[${text90}]`}
                >
                  Two-factor authentication
                </div>
                <div
                  className={`font-medium text-[10px] xl:text-sm mb-4 text-[${text50}]`}
                >
                  Secure your account by adding an extra layer of safety
                </div>
              </div>
              <div className={"flex gap-3 items-center"}>
                {profile?.google_2fa_status && <CheckB fill={success50Main} />}

                <div
                  className={
                    "w-11 h-6 rounded-xl relative " +
                    `${toggle ? `bg-[${primary50Main}]` : `bg-[${surface70}]`}`
                  }
                >
                  <div
                    className={
                      "w-4 h-4 rounded-full absolute top-[4px] " +
                      `bg-[${surface50Background}] ` +
                      `${toggle ? `left-[4px]` : "right-[4px]"}`
                    }
                    onClick={() => TwoFactorAuth()}
                  ></div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      {renderModal()}
    </div>
  );
}
