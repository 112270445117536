import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}


const MoonO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4599 22.75C12.2899 22.75 12.1199 22.75 11.9499 22.74C6.34995 22.49 1.66995 17.98 1.27995 12.48C0.939948 7.75999 3.66995 3.34999 8.06995 1.49999C9.31995 0.979992 9.97995 1.37999 10.2599 1.66999C10.5399 1.94999 10.9299 2.59999 10.4099 3.78999C9.94995 4.84999 9.71995 5.97999 9.72995 7.13999C9.74995 11.57 13.4299 15.33 17.9199 15.51C18.5699 15.54 19.2099 15.49 19.8299 15.38C21.1499 15.14 21.6999 15.67 21.9099 16.01C22.1199 16.35 22.3599 17.08 21.5599 18.16C19.4399 21.06 16.0699 22.75 12.4599 22.75ZM2.76995 12.37C3.10995 17.13 7.16995 21.03 12.0099 21.24C15.2999 21.4 18.4199 19.9 20.3399 17.28C20.4899 17.07 20.5599 16.92 20.5899 16.84C20.4999 16.83 20.3399 16.82 20.0899 16.87C19.3599 17 18.5999 17.05 17.8499 17.02C12.5699 16.81 8.24995 12.38 8.21995 7.15999C8.21995 5.77999 8.48995 4.44999 9.03995 3.19999C9.13995 2.97999 9.15995 2.82999 9.16995 2.74999C9.07995 2.74999 8.91995 2.76999 8.65995 2.87999C4.84995 4.47999 2.48995 8.29999 2.76995 12.37Z" fill={props.fill}/>
        </svg>
    );
};

export default MoonO;
