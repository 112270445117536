import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const MoonB = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5302 15.93C21.3702 15.66 20.9202 15.24 19.8002 15.44C19.1802 15.55 18.5502 15.6 17.9202 15.57C15.5902 15.47 13.4802 14.4 12.0102 12.75C10.7102 11.3 9.9102 9.40999 9.9002 7.36999C9.9002 6.22999 10.1202 5.12999 10.5702 4.08999C11.0102 3.07999 10.7002 2.54999 10.4802 2.32999C10.2502 2.09999 9.7102 1.77999 8.6502 2.21999C4.5602 3.93999 2.0302 8.03999 2.3302 12.43C2.6302 16.56 5.5302 20.09 9.3702 21.42C10.2902 21.74 11.2602 21.93 12.2602 21.97C12.4202 21.98 12.5802 21.99 12.7402 21.99C16.0902 21.99 19.2302 20.41 21.2102 17.72C21.8802 16.79 21.7002 16.2 21.5302 15.93Z" fill={props.fill}/>
        </svg>
    );
};

export default MoonB;
