import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export default function GoogleAuth() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("token")) {
      localStorage.setItem("token", searchParams.get("token") || "");
      navigate("/");
      window.location.reload();
    }
  }, []);

  return (
    <div>
      Google Auth
    </div>
  );
}
