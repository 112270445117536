import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const WalletB = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.85 3.94999V7.74999H13.35V3.94999C13.35 3.67999 13.11 3.54999 12.95 3.54999C12.9 3.54999 12.85 3.55999 12.8 3.57999L4.87 6.56999C4.34 6.76999 4 7.26999 4 7.83999V8.50999C3.09 9.18999 2.5 10.28 2.5 11.51V7.83999C2.5 6.64999 3.23 5.58999 4.34 5.16999L12.28 2.16999C12.5 2.08999 12.73 2.04999 12.95 2.04999C13.95 2.04999 14.85 2.85999 14.85 3.94999Z" fill={props.fill}/>
            <path d="M21.4999 14.5V15.5C21.4999 15.77 21.2899 15.99 21.0099 16H19.5499C19.0199 16 18.5399 15.61 18.4999 15.09C18.4699 14.78 18.5899 14.49 18.7899 14.29C18.9699 14.1 19.2199 14 19.4899 14H20.9999C21.2899 14.01 21.4999 14.23 21.4999 14.5Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55C17.54 14.04 17.29 14.77 17.5 15.53C17.75 16.46 18.67 17.05 19.63 17.05H20.5C21.05 17.05 21.5 17.5 21.5 18.05V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 10.28 3.09 9.18999 4 8.50999C4.63 8.02999 5.41 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44 21.5 11.51V11.95C21.5 12.5 21.05 12.95 20.5 12.95ZM7 12.75H14C14.41 12.75 14.75 12.41 14.75 12C14.75 11.59 14.41 11.25 14 11.25H7C6.59 11.25 6.25 11.59 6.25 12C6.25 12.41 6.59 12.75 7 12.75Z" fill={props.fill}/>
        </svg>
    );
};

export default WalletB;
