import React from "react";


export interface IconProperty{
    fill: string | '#56676C';
}

const CopyO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.75 16.75H17.1C21.01 16.75 22.75 15.01 22.75 11.1V6.9C22.75 2.99 21.01 1.25 17.1 1.25H12.9C8.99 1.25 7.25 2.99 7.25 6.9V7.25H6.9C2.99 7.25 1.25 8.99 1.25 12.9V17.1C1.25 21.01 2.99 22.75 6.9 22.75H11.1C15.01 22.75 16.75 21.01 16.75 17.1V16.75ZM16.75 15.25H17.1C20.2 15.25 21.25 14.2 21.25 11.1V6.9C21.25 3.8 20.2 2.75 17.1 2.75H12.9C9.8 2.75 8.75 3.8 8.75 6.9V7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V15.25ZM6.9 8.75C3.8 8.75 2.75 9.8 2.75 12.9V17.1C2.75 20.2 3.8 21.25 6.9 21.25H11.1C14.2 21.25 15.25 20.2 15.25 17.1V12.9C15.25 9.8 14.2 8.75 11.1 8.75H6.9Z" fill={props.fill}/>
        </svg>
    );
};

export default CopyO;
