import { useTheme } from "styled-components";
import CheckB from "../../assets/icons/Check-b";
import CloseB from "../../assets/icons/Close-b";
import Button from "../../components/ds/Button";
import "./styles.scss";
import moment from "moment";
import noPhoto from "../../assets/images/no-photo.png";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "./Loading";

export default function DataList(props: any) {
  const data = props?.data;
  const surface80Divider = useTheme().colors.surface80Divider;
  const text80Body = useTheme().colors.text80Body;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const success40 = useTheme().colors.success40;
  const error40 = useTheme().colors.error40;
  const success50Main = useTheme().colors.success50Main;
  const surface70 = useTheme().colors.surface70;
  const primary50Main = useTheme().colors.primary50Main;
  const error50Main = useTheme().colors.error50Main;
  const surface50Background = useTheme().colors.surface50Background;
  const [items, setItems] = useState<any>([]);

  function fetchData() {
    const newItems = [];

    for (let i = 0; i < 100; i++) {
      newItems.push(i);
    }
    setItems([...items, ...newItems]);
  }

  function ViewChart(fino_chart: any) {
    window.open(fino_chart, "_blank");
  }

  useEffect(() => {
    fetchData();
  }, []);



  function showMore(item: any, index: any) {
    if (
      (item.strategy?.name === "pull-back" ||
        item.strategy?.name === "pullback on traingle") &&
      !item.check_params
    ) {
      return (
        <div
          className={
            "table-row relative " + `${item.collapse ? "h-[135px]" : ""}`
          }
          onClick={() => {
            let tempData = Object.assign([], data);
            if (tempData[index].collapse) {
              tempData[index].collapse = !tempData[index].collapse;
            } else {
              tempData[index].collapse = true;
            }
            props.setData(tempData);
          }}
        >
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid " +
              `border-[${surface80Divider}]`
            }
          >
            <div className={"flex items-center"}>
              <div className={"w-8 min-w-max h-8 rounded-full  mr-1.5 "}>
                <img
                  className={"h-full rounded-full"}
                  src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                />
              </div>
              <div>
                <div className={`text-xs font-semibold text-[${text80Body}]`}>
                  {item.pair.symbol}
                </div>
                <div className={`text-[10px] font-medium text-[${text50}]`}>
                  {item.exchange.name}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid " +
              `border-[${surface80Divider}]`
            }
          >
            <div
              className={`font-normal text-xs text-center text-[${text50}]`}
            >
              {moment(item.candle_time).format("HH:mm")}
            </div>
            <div
              className={`px-3 text-xs w-fit mx-auto font-semibold rounded-[20px] ${item.position_side === "BUY"
                  ? `bg-[${success40}]`
                  : `bg-[${error40}]`
                } ${item.position_side === "BUY"
                  ? `text-[${success50Main}]`
                  : `text-[${error50Main}]`
                }`}
            >
              {item.position_side}
            </div>
          </div>
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid text-right " +
              `border-[${surface80Divider}]`
            }
          >
            <div className={`font-normal text-xs text-[${text80Body}]`}>
              {item.strategy?.name}
            </div>
            <div
              className={`px-3 mr-0 pl-auto text-xs w-fit font-semibold rounded-[20px] bg-[${surface70}] text-[${text50}]`}
            >
              {item.time_frame}
            </div>
          </div>

          {item.collapse && (
            <div
              className={`w-full absolute left-0 top-[79px] ${item.collapse ? "block" : "hidden"
                }`}
            >
              {((item.strategy?.name === "pull-back" ||
                item.strategy?.name === "pullback on traingle") &&
                !item.check_params) ||
                ((item.strategy?.name === "pull-back" ||
                  item.strategy?.name === "pullback on traingle") &&
                  item.check_params) ? (
                ""
              ) : (
                <>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.macd_hist_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Macd bar divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.sig_line_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Macd hidden divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.rsi_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      RSI Hidden Divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.stoch_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Stochastic Divergence
                    </div>
                  </div>
                </>
              )}
              <Button
                buttonStyleType={"BPrimary"}
                text={"View chart"}
                onClick={() => {
                  ViewChart(item.fino_chart);
                }}
                className={"w-full text-xs h-9 mt-2"}
              />
            </div>
          )}
        </div>
      );
    } else if (
      (item.strategy?.name === "pull-back" ||
        item.strategy?.name === "pullback on traingle") &&
      item.check_params
    ) {
      return (
        <div
          className={
            "table-row relative  " + `${item.collapse ? "h-[135px]" : ""}`
          }
          onClick={() => {
            let tempData = Object.assign([], data);
            if (tempData[index].collapse) {
              tempData[index].collapse = !tempData[index].collapse;
            } else {
              tempData[index].collapse = true;
            }
            props.setData(tempData);
          }}
        >
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid " +
              `border-[${surface80Divider}]`
            }
          >
            <div className={"flex items-center"}>
              <div className={"w-8 min-w-max h-8 rounded-full  mr-1.5 "}>
                <img
                  className={"h-full rounded-full"}
                  src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                />
              </div>
              <div>
                <div className={`text-xs font-semibold text-[${text80Body}]`}>
                  {item.pair.symbol}
                </div>
                <div className={`text-[10px] font-medium text-[${text50}]`}>
                  {item.exchange.name}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid " +
              `border-[${surface80Divider}]`
            }
          >
            <div
              className={`font-normal text-xs text-center text-[${text50}]`}
            >
              {moment(item.candle_time).format("HH:mm")}
            </div>
            <div
              className={`px-3 text-xs w-fit mx-auto font-semibold rounded-[20px] ${item.position_side === "BUY"
                  ? `bg-[${success40}]`
                  : `bg-[${error40}]`
                } ${item.position_side === "BUY"
                  ? `text-[${success50Main}]`
                  : `text-[${error50Main}]`
                }`}
            >
              {item.position_side}
            </div>
          </div>
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid text-right " +
              `border-[${surface80Divider}]`
            }
          >
            <div className={`font-normal text-xs text-[${text80Body}]`}>
              {item.strategy?.name}
            </div>
            <div
              className={`px-3 mr-0 pl-auto text-xs w-fit font-semibold rounded-[20px] bg-[${surface70}] text-[${text50}]`}
            >
              {item.time_frame}
            </div>
          </div>

          {item.collapse && (
            <div
              className={`w-full absolute left-0 top-[79px] ${item.collapse ? "block" : "hidden"
                }`}
            >
              {((item.strategy?.name === "pull-back" ||
                item.strategy?.name === "pullback on traingle") &&
                !item.check_params) ||
                ((item.strategy?.name === "pull-back" ||
                  item.strategy?.name === "pullback on traingle") &&
                  item.check_params) ? (
                ""
              ) : (
                <>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.macd_hist_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Macd bar divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.sig_line_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Macd hidden divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.rsi_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      RSI Hidden Divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.stoch_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Stochastic Divergence
                    </div>
                  </div>
                </>
              )}
              <Button
                buttonStyleType={"BPrimary"}
                text={"View chart"}
                onClick={() => {
                  ViewChart(item.fino_chart);
                }}
                className={"w-full text-xs h-9 mt-2"}
              />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          className={
            "table-row relative  " + `${item.collapse ? "h-[263px]" : ""}`
          }
          onClick={() => {
            let tempData = Object.assign([], data);
            if (tempData[index].collapse) {
              tempData[index].collapse = !tempData[index].collapse;
            } else {
              tempData[index].collapse = true;
            }
            props.setData(tempData);
          }}
        >
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid " +
              `border-[${surface80Divider}]`
            }
          >
            <div className={"flex items-center"}>
              <div className={"w-8 min-w-max h-8 rounded-full  mr-1.5 "}>
                <img
                  className={"h-full rounded-full"}
                  src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                />
              </div>
              <div>
                <div className={`text-xs font-semibold text-[${text80Body}]`}>
                  {item.pair.symbol}
                </div>
                <div className={`text-[10px] font-medium text-[${text50}]`}>
                  {item.exchange.name}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid " +
              `border-[${surface80Divider}]`
            }
          >
            <div
              className={`font-normal text-xs text-center text-[${text50}]`}
            >
              {moment(item.candle_time).format("HH:mm")}
            </div>
            <div
              className={`px-3 text-xs w-fit mx-auto font-semibold rounded-[20px] ${item.position_side === "BUY"
                  ? `bg-[${success40}]`
                  : `bg-[${error40}]`
                } ${item.position_side === "BUY"
                  ? `text-[${success50Main}]`
                  : `text-[${error50Main}]`
                }`}
            >
              {item.position_side}
            </div>
          </div>
          <div
            className={
              "table-cell align-top py-2.5 border-b border-solid text-right " +
              `border-[${surface80Divider}]`
            }
          >
            <div className={`font-normal text-xs text-[${text80Body}]`}>
              {item.strategy?.name}
            </div>
            <div
              className={`px-3 mr-0 pl-auto text-xs w-fit font-semibold rounded-[20px] bg-[${surface70}] text-[${text50}]`}
            >
              {item.time_frame}
            </div>
          </div>

          {item.collapse && (
            <div
              className={`w-full absolute left-0 top-[79px] ${item.collapse ? "block" : "hidden"
                }`}
            >
              {((item.strategy?.name === "pull-back" ||
                item.strategy?.name === "pullback on traingle") &&
                !item.check_params) ||
                ((item.strategy?.name === "pull-back" ||
                  item.strategy?.name === "pullback on traingle") &&
                  item.check_params) ? (
                ""
              ) : (
                <>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.macd_hist_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Macd bar divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.sig_line_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Macd hidden divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.rsi_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      RSI Hidden Divergence
                    </div>
                  </div>
                  <div className={"flex items-center mb-2"}>
                    {item?.params?.stoch_div ? (
                      <CheckB fill={success50Main} />
                    ) : (
                      <CloseB fill={error50Main} />
                    )}
                    <div
                      className={`font-normal text-xs pl-2 text-[${text80Body}]`}
                    >
                      Stochastic Divergence
                    </div>
                  </div>
                </>
              )}
              <Button
                buttonStyleType={"BPrimary"}
                text={"View chart"}
                onClick={() => {
                  ViewChart(item.fino_chart);
                }}
                className={"w-full text-xs h-9 mt-2"}
              />
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <>
      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={props.hasMore}
        loader={<Loading />}
        height={"66vh"}
        className={"hidden 2xl:block"}
      >
        <div className="w-full table">
          <div className="table-header-group">
            <div className={"table-row"}>
              <div
                className={`table-cell py-4 px-4 text-left font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair
              </div>
              <div
                className={`table-cell py-4 px-4 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-4 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Signal Candle
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-4 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Exchange
              </div>
              <div
                className={`table-cell py-4 px-4 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-4 text-center font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Timeframe
              </div>
              <div
                className={`table-cell py-4 px-4 text-right font-semibold text-base tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Chart
              </div>
            </div>
          </div>

          <div className="table-row-group">
            {data?.map((item: any) => {
              return (
                <div
                  className="table-row"
                  onClick={(event: any) => {
                    ViewChart(item.fino_chart);
                  }}
                >
                  <div
                    className={`hover:cursor-pointer table-cell dataRow  relative align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex items-center"}>
                      <div
                        className={
                          "w-7  h-14 max-w-7 min-w-max rounded-full mr-4"
                        }
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                        />
                      </div>

                      <div>
                        <div
                          className={`font-semibold text-base tracking-tight text-[${text80Body}]`}
                        >
                          {item.pair.symbol}
                        </div>
                        <div
                          className={`font-medium text-sm tracking-tight text-[${text50}]`}
                        >
                          {item.pair.description}
                        </div>
                      </div>
                    </div>

                    {((item.strategy?.name === "pull-back" ||
                      item.strategy?.name === "pullback on traingle") &&
                      !item.check_params) ||
                      ((item.strategy?.name === "pull-back" ||
                        item.strategy?.name === "pullback on traingle") &&
                        item.check_params) ? (
                      ""
                    ) : (
                      <div
                        className={`details p-4 border border-solid rounded-3xl absolute left-0 top-0 z-20 border-[${surface80Divider}] bg-[${surface50Background}]`}
                      >
                        {/*<img src={'https://picsum.photos/200/300'}*/}
                        {/*     className={'w-72 h-44 rounded-2xl mb-[18px]'}/>*/}

                        <div className={"flex justify-between items-center"}>
                          <div className={"flex items-center"}>
                            <div
                              className={
                                "w-10 min-w-max h-10 rounded-full  mr-2"
                              }
                            >
                              <img
                                className={"h-full w-full rounded-full"}
                                src={
                                  item?.pair?.logo ? item?.pair?.logo : noPhoto
                                }
                              />
                            </div>
                            <div
                              className={`text-base font-semibold text-[${text80Body}]`}
                            >
                              {item.pair.symbol}
                            </div>
                          </div>
                          <div
                            className={`py-1 px-4 pl-3 text-base font-semibold rounded-[24px] ${item.position_side === "BUY"
                                ? `bg-[${success40}]`
                                : `bg-[${error40}]`
                              } ${item.position_side === "BUY"
                                ? `text-[${success50Main}]`
                                : `text-[${error50Main}]`
                              }`}
                          >
                            {item.position_side}
                          </div>
                        </div>

                        <div
                          className={`my-[18px] h-px w-full bg-[${surface80Divider}]`}
                        />

                        <>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.macd_hist_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Macd bar divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.sig_line_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Macd hidden divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.rsi_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              RSI Hidden Divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.stoch_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-base pl-2 text-[${text80Body}]`}
                            >
                              Stochastic Divergence
                            </div>
                          </div>
                        </>

                        <Button
                          buttonStyleType={"BPrimary"}
                          text={"View chart"}
                          onClick={() => {
                            ViewChart(item.fino_chart);
                          }}
                          className={"w-full h-12 mt-4"}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`py-2 px-4 m-auto w-fit rounded-[20px] font-semibold text-base tracking-tight ${item.position_side === "BUY"
                          ? `bg-[${success40}]`
                          : `bg-[${error40}]`
                        } ${item.position_side === "BUY"
                          ? `text-[${success50Main}]`
                          : `text-[${error50Main}]`
                        }`}
                    >
                      {item.position_side}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-base tracking-tight text-[${text50}]`}
                    >
                      {" "}
                      {moment(item.candle_time).format("MMM Do YYYY , HH:mm")}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex items-center justify-center"}>
                      <div
                        className={
                          `h-6 min-w-min w-6 rounded-full mr-2 p-1 ${item.exchange.id === 3 ? 'bg-[black]' :
                            'bg-[#F3BA2F]'
                          }`
                        }
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item.exchange.full_logo}
                          alt={"exchange icon"}
                        />
                      </div>
                      <div
                        className={`text-center font-normal text-base tracking-tight text-[${text90}]`}
                      >
                        {item.exchange.full_name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-base tracking-tight text-[${text90}]`}
                    >
                      {item.strategy?.name}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center p-2 font-normal rounded-[18px] text-base w-16 h-9 m-auto tracking-tight text-[${text50}]  bg-[${surface70}]`}
                    >
                      {item.time_frame}
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`hover:cursor-pointer text-right font-semibold text-base tracking-tight text-[${primary50Main}]`}
                    >
                      View chart
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>

      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={props.hasMore}
        loader={<Loading />}
        height={"67.5vh"}
        className={"hidden 2xl:hidden xl:block"}
      >
        <div className="w-full table">
          <div className="table-header-group">
            <div className={"table-row"}>
              <div
                className={`table-cell py-4 px-4 text-left font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair
              </div>
              <div
                className={`table-cell py-4 px-4 text-center font-semibold text-sme tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-4 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Signal Candle
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-4 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Exchange
              </div>
              <div
                className={`table-cell py-4 px-4 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-4 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Timeframe
              </div>
              <div
                className={`table-cell py-4 px-4 text-right font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Chart
              </div>
            </div>
          </div>

          <div className="table-row-group">
            {data?.map((item: any) => {
              return (
                <div className="table-row  ">
                  <div
                    className={`hover:cursor-pointer table-cell dataRow  relative align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex  items-center"}>
                      <div
                        className={"w-14 min-w-max  h-14 rounded-full  mr-4"}
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                        />
                      </div>

                      <div>
                        <div
                          className={`font-semibold text-sm tracking-tight text-[${text80Body}]`}
                        >
                          {item.pair.symbol}
                        </div>
                        <div
                          className={`font-medium text-xs tracking-tight text-[${text50}]`}
                        >
                          {item.pair.description}
                        </div>
                      </div>
                    </div>

                    {((item.strategy?.name === "pull-back" ||
                      item.strategy?.name === "pullback on traingle") &&
                      !item.check_params) ||
                      ((item.strategy?.name === "pull-back" ||
                        item.strategy?.name === "pullback on traingle") &&
                        item.check_params) ? (
                      ""
                    ) : (
                      <div
                        className={`details p-4 border border-solid rounded-3xl absolute left-0 top-0 z-20 border-[${surface80Divider}] bg-[${surface50Background}]`}
                      >
                        {/*<img src={'https://picsum.photos/200/300'}*/}
                        {/*     className={'w-72 h-44 rounded-2xl mb-[18px]'}/>*/}

                        <div className={"flex justify-between items-center"}>
                          <div className={"flex items-center"}>
                            <div
                              className={
                                "w-10 min-w-max h-10 rounded-full  mr-2"
                              }
                            >
                              <img
                                className={"h-full w-full rounded-full"}
                                src={
                                  item?.pair?.logo ? item?.pair?.logo : noPhoto
                                }
                              />
                            </div>
                            <div
                              className={`text-sm font-semibold text-[${text80Body}]`}
                            >
                              {item.pair.symbol}
                            </div>
                          </div>
                          <div
                            className={`py-1 px-4 pl-3 text-sm font-semibold rounded-[24px] ${item.position_side === "BUY"
                                ? `bg-[${success40}]`
                                : `bg-[${error40}]`
                              } ${item.position_side === "BUY"
                                ? `text-[${success50Main}]`
                                : `text-[${error50Main}]`
                              }`}
                          >
                            {item.position_side}
                          </div>
                        </div>

                        <div
                          className={`my-[18px] h-px w-full bg-[${surface80Divider}]`}
                        />

                        <>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.macd_hist_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              Macd bar divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.sig_line_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              Macd hidden divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.rsi_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              RSI Hidden Divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.stoch_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              Stochastic Divergence
                            </div>
                          </div>
                        </>

                        <Button
                          buttonStyleType={"BPrimary"}
                          text={"View chart"}
                          onClick={() => {
                            ViewChart(item.fino_chart);
                          }}
                          className={"w-full h-12 mt-4"}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`py-2 px-4 m-auto w-fit rounded-[20px] font-semibold text-sm tracking-tight ${item.position_side === "BUY"
                          ? `bg-[${success40}]`
                          : `bg-[${error40}]`
                        } ${item.position_side === "BUY"
                          ? `text-[${success50Main}]`
                          : `text-[${error50Main}]`
                        }`}
                    >
                      {item.position_side}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-sm tracking-tight text-[${text50}]`}
                    >
                      {moment(item.candle_time).format("MMM Do YYYY , HH:mm")}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex items-center justify-center"}>
                      <div
                        className={
                          `h-6 min-w-min w-6 rounded-full mr-2 p-1 ${item.exchange.id === 3 ? 'bg-[black]' :
                            'bg-[#F3BA2F]'
                          }`
                        }
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item.exchange.full_logo}
                          alt={"exchange icon"}
                        />
                      </div>
                      <div
                        className={`text-center font-normal text-sm tracking-tight text-[${text90}]`}
                      >
                        {item.exchange.full_name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-sm tracking-tight text-[${text90}]`}
                    >
                      {item.strategy?.name}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center p-2 font-normal rounded-[18px] text-sm w-16 h-9 m-auto tracking-tight text-[${text50}]  bg-[${surface70}]`}
                    >
                      {item.time_frame}
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-4 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`hover:cursor-pointer text-right font-semibold text-sm tracking-tight text-[${primary50Main}]`}
                    >
                      View chart
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>

      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={props.hasMore}
        loader={<Loading />}
        height={"67.5vh"}
        className={"hidden xl:hidden 2xl:hidden lg:block"}
      >
        <div className="w-full table">
          <div className="table-header-group">
            <div className={"table-row"}>
              <div
                className={`table-cell py-4 px-1 text-left font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair
              </div>
              <div
                className={`table-cell py-4 px-1 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-1 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Signal Candle
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-1 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Exchange
              </div>
              <div
                className={`table-cell py-4 px-1 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
              <div
                className={`md:hidden lg:table-cell py-4 px-1 text-center font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Timeframe
              </div>
              <div
                className={`table-cell py-4 px-1 text-right font-semibold text-sm tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Chart
              </div>
            </div>
          </div>

          <div className="table-row-group">
            {data?.map((item: any) => {
              return (
                <div className="table-row  ">
                  <div
                    className={`hover:cursor-pointer table-cell dataRow  relative align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex  items-center"}>
                      <div
                        className={"w-14 min-w-min  h-14 rounded-full  mr-4"}
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item?.pair?.logo ? item?.pair?.logo : noPhoto}
                        />
                      </div>

                      <div>
                        <div
                          className={`font-semibold text-sm tracking-tight text-[${text80Body}]`}
                        >
                          {item.pair.symbol}
                        </div>
                        <div
                          className={`font-medium text-xs tracking-tight text-[${text50}]`}
                        >
                          {item.pair.description}
                        </div>
                      </div>
                    </div>

                    {((item.strategy?.name === "pull-back" ||
                      item.strategy?.name === "pullback on traingle") &&
                      !item.check_params) ||
                      ((item.strategy?.name === "pull-back" ||
                        item.strategy?.name === "pullback on traingle") &&
                        item.check_params) ? (
                      ""
                    ) : (
                      <div
                        className={`details p-4 border border-solid rounded-3xl absolute left-0 top-0 z-20 border-[${surface80Divider}] bg-[${surface50Background}]`}
                      >
                        {/*<img src={'https://picsum.photos/200/300'}*/}
                        {/*     className={'w-72 h-44 rounded-2xl mb-[18px]'}/>*/}

                        <div className={"flex justify-between items-center"}>
                          <div className={"flex items-center"}>
                            <div
                              className={
                                "w-10 min-w-max h-10 rounded-full  mr-2"
                              }
                            >
                              <img
                                className={"h-full w-full rounded-full"}
                                src={
                                  item?.pair?.logo ? item?.pair?.logo : noPhoto
                                }
                              />
                            </div>
                            <div
                              className={`text-sm font-semibold text-[${text80Body}]`}
                            >
                              {item.pair.symbol}
                            </div>
                          </div>
                          <div
                            className={`py-1 px-4 pl-3 text-sm font-semibold rounded-[24px] ${item.position_side === "BUY"
                                ? `bg-[${success40}]`
                                : `bg-[${error40}]`
                              } ${item.position_side === "BUY"
                                ? `text-[${success50Main}]`
                                : `text-[${error50Main}]`
                              }`}
                          >
                            {item.position_side}
                          </div>
                        </div>

                        <div
                          className={`my-[18px] h-px w-full bg-[${surface80Divider}]`}
                        />

                        <>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.macd_hist_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              Macd bar divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.sig_line_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              Macd hidden divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.rsi_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              RSI Hidden Divergence
                            </div>
                          </div>
                          <div className={"flex items-center mb-2"}>
                            {item?.params?.stoch_div ? (
                              <CheckB fill={success50Main} />
                            ) : (
                              <CloseB fill={error50Main} />
                            )}
                            <div
                              className={`font-normal text-sm pl-2 text-[${text80Body}]`}
                            >
                              Stochastic Divergence
                            </div>
                          </div>
                        </>

                        <Button
                          buttonStyleType={"BPrimary"}
                          text={"View chart"}
                          onClick={() => {
                            ViewChart(item.fino_chart);
                          }}
                          className={"w-full h-12 mt-4"}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`py-2 px-4 m-auto w-fit rounded-[20px] font-semibold text-sm tracking-tight ${item.position_side === "BUY"
                          ? `bg-[${success40}]`
                          : `bg-[${error40}]`
                        } ${item.position_side === "BUY"
                          ? `text-[${success50Main}]`
                          : `text-[${error50Main}]`
                        }`}
                    >
                      {item.position_side}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-sm tracking-tight text-[${text50}]`}
                    >
                      {moment(item.candle_time).format("MMM Do YYYY , HH:mm")}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div className={"flex items-center justify-center"}>
                      <div
                        className={
                          `h-6 min-w-min w-6 rounded-full mr-2 p-1 ${item.exchange.id === 3 ? 'bg-[black]' :
                            'bg-[#F3BA2F]'
                          }`
                        }
                      >
                        <img
                          className={"h-full w-full rounded-full"}
                          src={item.exchange.full_logo}
                          alt={"exchange icon"}
                        />
                      </div>
                      <div
                        className={`text-center font-normal text-sm tracking-tight text-[${text90}]`}
                      >
                        {item.exchange.full_name}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center font-normal text-sm tracking-tight text-[${text90}]`}
                    >
                      {item.strategy?.name}
                    </div>
                  </div>
                  <div
                    className={`md:hidden lg:table-cell align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`text-center p-2 font-normal rounded-[18px] text-sm w-16 h-9 m-auto tracking-tight text-[${text50}]  bg-[${surface70}]`}
                    >
                      {item.time_frame}
                    </div>
                  </div>
                  <div
                    className={`table-cell align-middle border-b-[0.5px] border-solid px-1 py-4 border-[${surface80Divider}]`}
                  >
                    <div
                      className={`hover:cursor-pointer text-right font-semibold text-sm tracking-tight text-[${primary50Main}]`}
                    >
                      View chart
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </InfiniteScroll>

      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={true}
        loader={<Loading />}
        height={"71.5vh"}
        className={"lg:hidden xl:hidden 2xl:hidden"}
      >
        <div className={"w-full table"}>
          <div className={"table-header-group"}>
            <div className={"table-row"}>
              <div
                className={`table-cell py-2  text-left font-semibold text-xs tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Pair/Exchange
              </div>
              <div
                className={`table-cell py-2  text-center font-semibold text-xs tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Position/Candle
              </div>
              <div
                className={`table-cell py-2  text-right font-semibold text-xs tracking-tight border-b-[0.5px] border-solid text-[${useTheme().colors.text50
                  }] border-[${useTheme().colors.surface80Divider}]`}
              >
                Strategy
              </div>
            </div>
          </div>
          <div className="table-row-group">
            {data.map((item: any, index: number) => {
              return <>{showMore(item, index)}</>;
            })}
          </div>
        </div>
      </InfiniteScroll>
    </>
  );
}
