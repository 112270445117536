import React from "react";

export interface IconProperty{
    fill?: string | '#56676C';
}
const ListT = (props: IconProperty) => {
    return (
        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.1416 18.7894C40.8793 11.8343 47.1808 6.66699 54.6666 6.66699H73.3332C77.7447 6.66699 81.7367 8.45344 84.6418 11.3586C86.6664 13.3831 88.1475 15.9353 88.8594 18.7895C93.9267 19.1604 98.95 20.454 102.957 23.7412C108.06 27.9273 110.667 34.6163 110.667 44.0003V96.0003C110.667 104.819 108.005 111.402 103.034 115.694C98.1736 119.892 91.7485 121.334 85.3332 121.334H42.6666C36.2513 121.334 29.8263 119.892 24.9656 115.694C19.9952 111.402 17.3333 104.819 17.3333 96.0003V44.0003C17.3333 34.6163 19.9402 27.9273 25.0429 23.7412C29.0502 20.4537 34.074 19.1602 39.1416 18.7894ZM46.6666 22.667C46.6666 18.2628 50.2624 14.667 54.6666 14.667H73.3332C75.5351 14.667 77.5168 15.5472 78.985 17.0154C80.4531 18.4836 81.3332 20.4651 81.3332 22.667C81.3332 27.0712 77.7374 30.667 73.3332 30.667H54.6666C52.4647 30.667 50.4831 29.7867 49.0149 28.3185C47.5467 26.8504 46.6666 24.8689 46.6666 22.667ZM39.2091 26.8102C35.3234 27.1559 32.2916 28.1421 30.1169 29.9262C27.4996 32.0733 25.3333 36.051 25.3333 44.0003V96.0003C25.3333 103.181 27.4446 107.265 30.1943 109.64C33.0536 112.109 37.2952 113.334 42.6666 113.334H85.3332C90.7046 113.334 94.9463 112.109 97.8056 109.64C100.555 107.265 102.667 103.181 102.667 96.0003V44.0003C102.667 36.051 100.5 32.0733 97.8829 29.9262C95.708 28.1419 92.6758 27.1557 88.7894 26.8101C86.9594 33.6307 80.7234 38.667 73.3332 38.667H54.6666C50.2551 38.667 46.2632 36.8805 43.358 33.9754C41.5423 32.1597 40.1636 29.9195 39.385 27.4176C39.3225 27.2168 39.2638 27.0143 39.2091 26.8102Z" fill="#676B70"/>
            <path d="M42.6667 65.333C40.4576 65.333 38.6667 67.1239 38.6667 69.333C38.6667 71.5421 40.4576 73.333 42.6667 73.333H64.0001C66.2092 73.333 68.0001 71.5421 68.0001 69.333C68.0001 67.1239 66.2092 65.333 64.0001 65.333H42.6667Z" fill="#2BBBB2"/>
            <path d="M42.6667 86.6663C40.4576 86.6663 38.6667 88.4572 38.6667 90.6663C38.6667 92.8755 40.4576 94.6663 42.6667 94.6663H85.3334C87.5426 94.6663 89.3334 92.8755 89.3334 90.6663C89.3334 88.4572 87.5426 86.6663 85.3334 86.6663H42.6667Z" fill="#2BBBB2"/>
        </svg>
    );
};

export default ListT;
