import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useTheme } from 'styled-components'


export default function Loading() {
    return (
        <div className="block w-full">
            <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                    <div className={'w-1/12 '}>
                        <Skeleton />
                    </div>
                    <div className={'w-1/3'}>
                        <Skeleton />
                    </div>

                    <div className={'hidden xl:flex relative'}>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (0 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (1 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (2 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (3 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (4 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
            <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                    <div className={'w-1/12 '}>
                        <Skeleton />
                    </div>
                    <div className={'w-1/3'}>
                        <Skeleton />
                    </div>

                    <div className={'hidden xl:flex relative'}>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (0 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (1 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (2 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (3 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (4 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>

            <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                    <div className={'w-1/12 '}>
                        <Skeleton />
                    </div>
                    <div className={'w-1/3'}>
                        <Skeleton />
                    </div>

                    <div className={'hidden xl:flex relative'}>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (0 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (1 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (2 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (3 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (4 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
            <div className={'flex gap-7 rounded-3xl px-4 h-[52px] xl:h-24 items-center mb-4 ' + `bg-[${useTheme().colors.surface60}]`}>
                <SkeletonTheme baseColor={`${useTheme().colors.surface70}`} highlightColor={`${useTheme().colors.surface50Background}`}>
                    <div className={'w-1/12 '}>
                        <Skeleton />
                    </div>
                    <div className={'w-1/3'}>
                        <Skeleton />
                    </div>

                    <div className={'hidden xl:flex relative'}>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (0 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (1 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (2 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (3 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                        <div className={'w-10 h-10 rounded-full relative'} style={{ left: (4 * (-12)) + 'px' }}>
                            <Skeleton circle
                                height="100%" />
                        </div>
                    </div>
                </SkeletonTheme>
            </div>
        </div>
    )
}