import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}


const ListB = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.25 6.5H13.75C14.99 6.5 16 5.49 16 4.25C16 3.63 15.75 3.07 15.34 2.66C14.93 2.25 14.37 2 13.75 2H10.25C9.01 2 8 3.01 8 4.25C8 4.87 8.25 5.43 8.66 5.84C9.07 6.25 9.63 6.5 10.25 6.5Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.06 4.58C18.34 4.69 18.6 4.84 18.83 5.03C19.55 5.62 20 6.62 20 8.25V18C20 21 18.21 22 16 22H8C5.79 22 4 21 4 18V8.25C4 6.12 4.77 5.06 5.93 4.59C6.22 4.47 6.52 4.71 6.58 5.01C6.72 5.72 7.08 6.38 7.6 6.9C8.31 7.61 9.25 8 10.25 8H13.75C15.57 8 17.08 6.71 17.42 5C17.48 4.7 17.77 4.47 18.06 4.58ZM12 12.25H8C7.59 12.25 7.25 12.59 7.25 13C7.25 13.41 7.59 13.75 8 13.75H12C12.41 13.75 12.75 13.41 12.75 13C12.75 12.59 12.41 12.25 12 12.25ZM7.25 17C7.25 17.41 7.59 17.75 8 17.75H16C16.41 17.75 16.75 17.41 16.75 17C16.75 16.59 16.41 16.25 16 16.25H8C7.59 16.25 7.25 16.59 7.25 17Z" fill={props.fill}/>
        </svg>
    );
};

export default ListB;
