import React from 'react'
import {useEffect} from 'react'
import {FC} from 'react'
import {useState} from 'react'
import checkIcon from './icons/CheckIcon.svg'
import {
    CheckIconImg,
    LabelCheckboxWrapper,
    SingleCheckboxWrapper
} from './styles'

//Props types for the main checkbox in the  page
interface Props {
    //Whenever user clicks on the checkbox we well invoke the function
    //We will get an event from the parent which takes an argument of event that we will pass "target" state to it
    onClick: (event: any) => void
    //The value that component currently depends on
    checked: boolean
    //Value should be an unique id
    value: string
}

interface UseData {
    target: {
        checked: boolean
        value: string
    }
}

export const LabelCheckbox: FC<Props> = props => {
    //The part we will use to set state to checked or not checked
    const [isChecked, setChecked] = useState<boolean>(false)
    //Event that we well pas to the onClick function from the props
    const [event, setEvent] = useState<UseData>({
        target: {checked: false, value: ''}
    })
    useEffect(() => {
        //When we first render the component all should be set to false
        setChecked(false)
    }, [])
    useEffect(() => {
        setChecked(!props.checked)
    }, [props.checked])
    useEffect(() => {
        //Whenever isChecked changes to true or false we should pass the data to event
        setEvent((item: any) => ({target: {...item, checked: isChecked}}))
    }, [isChecked])
    return (
        <LabelCheckboxWrapper
            checked={props.checked}
            onClick={() => {
                props.onClick(event)
            }}
        >
            <CheckIconImg
                alt=''
                checked={props.checked}
                src={checkIcon}
            ></CheckIconImg>
        </LabelCheckboxWrapper>
    )
}

interface SingleProps {
    onClick: (event: any, idx: any) => void
    checked: boolean
    value: any
    key: number
}

interface UseDataSingle {
    target: {
        checked: boolean
        value: null | string
    }
}

export const SingleCheckbox: FC<SingleProps> = props => {
    const [isChecked, setChecked] = useState<boolean>(false)
    const [event, setEvent] = useState<UseDataSingle>({
        target: {checked: false, value: null}
    })

    useEffect(() => {
        setChecked(false)
    }, [])
    useEffect(() => {
        setChecked(!props.checked)
    }, [props.checked])
    useEffect(() => {
        setEvent((item: any) => ({
            target: {checked: isChecked, value: props.value.toString()}
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked])
    return (
        <SingleCheckboxWrapper
            checked={props.checked}
            onClick={() => {
                setChecked(!isChecked)
                props.onClick(event, props.value)
            }}
        >
            <CheckIconImg
                alt=''
                checked={props.checked}
                src={checkIcon}
            ></CheckIconImg>
        </SingleCheckboxWrapper>
    )
}
