import React from "react";

const HomeO = (props: any) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 18.75C11.59 18.75 11.25 18.41 11.25 18V15C11.25 14.59 11.59 14.25 12 14.25C12.41 14.25 12.75 14.59 12.75 15V18C12.75 18.41 12.41 18.75 12 18.75Z" fill="#56676C"/>
            <path d="M17.6 22.56H6.39996C4.57996 22.56 2.91996 21.16 2.61996 19.37L1.28996 11.4C1.06996 10.16 1.67996 8.57001 2.66996 7.78001L9.59996 2.23001C10.94 1.15001 13.05 1.16001 14.4 2.24001L21.33 7.78001C22.31 8.57001 22.91 10.16 22.71 11.4L21.38 19.36C21.08 21.13 19.38 22.56 17.6 22.56ZM11.99 2.93001C11.46 2.93001 10.93 3.09001 10.54 3.40001L3.60996 8.96001C3.04996 9.41001 2.64996 10.45 2.76996 11.16L4.09996 19.12C4.27996 20.17 5.32996 21.06 6.39996 21.06H17.6C18.67 21.06 19.72 20.17 19.9 19.11L21.23 11.15C21.34 10.45 20.94 9.39001 20.39 8.95001L13.46 3.41001C13.06 3.09001 12.52 2.93001 11.99 2.93001Z" fill="#56676C"/>
        </svg>
    );
};

export default HomeO;
