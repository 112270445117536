import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppThemeProvider } from "./theme";
import { ContextProvider } from "./context";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./useAuth";
import WorkerProvider from "./workerProvider"
import { CommonProvider } from "./context/commonContext";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLDivElement
);

root.render(
    <WorkerProvider>
        <ContextProvider>
            <AppThemeProvider>
                <Router>
                    <CommonProvider>

                        <AuthProvider>
                            <App />
                            <Toaster position="bottom-center" reverseOrder={false} toastOptions={{ duration: 3000 }} />
                        </AuthProvider>
                    </CommonProvider>

                </Router>
            </AppThemeProvider>
        </ContextProvider>
    </WorkerProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
