import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}
const EmailO = (props: any) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 21.25H17C20.65 21.25 22.75 19.15 22.75 15.5V8.5C22.75 4.85 20.65 2.75 17 2.75H7C3.35 2.75 1.25 4.85 1.25 8.5C1.25 8.91 1.59 9.25 2 9.25C2.41 9.25 2.75 8.91 2.75 8.5C2.75 5.64 4.14 4.25 7 4.25H17C19.86 4.25 21.25 5.64 21.25 8.5V15.5C21.25 18.36 19.86 19.75 17 19.75H7C6.59 19.75 6.25 20.09 6.25 20.5C6.25 20.91 6.59 21.25 7 21.25Z" fill={props.fill}/>
            <path d="M9.65978 12.08C10.3098 12.61 11.1598 12.87 11.9998 12.87C12.8398 12.87 13.6898 12.61 14.3298 12.08L17.4598 9.57997C17.7898 9.32997 17.8398 8.84997 17.5798 8.52997C17.3298 8.19997 16.8498 8.14997 16.5298 8.40997L13.3998 10.91C12.6398 11.52 11.3498 11.52 10.5898 10.91L7.45977 8.40997C7.13977 8.14997 6.66978 8.20997 6.40978 8.52997C6.14978 8.84997 6.20978 9.31997 6.52978 9.57997L9.65978 12.08Z" fill={props.fill}/>
            <path d="M8.75 16.5C8.75 16.91 8.41 17.25 8 17.25H2C1.59 17.25 1.25 16.91 1.25 16.5C1.25 16.09 1.59 15.75 2 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5Z" fill={props.fill}/>
            <path d="M2 13.25H5C5.41 13.25 5.75 12.91 5.75 12.5C5.75 12.09 5.41 11.75 5 11.75H2C1.59 11.75 1.25 12.09 1.25 12.5C1.25 12.91 1.59 13.25 2 13.25Z" fill={props.fill}/>
        </svg>
    );
};

export default EmailO;
