import { Badge, Icon, Item, Link, Title } from "./styles";

export interface SideItem_Option {
    icon?: any;
    title?: string;
    badge?: any;
    titleClassName?: any;
    className?: any;
    status?: any;
    collapse?: any;
}

export default function SidebarItem(props: SideItem_Option) {
    return (
        <Item className={props.className}>
            <Link>
                <Icon>
                    {props.icon}
                </Icon>
                <Title collapse={props.collapse} status={props.status} className={props.titleClassName}>
                    {props.title}
                </Title>
            </Link>

            {!props.collapse && props.badge &&
                <Badge>
                    {props.badge}
                </Badge>
            }
        </Item>
    )
}