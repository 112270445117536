import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import TextField from "../../components/ds/Textfield";
import SearchO from "../../assets/icons/Search-o";
import { NavLink } from "react-router-dom";
import Button from "../../components/ds/Button";
import AddOutline from "../../assets/icons/Add-outline";
import DataList from "./dataList";
import Empty from "../../components/ds/ResultPage/empty";
import NotificationT from "../../assets/icons/icon-128/Notification-t";
import AlarmService from "../../services/alarm.service";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import SearchT from "../../assets/icons/icon-128/Search-t";
import Search64T from "../../assets/icons/icon-64/Search-t";
import Loading from "./Loading";
import { useDebouncedCallback } from "../../components/ds/Hooks/useDebounce";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import alarmMock from "../../assets/images/mock/alarm.png";
import useAuth from "../../useAuth";
import { useCommon } from "../../context/commonContext";
import toast from "react-hot-toast";

export default function Alarms() {
  const [result, setResult] = useState<any>([]);
  const [filterParam, setFilterParam] = useState<any>("");
  const [search, setSearch] = useState<any>("");
  const [page, setPage] = useState<any>(1);
  const { profile } = useCommon();

  const [loading, setLoading] = useState(true);

  const text20 = useTheme().colors.text20;
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const text90 = useTheme().colors.text90;
  const text80Body = useTheme().colors.text80Body;
  const primary50Main = useTheme().colors.primary50Main;
  const [hasMore, setHasMore] = useState(false);
  const { token } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
      maxWidth: "600px"
    },
  };
  useEffect(() => {
    getAlarms(1);
  }, []);

  const makeRequest = useDebouncedCallback((event: string) => {
    getAlarms(1, event);
  }, 1500);

  function getAlarms(pageEnter?: number, param?: string) {
    setFilterParam(param);
    setPage(pageEnter);
    AlarmService.getAll(pageEnter ? pageEnter : page, param, token)
      .then((response: any) => {
        if (
          response.data.message ===
          "The user does not have an active subscription"
        ) {
          toast.error(response.data.message)
        }

        if (page === 1) setResult(response?.data?.data);
        else setResult([...result, ...response?.data?.data]);

        setLoading(false);
        if (response.data.data.length && response.data.total > 15) {
          setHasMore(true);
        } else if (response.data.data.length && response.data.total <= 15) {
          setHasMore(false);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message)
      });
  }

  function renderContent() {
    if (result.length === 0) {
      if (filterParam) {
        return (
          <>
            <div className="flex justify-between pb-6">
              <div className={"basis-full lg:basis-1/4"}>
                <TextField
                  size={"Big"}
                  prefixIcon={<SearchO fill={text50} size={"Big"} />}
                  onChange={(event: any) => {
                    if (event.target.value.length > 2) {
                      makeRequest(event.target.value);
                      setSearch(event.target.value);
                    } else if (event.target.value.length === 0)
                      makeRequest(event.target.value);
                    setSearch(event.target.value);
                  }}
                  placeholder={"Search in alarms"}
                />
              </div>
              <NavLink to={"/alarms/add"} className={"hidden lg:block"}>
                <Button
                  className={
                    "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium xl:px-6 sm:px-4"
                  }
                  text={"Add new alarm"}
                  buttonStyleType={"MPrimary"}
                  icon={<AddOutline fill={text20} />}
                />
              </NavLink>
            </div>

            <SearchResult
              icon={<SearchT />}
              mobileIcon={<Search64T />}
              title={"No results with " + '"' + search + '"'}
            />
          </>
        );
      } else {
        //render no data
        return (
          <Empty
            icon={<NotificationT />}
            title={"Always have your ear to the ground"}
            des={
              "Alarms help you to be aware of latest changes you are curious about them by sending messages, emails, etc."
            }
            linkText={"Create first alarm"}
            to={"/alarms/add"}
            linkIcon={<AddOutline fill={text20} />}
          />
        );
      }
    } else {
      return (
        <>
          <div className="flex justify-between pb-6">
            <div className={"basis-full lg:basis-1/4"}>
              <TextField
                size={"Big"}
                prefixIcon={<SearchO fill={text50} size={"Big"} />}
                onChange={(event: any) => {
                  if (event.target.value.length > 2) {
                    makeRequest(event.target.value);
                    setSearch(event.target.value);
                  } else if (event.target.value.length === 0)
                    makeRequest(event.target.value);
                  setSearch(event.target.value);
                }}
                placeholder={"Search in alarms"}
              />
            </div>
            <NavLink to={"/alarms/add"} className={"hidden lg:block"}>
              <Button
                className={
                  "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium xl:px-6 sm:px-4"
                }
                text={"Add new alarm"}
                buttonStyleType={"MPrimary"}
                icon={<AddOutline fill={text20} />}
              />
            </NavLink>
          </div>
          <DataList
            data={result}
            hasMore={hasMore}
            onDelete={(id: any) => {
              setResult([...result?.filter((i: any) => i.id !== id)]);
            }}
            editData={(data: any) => getAlarms(page)}
          />
        </>
      );
    }
  }



  return (
    <>
      <div className={"flex  justify-between items-center xl:mb-6 mb-4"}>
        <PageTitles
          text={"Alarms"}
          from={result.length}
          to={"10"}
          activePlan={profile?.active_plan?.is_active === 1}
          onClickIcon={() => setIsOpen(true)}
          icon={<QuestionO fill={primary50Main} />}
        />

        <NavLink to={"/alarms/add"} className={"lg:hidden"}>
          <AddOutline fill={primary50Main} />
        </NavLink>
      </div>

      {loading ? (
        <Loading />
      ) : (
        renderContent()
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
            How to use alarms?
          </div>

          <button onClick={() => setIsOpen(false)}>
            <CloseO fill={text60} />
          </button>
        </div>

        <div className={"w-full h-1/2"}>
          <img src={alarmMock} />
        </div>

        <div className={"text-base " + `text-[${text80Body}]`}>
          click <a className={`text-[${primary50Main}]`}>here </a> to see
          more
        </div>
      </Modal>
    </>
  );
}
