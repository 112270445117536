import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const TelegramO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0499 16.83C11.3699 20.8 13.0399 21.63 14.2199 21.63C15.3999 21.63 17.0699 20.8 18.3999 16.82L21.2299 8.33001C21.9399 6.21001 21.7299 4.43001 20.6399 3.35001C19.5499 2.27001 17.7799 2.06001 15.6599 2.77001L7.16988 5.60001C3.20988 6.93001 2.37988 8.61001 2.37988 9.78001C2.37988 10.96 3.20988 12.63 7.16988 13.95L9.32988 14.67L10.0499 16.83ZM3.86988 9.78001C3.86988 9.06001 4.85988 7.96001 7.63988 7.03001L16.1299 4.19001C17.6599 3.68001 18.9199 3.76001 19.5699 4.41001C20.2199 5.06001 20.3099 6.32001 19.7999 7.86001L16.9699 16.35C16.0399 19.13 14.9399 20.12 14.2199 20.12C13.4999 20.12 12.3899 19.13 11.4699 16.35L10.7198 14.0998L14.2199 10.59C14.5099 10.3 14.5099 9.82001 14.2199 9.53001C13.9299 9.24001 13.4499 9.24001 13.1599 9.53001L9.57989 13.12C9.56833 13.1316 9.55723 13.1434 9.54659 13.1556L7.63988 12.52C4.85988 11.6 3.86988 10.5 3.86988 9.78001Z" fill={props.fill}/>
        </svg>
    );
};

export default TelegramO;
