import styled, { css } from "styled-components";

export const Side = styled.div`
  background-color: ${({ theme }) => theme.colors.surface50Background};
  width: 100%;
  position: relative;
  border-right: 1px solid ${({ theme }) => theme.colors.surface80Divider};
  //padding: 48px 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    border-right: unset;
  }
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      a {
        text-decoration: none;
      }
    }
  }
`;

export const Collapse = styled.div`
  position: absolute;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.surface50Background};
  border-radius: 28px;
  border: 1px solid ${({ theme }) => theme.colors.surface80Divider};
  right: -28px;
  z-index: 2;
`;

export const Upgrade = styled.div<{ collapse: string }>`
  background-color: ${({ theme }) => theme.colors.primary50Main};
  border: 1px solid ${({ theme }) => theme.colors.primary50Main};
  color: ${({ theme }) => theme.colors.text20};
  border-radius: 28px;
  padding: 13px 32px;
  margin: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 9.25px;
  }

  ${({ collapse }) => {
    if (collapse) {
      return css`
        padding: 13px 10px;
        margin: 24px 22px;
        svg {
          margin-right: 0;
        }
      `;
    }
  }}
`;

export const Bottom = styled.div``;

export const ChangeMode = styled.div<{ collapse: boolean }>`
  padding: 4px;
  height: 48px;
  background: ${({ theme }) => theme.colors.surface60};
  border-radius: 28px;
  margin: auto 40px;
  display: flex;
  align-items: center;

  ${({ collapse }) => {
    if (collapse) {
      return css`
        width: 55px;
        height: 103px;
        flex-direction: column;
        margin: auto 22px;
      `;
    }
  }}
`;

export const EachMode = styled.div<{ mode: boolean; collapse: boolean }>`
  width: 50%;
  min-width: 50%;
  color: ${({ theme }) => theme.colors.text50};
  height: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 8px;
  }

  ${({ collapse }) => {
    if (collapse) {
      return css`
        width: 47px;
        height: 48px;
        min-width: 47px;
        min-height: 48px;

        svg {
          margin-right: 0;
        }
      `;
    }
  }}

  &:hover {
    cursor: pointer;
  }

  ${({ mode }) => {
    if (mode) {
      return css`
        color: ${({ theme }) => theme.colors.primary50Main};
        background-color: ${({ theme }) => theme.colors.surface50Background};
        border-radius: 24px;
      `;
    }
  }}
`;

export const CopyRight = styled.div`
  color: ${({ theme }) => theme.colors.text30};
  text-align: center;
  margin: 16px 0 0;
`;
