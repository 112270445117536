import { useTheme } from "styled-components";
import ListCart from "./listCart";
import TicketService from "../../services/ticket.service";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "./Loading";
import useAuth from "../../useAuth";

export default function DataList(props: any) {
  const { token } = useAuth();
  function deleteAction(id: any) {
    TicketService.delete(id, token)
      .then((response) => {
        if (response.status) {
          props.onDelete(id);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
      <InfiniteScroll
        dataLength={props?.data?.length} //This is important field to render the next data
        next={() => props.goNext()}
        hasMore={props.hasMore}
        loader={<Loading />}
        height={"69vh"}
      >
        <div className={"table rounded-3xl w-full  mt-6"}>
          <div className="lg:table-header-group hidden">
            <div className="table-row">
              <div
                className={`table-cell rounded-tl-3xl py-3 pl-6 text-left text-base  font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                #
              </div>
              <div
                className={`table-cell py-3 text-left text-base font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                Subject
              </div>
              <div
                className={`hidden xl:table-cell py-3 text-left text-base font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                Department
              </div>
              <div
                className={`hidden xl:table-cell py-3 text-left text-base font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                Status
              </div>
              <div
                className={`table-cell  py-3 mr-6 text-left text-base font-semibold text-[${useTheme().colors.text50
                  }]`}
              >
                Sender
              </div>
              <div className={"table-cell rounded-tr-3xl py-3 mr-6"}></div>
            </div>
          </div>
          <div className="table-row-group">
            {props?.data?.map((item: any, index: number) => {
              return (
                <ListCart
                  item={item}
                  index={index}
                  deleteAction={(id: any) => deleteAction(id)}
                />
              );
            })}
          </div>
          <div className={"h-4"}></div>
        </div>
      </InfiniteScroll>
  );
}
