import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import TextField from "../../components/ds/Textfield";
import SearchO from "../../assets/icons/Search-o";
import Button from "../../components/ds/Button";
import FilterO from "../../assets/icons/Filter-o";
import DataList from "./dataList";
import Filter from "./Filter";
import DashboardService from "../../services/dashboard.service";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import SearchT from "../../assets/icons/icon-128/Search-t";
import Search64T from "../../assets/icons/icon-64/Search-t";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloseO from "../../assets/icons/Close-o";
import Loading from "./Loading";
import { useDebouncedCallback } from "../../components/ds/Hooks/useDebounce";
import Modal from "react-modal";
import dashboardMock from "../../assets/images/mock/dashboard.png";
import { usePusher } from "../../hooks/usePusher";
import useAuth from "../../useAuth";
import toast from "react-hot-toast";

export default function Dashboard() {
    const [search, setSearch] = useState<any>("");
    const [collapse, setCollapse] = React.useState(false);
    const [result, setResult] = useState<any>([]);
    const [resultFilter, setResultFilter] = useState<any>([]);
    const [filter, setFilter] = useState<any>("");
    const [pairsFilter, setPairsFilter] = useState<any>("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const primary50Main = useTheme().colors.primary50Main;
    const text50 = useTheme().colors.text50;
    const [hasMore, setHasMore] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const customStyles = {
        content: {
            backgroundColor: useTheme().colors.surface50Background,
            borderColor: useTheme().colors.surface50Background,
            width: "50%",
            padding: "1.5rem",
            borderRadius: "1.5rem",
            height: "fit-content",
            display: "block",
            margin: "auto",
        },
    };
    const navigate = useNavigate();

    const { token } = useAuth();
    const [searchParams] = useSearchParams();


    const ClearFilter = () => {
        if (filter) {
            setLoading(true);
            setResult([]);
            setResultFilter([]);
            setPage(1);
            setFilter("");
        }
    };

    const makeRequest = useDebouncedCallback((event: string) => {
        getSignal(1, event);
    }, 1500);

    const newSignal = usePusher("signal-channel", "new-signal");

    useEffect(() => {
        // When a new signal is received from Pusher, update the state with the latest signal
        if (newSignal && newSignal?.signal) {
            setResult((prevSignals: any) => [newSignal?.signal, ...prevSignals]);
            setResultFilter((prevSignals: any) => [
                newSignal?.signal,
                ...prevSignals,
            ]);
        }
    }, [newSignal]);

    useEffect(() => {
        getSignal(page);
    }, [filter, pairsFilter, page]);

    useEffect(() => {
        if (searchParams.get("pairs"))
            setPairsFilter(`&pairs[]=${searchParams.get("pairs")}`);
        else setPairsFilter("");
    }, [searchParams]);

    function getSignal(pageEnter?: number, param?: string) {
        setPage(pageEnter || 1);
        DashboardService.getSignalsByFilter(
            pageEnter ? pageEnter : page,
            filter + `${param ? `&query=${param}` : search.length >= 3 ? `&query=${search}` : ""}${pairsFilter}`,
            token
        ).then((response) => {
            if (
                response.data.message ===
                "The user does not have an active subscription"
            ) {
                toast.error(response.data.message)
            }
            if (pageEnter === 1) {
                setResult(response?.data?.data);
                setResultFilter(response?.data?.data);
            } else {
                setResult([...result, ...response?.data?.data]);
                setResultFilter([...resultFilter, ...response?.data?.data]);
            }
            setLoading(false);
            if (response.data.data.length && response.data.total > 15) {
                setHasMore(true);
            } else if (response.data.data.length && response.data.total <= 15) {
                setHasMore(false);
            } else {
                setHasMore(false);
            }
        })
            .catch((err) => {
                setLoading(false);
                toast.error(err.response.data.message);
                if (err.response.status === 401) {
                    navigate("/");
                    localStorage.removeItem("token");
                }
                if (err.response.status === 403) {
                    if (err?.response?.data?.message ==="Your email address is not verified.") {
                        navigate("/settings/profile");
                    } else {
                        navigate("/plans");
                    }
                }
                if (err.response.status === 404) {
                    localStorage.removeItem("token");
                    navigate("*");
                }
            });
    }


    return (
        <section>
            {loading ? (
                <Loading />
            ) : (
                <section>
                    <div className={"mb-4 lg:mb-6 relative"}>
                        <PageTitles
                            text={"Dashboard"}
                            icon={<QuestionO fill={primary50Main} />}
                            onClickIcon={() => setIsOpen(true)}
                        />
                    </div>
                    <div className="flex justify-between pb-6">
                        <div className={"basis-full lg:basis-1/4"}>
                            <TextField
                                size={"Big"}
                                suffixIconClassName={"block lg:hidden"}
                                onChange={(event: any) => {
                                    setSearch(event.target.value.toUpperCase());
                                    makeRequest(event.target.value.toUpperCase());
                                }}
                                prefixIcon={<SearchO fill={text50} size={"Big"} />}
                                suffixIcon={<FilterO fill={text50} />}
                                suffixOnClick={() => setCollapse(!collapse)}
                                placeholder={"Search Signals"}
                            />
                        </div>

                        <Button
                            className={
                                "hidden lg:flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium lg:px-6 sm:px-4"
                            }
                            text={"Filter"}
                            buttonStyleType={"MSecondary"}
                            icon={<FilterO fill={text50} />}
                            onClick={() => setCollapse(!collapse)}
                        />
                    </div>
                    <Filter
                        collapse={collapse}
                        closeFilter={() => setCollapse(false)}
                        clear={ClearFilter}
                        filter={(searchParam: string) => {
                            setPage(1);
                            setFilter(searchParam);
                        }}
                    />

                    {result?.length > 0 && (
                        <DataList
                            data={resultFilter}
                            setData={setResultFilter}
                            hasMore={hasMore}
                            goNext={() => setPage(page + 1)}
                        />
                    )}

                    {resultFilter.length === 0 && search.length > 0 &&
                        <SearchResult
                            icon={<SearchT />}
                            mobileIcon={<Search64T />}
                            title={"No results with " + '"' + search + '"'}
                        />
                    }

                    {resultFilter.length === 0 && filter.length > 0 &&
                        <SearchResult
                            icon={<SearchT />}
                            mobileIcon={<Search64T />}
                            title={"No results  "}
                        />
                    }

                </section>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel="Example Modal"
                style={customStyles}
            >
                <div className={"flex justify-between items-center mb-6"}>
                    <div
                        className={
                            "text-2xl font-semibold " + `text-[${useTheme().colors.text90}]`
                        }
                    >
                        How to use dashboard?
                    </div>

                    <button onClick={() => setIsOpen(false)}>
                        <CloseO fill={useTheme().colors.text60} />
                    </button>
                </div>

                <div className={"w-full h-1/2"}>
                    <img src={dashboardMock} />
                </div>

                <div
                    className={"text-base " + `text-[${useTheme().colors.text80Body}]`}
                >
                    click{" "}
                    <a className={`text-[${useTheme().colors.primary50Main}]`}>here </a>{" "}
                    to see more
                </div>
            </Modal>
        </section>
    );
}
