import React from "react";

const SearchT = (props: any) => {
    return (
        <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M58.6665 14.667C34.369 14.667 14.6665 34.3695 14.6665 58.667C14.6665 82.9645 34.369 102.667 58.6665 102.667C82.964 102.667 102.667 82.9645 102.667 58.667C102.667 56.4579 104.457 54.667 106.667 54.667C108.876 54.667 110.667 56.4579 110.667 58.667C110.667 87.3828 87.3823 110.667 58.6665 110.667C29.9507 110.667 6.6665 87.3828 6.6665 58.667C6.6665 29.9512 29.9507 6.66699 58.6665 6.66699C60.8756 6.66699 62.6665 8.45785 62.6665 10.667C62.6665 12.8761 60.8756 14.667 58.6665 14.667Z" fill="#676B70"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.55 95.8393C104.446 95.8081 100.753 97.427 98.5004 100.555C96.2495 103.68 95.8737 107.702 97.1612 111.6C98.7716 116.462 101.918 120.623 106.748 121.271C111.577 121.918 115.704 118.735 118.531 114.479C121.217 110.434 122.357 105.684 120.172 101.604C117.989 97.5275 113.41 95.8433 108.55 95.8393ZM104.757 109.09C105.973 112.76 107.467 113.295 107.811 113.341C108.155 113.388 109.735 113.263 111.867 110.053M104.757 109.09C104.125 107.176 104.496 105.919 104.992 105.23C105.482 104.55 106.529 103.822 108.498 103.839L108.533 103.839C111.88 103.839 112.892 104.956 113.12 105.381C113.349 105.808 113.714 107.272 111.867 110.053" fill="#676B70"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M70.6665 26.667C70.6665 24.4579 72.4574 22.667 74.6665 22.667H106.667C108.876 22.667 110.667 24.4579 110.667 26.667C110.667 28.8761 108.876 30.667 106.667 30.667H74.6665C72.4574 30.667 70.6665 28.8761 70.6665 26.667Z" fill="#2BBBB2"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M70.6665 42.667C70.6665 40.4579 72.4574 38.667 74.6665 38.667H90.6665C92.8756 38.667 94.6665 40.4579 94.6665 42.667C94.6665 44.8761 92.8756 46.667 90.6665 46.667H74.6665C72.4574 46.667 70.6665 44.8761 70.6665 42.667Z" fill="#2BBBB2"/>
        </svg>
    );
};

export default SearchT;
