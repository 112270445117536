import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import SearchO from "../../assets/icons/Search-o";
import DataList from "./dataList";
import Button from "../../components/ds/Button";
import AddOutline from "../../assets/icons/Add-outline";
import React, { useEffect, useState } from "react";
import ListT from "../../assets/icons/icon-128/List-t";
import { NavLink, useNavigate } from "react-router-dom";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import SearchT from "../../assets/icons/icon-128/Search-t";
import Search64T from "../../assets/icons/icon-64/Search-t";
import WatchListService from "../../services/list.service";
import Empty from "../../components/ds/ResultPage/empty";
import CloseO from "../../assets/icons/Close-o";
import Loading from "./Loading";
import { useDebouncedCallback } from "../../components/ds/Hooks/useDebounce";
import Modal from "react-modal";
import listsMock from "../../assets/images/mock/lists.png";
import useAuth from "../../useAuth";
import { useCommon } from "../../context/commonContext";
import toast from "react-hot-toast";

export default function Lists() {
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [filterParam, setFilterParam] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const { profile } = useCommon();


  const text20 = useTheme().colors.text20;
  const text50 = useTheme().colors.text50;
  const primary50Main = useTheme().colors.primary50Main;
  const navigate = useNavigate();
  const [hasMore, setHasMore] = useState(false);
  const { token } = useAuth();


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "50%",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };


  const [page, setPage] = useState(1);

  useEffect(() => {
    getList(1);
  }, []);

  const makeRequest = useDebouncedCallback((event: string) => {
    getList(1, event);
  }, 1500);

  function getList(pageEnter?: number, param?: string) {
    let isNew = filterParam !== param;
    setFilterParam(param);
    setPage(pageEnter || 1);
    WatchListService.getAll(pageEnter ? pageEnter : page, param, token)
      .then((response: any) => {
        if (
          response.data.message ===
          "The user does not have an active subscription"
        ) {
          toast.error(response.data.message)
        }
        if (pageEnter === 1 || isNew) setData(response?.data?.data);
        else setData([...data, ...response?.data?.data]);
        setLoading(false);
        if (response.data.data.length && response.data.total > 15) {
          setHasMore(true);
        } else if (response.data.data.length && response.data.total <= 15) {
          setHasMore(false);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message) 
        navigate("/plans")
      });
  }

  function renderContent() {
    if (data.length === 0) {
      if (filterParam) {
        //render not found
        return (
          <>
            <div className="flex justify-between pb-6">
              <div className={"basis-full lg:basis-1/4"}>
                <TextField
                  size={"Big"}
                  prefixIcon={<SearchO fill={text50} size={"Big"} />}
                  onChange={(event: any) => {
                    if (event.target.value.length > 2) {
                      makeRequest(event.target.value);
                      setSearch(event.target.value);
                    } else if (event.target.value.length === 0)
                      makeRequest(event.target.value);
                    setSearch(event.target.value);
                  }}
                  placeholder={"Search in lists"}
                />
              </div>
              <NavLink to={"/lists/add"} className={"hidden lg:block"}>
                <Button
                  className={
                    "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium lg:px-6 sm:px-4"
                  }
                  text={"Add new list"}
                  buttonStyleType={"MPrimary"}
                  icon={<AddOutline fill={text20} />}
                />
              </NavLink>
            </div>

            <SearchResult
              icon={<SearchT />}
              mobileIcon={<Search64T />}
              title={"No results with " + '"' + search + '"'}
            />
          </>
        );
      } else {
        //render no data
        return (
          <Empty
            icon={<ListT />}
            title={"Organize your coins"}
            des={
              "Create lists to organize your coins such as portfolio, your watchlist or any other list you have in mind"
            }
            linkText={"Create first list"}
            to={"/lists/add"}
            linkIcon={<AddOutline fill={text20} />}
          />
        );
      }
    } else {
      //render data
      return (
        <>
          <div className="flex justify-between pb-6">
            <div className={"basis-full lg:basis-1/4"}>
              <TextField
                size={"Big"}
                prefixIcon={<SearchO fill={text50} size={"Big"} />}
                onChange={(event: any) => {
                  if (event.target.value.length > 2) {
                    makeRequest(event.target.value);
                    setSearch(event.target.value);
                  } else if (event.target.value.length === 0)
                    makeRequest(event.target.value);
                  setSearch(event.target.value);
                }}
                placeholder={"Search in lists"}
              />
            </div>
            <NavLink to={"/lists/add"} className={"hidden lg:block"}>
              <Button
                className={
                  "flex items-center text-base tracking-tight lg:rounded-3xl h-12 font-medium lg:px-6 sm:px-4"
                }
                text={"Add new list"}
                buttonStyleType={"MPrimary"}
                icon={<AddOutline fill={text20} />}
              />
            </NavLink>
          </div>

          <DataList
            data={data}
            hasMore={hasMore}
            goNext={() => getList(page + 1, filterParam)}
            onDelete={(id: any) => {
              setData([...data?.filter((i: any) => i.id !== id)]);
            }}
          />
        </>
      );
    }
  }

  return (
    <>
      <div
        className={"flex  relative justify-between items-center xl:mb-6 mb-4"}
      >
        <PageTitles
          text={"Lists"}
          from={data.length}
          to={"5"}
          activePlan={profile?.active_plan?.is_active === 1}
          onClickIcon={() => setIsOpen(true)}
          icon={<QuestionO fill={primary50Main} />}
        />
        <NavLink to={"/lists/add"} className={"lg:hidden"}>
          <AddOutline fill={primary50Main} />
        </NavLink>
      </div>

      {loading ? (
        <Loading />
      ) : (
          renderContent()
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={()=>setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div
            className={
              "text-2xl font-semibold " + `text-[${useTheme().colors.text90}]`
            }
          >
            How to use Lists?
          </div>

          <button onClick={()=>setIsOpen(false)}>
            <CloseO fill={useTheme().colors.text60} />
          </button>
        </div>

        <div className={"w-full h-1/2"}>
          <img src={listsMock} />
        </div>

        <div
          className={"text-base " + `text-[${useTheme().colors.text80Body}]`}
        >
          click{" "}
          <a className={`text-[${useTheme().colors.primary50Main}]`}>here </a>{" "}
          to see more
        </div>
      </Modal>
    </>
  );
}
