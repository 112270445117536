import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}


const NotificationO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0301 1.17999C11.0065 1.17999 10.0958 1.73627 9.62369 2.60995C7.08198 3.57934 5.27011 6.04337 5.27011 8.92001V11.81C5.27011 12.28 5.05011 13.08 4.81011 13.48L3.66011 15.39C3.20011 16.15 3.10011 17 3.39011 17.77C3.67011 18.54 4.31011 19.13 5.15011 19.42C6.23027 19.7817 7.33909 20.055 8.46257 20.2399C8.64589 20.7893 8.9553 21.2953 9.37002 21.71C10.07 22.41 11.03 22.81 12.02 22.81C13.6781 22.81 15.086 21.7321 15.5806 20.2392C16.7014 20.0543 17.8062 19.7812 18.8801 19.42C19.6701 19.16 20.3001 18.56 20.5901 17.77C20.8801 16.98 20.8001 16.11 20.3701 15.39L19.2301 13.49C18.9901 13.08 18.7701 12.27 18.7701 11.81V8.92001C18.7701 6.0535 16.971 3.59669 14.4433 2.62024C13.9732 1.73352 13.0586 1.17999 12.0301 1.17999ZM13.7802 20.4595C13.1949 20.5065 12.6075 20.53 12.0201 20.53C11.4329 20.53 10.8456 20.5065 10.2603 20.4595C10.3131 20.526 10.3697 20.5897 10.43 20.65C10.85 21.07 11.43 21.31 12.02 21.31C12.7317 21.31 13.3675 20.9773 13.7802 20.4595ZM6.77011 8.92001C6.77011 6.28215 8.72274 4.09935 11.2508 3.72629C11.7335 3.66256 12.2316 3.66015 12.7408 3.71905C15.2997 4.07019 17.2701 6.26452 17.2701 8.92001V11.8C17.2701 12.53 17.5601 13.6 17.9401 14.25L19.0901 16.16C19.2901 16.49 19.3201 16.88 19.1901 17.24C19.0601 17.6 18.7801 17.87 18.4201 17.99C14.2401 19.39 9.81011 19.39 5.63011 17.99C5.22011 17.85 4.92011 17.59 4.80011 17.25C4.67011 16.92 4.73011 16.53 4.95011 16.16L6.10011 14.25C6.47011 13.62 6.77011 12.54 6.77011 11.81V8.92001Z" fill={props.fill}/>
        </svg>
    );
};

export default NotificationO;
