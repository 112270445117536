import { useTheme } from "styled-components";
import Button from "../../../components/ds/Button";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import AuthService from "../../../services/auth.service";
import toast from "react-hot-toast";

export default function Google2Fa() {
    const navigate = useNavigate();
    const text90 = useTheme().colors.text90;
    const text50 = useTheme().colors.text50;
    const primary50Main = useTheme().colors.primary50Main;
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState<any>("");
    const [otp, setOTP] = useState<any>("");

    useEffect(() => {
        if (searchParams.get("token")) {
            setToken(searchParams.get("token"));
        }
    }, []);

    function handleChange(vals: any) {
        if (vals.length >= 6) {
            setOTP(vals);
        } else if (vals.length === 0) {
        }
    }

    return (
        <>
            <div className={
                "flex xl:flex-row flex-col xl:h-screen " +
                `bg-[${useTheme().colors.surface50Background}]`
            } >
                ©2022{" "}
                <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
            </div>
            <div
                className={
                    "xl:w-8/12 w-full h-full  2xl:px-80 xl:px-56 flex flex-col justify-center p-4"
                }
            >
                <>
                    <div
                        className={
                            "font-normal xl:text-4xl text-3xl mb-2 " + `text-[${text90}]`
                        }
                    >
                        Two-Factor Authentication
                    </div>

                    <div
                        className={
                            "font-normal xl:text-base text-xs mb-8 " + `text-[${text50}]`
                        }
                    >
                        To login to your account, enter the validation code in the Google
                        Authenticator.
                    </div>

                    <div className={"my-8"}>
                        <ReactCodeInput
                            onChange={handleChange}
                            onComplete={(val) => {
                                setOTP(val);
                            }}
                            fields={6}
                        />
                    </div>

                    <Button
                        buttonStyleType={"BPrimary"}
                        text={"Sign in"}
                        onClick={() => {
                            let params = new URLSearchParams();
                            params.append("2fa-token", token);
                            params.append("otp_code", otp);

                            AuthService.login2fa(params)
                                .then((response: any) => {
                                    if (response) {
                                        localStorage.setItem("token", response.data.token);
                                        localStorage.setItem("userEmail", response.data.user.email);
                                        localStorage.setItem(
                                            "userFirst_name",
                                            response.data.user.first_name
                                        );
                                        localStorage.setItem(
                                            "userLast_name",
                                            response.data.user.last_name
                                        );
                                        localStorage.setItem("userRole", response.data.user.role);
                                        navigate("/");
                                        window.location.reload();
                                    }
                                    toast.success(response.data.message)
                                })
                                .catch((err) => {
                                    toast.error(err?.response?.data?.message)
                                    setTimeout(() => {
                                        if (err.response.status === 401) {
                                            navigate("/");
                                        }
                                        if (err.response.status === 404) {
                                            navigate("*");
                                        }
                                    }, 3000);
                                });
                        }}
                        className={"w-full h-12 mt-8"}
                    />

                    <div className={" mt-8 text-center "}>
                        <NavLink
                            to={"/login"}
                            className={
                                "font-normal xl:text-base text-xs pl-1.5 " +
                                `text-[${primary50Main}]`
                            }
                        >
                            Back to login
                        </NavLink>
                    </div>
                </>
            </div>
        </>

  );
}
