import Header from "../../components/ds/Header";
import { Context, ContextWrapper, LayoutWrapper, SideWrapper } from "./styles";
import Sidebar from "../../components/ds/Sidebar";
import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

export default function Layout() {
  const [collapse, setCollapse] = useState(false);
  const [width, setWidth] = useState("320px");
  const [mobileMenu, setMobileMenu] = React.useState(false);

  function changeCollapse() {
    setCollapse(!collapse);

    if (collapse) {
      setWidth("320px");
    } else {
      setWidth("108px");
    }
  }

  function toggleMobileMenu() {
    setMobileMenu(!mobileMenu);
  }

  function renderContent() {
    if (isDesktop) {
      return (
        <Context width={width}>
          <Outlet />
        </Context>
      );
    } else if (isTablet) {
      return (
        <Context width={"108px"}>
          <Outlet />
        </Context>
      );
    } else if (isMobile) {
      return (
        <Context width={"0px"}>
          <Outlet />
        </Context>
      );
    }
  }

  return (
    <LayoutWrapper>
      <Header mobileMenu={mobileMenu} onClickMenu={toggleMobileMenu} />

      <ContextWrapper id={'ContextWrapper-a'}>
        {/*4k display*/}
        <SideWrapper className={"hidden 2xl:block"} width={width}>
          <Sidebar
            collapse={collapse}
            setCollapse={() => changeCollapse()}
            mobileMenu={mobileMenu}
            closeSidebar={toggleMobileMenu}
          />
        </SideWrapper>

        {/*table display*/}
        <SideWrapper className={"hidden xl:block 2xl:hidden"} width={"108px"}>
          <Sidebar
            collapse={true}
            mobileMenu={mobileMenu}
            closeSidebar={toggleMobileMenu}
          />
        </SideWrapper>

        <SideWrapper
          className={"hidden lg:block xl:hidden 2xl:hidden"}
          width={"108px"}
        >
          <Sidebar
            collapse={true}
            mobileMenu={mobileMenu}
            closeSidebar={toggleMobileMenu}
          />
        </SideWrapper>

        {/*mobile display*/}
        <div
          className={`absolute w-full h-[90%] z-20 lg:hidden ${
            mobileMenu ? "block" : "hidden"
          }`}
        >
          <Sidebar />
        </div>

        {renderContent()}
      </ContextWrapper>
    </LayoutWrapper>
  );
}
