import {useTheme} from "styled-components";

export interface NoData_Options {
    icon?: any;
    mobileIcon?: any;
    title: any;
}
export default function SearchResult(props: NoData_Options){

    return(
        <div className={'flex xl:h-[82%] items-center justify-center flex-col mt-20 xl:mt-0'}>
            <div className={'mb-4 hidden xl:block'}>
                {props.icon}
            </div>

            <div className={'mb-4 block xl:hidden'}>
                {props.mobileIcon}
            </div>

            <div className={`font-semibold text-center text-2xl xl:text-3xl tracking-tight text-[${useTheme().colors.text90}]`}>
                {props.title}
            </div>
        </div>
    )
}
