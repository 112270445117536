import { useTheme } from "styled-components";
import ArrowDownOutline from "../../../assets/icons/Arrow-down-outline";
import { SingleCheckbox } from "../../../components/ds/Checkboxes";
import Button from "../../../components/ds/Button";
import { useEffect, useState } from "react";
import ArrowUpOutline from "../../../assets/icons/Arrow-up-outline";
import BaseService from "../../../services/base.service";
import useAuth from "../../../useAuth";
import { useCommon } from "../../../context/commonContext";
import toast from "react-hot-toast";

export interface Filter_Options {
  onClick?: () => void;
  filter?: any;
  collapse?: any;
  closeFilter?: any;
  clear?: any;
  position?: any;
  exchangesSelected?: any;
  strategiesSelected?: any;
  pairSelected?: any;
  searchItems?: any;
}

export default function Filter(props: Filter_Options) {
  const primary50Main = useTheme().colors.primary50Main;
  const surface80Divider = useTheme().colors.surface80Divider;
  const text40 = useTheme().colors.text40;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const [exchangesPanel, setExchangesPanel] = useState(false);
  const [strategiesPanel, setStrategiesPanel] = useState(false);
  const [timeframePanel, setTimeframePanel] = useState(false);
  const [pairPanel, setPairPanel] = useState(false);
  const [exchangeResult, setExchangeResult] = useState<any>([]);
  const [strategiesResult, setStrategiesResult] = useState<any>([]);
  const [timeframeResult, setTimeframeResult] = useState<any>([]);
  const [pairsResult, setPairsResult] = useState<any>([]);
  const [buy, setBuy] = useState<any>(false);
  const [sell, setSell] = useState<any>(false);
  const { token } = useAuth();
  const { exchange } = useCommon();

  useEffect(() => {
    setExchangeResult(exchange)
    BaseService.getStrategies(token)
      .then((response) => {
        setStrategiesResult(response.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
    BaseService.getTimeFrame(token)
      .then((response) => {
        let data = [];
        for (let item of response.data) {
          data.push({ title: item, checked: false });
        }
        setTimeframeResult(data);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });

    setPairsResult([
      {
        'id': 'USDT',
        'name': 'USDT',
        'checked': true
      },
      {
        'id': 'BTC',
        'name': 'BTC'
      }
    ]);
  }, []);


  /**
   * Handle save button click. Build search parameters and call the appropriate filters.
   */
  function handleSaveClick(): void {
    const searchParams = buildSearchParams();
    // Trigger the parent's filter method with the built parameters and close the filter afterwards
    props.filter(searchParams);
    props.closeFilter();
  }

  /**
   * Extract IDs of checked items from an array.
   */
  function gatherSelectedIDs(items: any[]): number[] {
    return items.filter(item => item.checked).map(item => item.id);
  }

  /**
   * Extract titles of checked items from an array.
   */
  function gatherSelectedTitles(items: any[]): string[] {
    return items.filter(item => item.checked).map(item => item.title);
  }

  /**
   * Build the search parameters based on selected options.
   */
  function buildSearchParams(): string {
    const exchangeSelected = gatherSelectedIDs(exchangeResult);
    const strategiesSelected = gatherSelectedIDs(strategiesResult);
    const timeframeSelected = gatherSelectedTitles(timeframeResult);
    const pairSelected = gatherSelectedIDs(pairsResult);

    let params = "";

    // Set position side based on whether buy or sell are selected
    if (buy || sell) {
      params += `&position_side=${buy && sell ? "all" : buy ? "BUY" : "SELL"}`;
    }

    // Add exchange_ids, strategy, timeframe and pair parameters
    params += getItemParams(exchangeSelected, "exchange_ids");
    params += getItemParams(strategiesSelected, "strategy_ids");
    params += getItemParams(timeframeSelected, "time_frames");
    params += getItemParams(pairSelected, "pairs_type");

    return params;
  }

  /**
   * Return a URL parameter string based on provided items and a parameter name.
   */
  function getItemParams(items: (string | number)[], paramName: string): string {
    if (!items.length) return `&${paramName}=all`;
    return `&${paramName}=${items.join(",")}`;
  }

  return (
    <div
      className={
        "fixed z-10 right-0 bottom-0 w-full xl:w-[360px] xl:border  xl:border-solid " +
        `bg-[${useTheme().colors.surface50Background}] ` +
        `xl:border-[${useTheme().colors.surface80Divider}]`
      }
      style={{
        display: props.collapse ? "block" : "none",
        height: "calc(100% - 97px)",
      }}
    >
      <div className={"py-4  xl:py-12 "}>
        <div
          className={
            "flex items-center justify-between mb-3 xl:mb-6 px-5 xl:px-8"
          }
        >
          <div
            className={
              "font-semibold text-2xl xl:text-3xl " +
              `text-[${useTheme().colors.text90}]`
            }
          >
            Filters
          </div>

          <div
            className={
              "hover:cursor-pointer text-sm font-normal " +
              `text-[${useTheme().colors.text50}]`
            }
            onClick={() => {
              setExchangesPanel(false);

              setStrategiesPanel(false);

              setTimeframePanel(false);

              setPairPanel(false);

              setExchangeResult(
                exchangeResult.map((e: any) => {
                  e.checked = false;
                  return e;
                })
              );

              setStrategiesResult(
                strategiesResult.map((e: any) => {
                  e.checked = false;
                  return e;
                })
              );

              setTimeframeResult(
                timeframeResult.map((e: any) => {
                  e.checked = false;
                  return e;
                })
              );

              setPairsResult(
                pairsResult.map((e: any) => {
                  e.checked = false;
                  return e;
                })
              );

              setBuy(false);

              setSell(false);

              props.clear();
            }}
          >
            Clear Filters
          </div>
        </div>
        <div className={"overflow-auto h-[62vh] px-5 xl:px-8"}>
          <div
            className={
              "border-t  border-solid py-3 xl:py-6  " +
              `border-[${useTheme().colors.surface80Divider}]`
            }
          >
            <div className={"flex items-center justify-between"}>
              <div
                className={
                  "text-xs xl:text-base font-semibold mb-2 " +
                  `text-[${useTheme().colors.text50}]`
                }
              >
                Position
              </div>
            </div>

            <div className={"flex gap-2 justify-between"}>
              <div
                className={
                  "hover:cursor-pointer border border-solid text-xs xl:text-base font-normal   w-1/2 px-5 h-9 xl:h-12 rounded-3xl text-center flex justify-center items-center " +
                  ` ${buy
                    ? ` border-[${primary50Main}]`
                    : ` border-[${surface80Divider}]`
                  }  ` +
                  `${buy
                    ? ` text-[${primary50Main}]`
                    : ` text-[${surface80Divider}]`
                  }`
                }
                onClick={(e: any) => setBuy(!buy)}
              >
                BUY
              </div>
              <div
                className={
                  "hover:cursor-pointer border border-solid text-xs xl:text-base font-normal   w-1/2 px-5 h-9 xl:h-12 rounded-3xl text-center flex justify-center items-center " +
                  ` ${sell
                    ? ` border-[${primary50Main}]`
                    : ` border-[${surface80Divider}]`
                  }  ` +
                  `${sell
                    ? ` text-[${primary50Main}]`
                    : ` text-[${surface80Divider}]`
                  }`
                }
                onClick={(e: any) => setSell(!sell)}
              >
                SELL
              </div>
            </div>
          </div>

          <div
            className={
              "border-t  border-solid py-3 xl:py-6 " +
              `border-[${useTheme().colors.surface80Divider}]`
            }
          >
            <div
              className={"flex items-center justify-between"}
              onClick={(e) => setExchangesPanel(!exchangesPanel)}
            >
              <div
                className={
                  "text-xs xl:text-base font-semibold mb-2 " +
                  `text-[${useTheme().colors.text50}]`
                }
              >
                Exchanges
              </div>

              {exchangesPanel ? (
                <ArrowUpOutline fill={text50} />
              ) : (
                <ArrowDownOutline fill={text50} />
              )}
            </div>

            {exchangesPanel ? (
              <>
                {/*<div*/}
                {/*    className={'hover:cursor-pointer  text-xs xl:text-base font-semibold my-4 ' + `text-[${primary50Main}]`}>*/}
                {/*    /!*Deselect all*!/*/}

                {/*    Select all*/}
                {/*</div>*/}

                <div className={"mt-4"}>
                  {exchangeResult?.map((item: any, index: number) => {
                    return (
                      <div key={index}
                        className={
                          "flex items-center justify-between mb-4 last:mb-0"
                        }
                      >
                        <div className={"flex items-center"}>
                          <img className={"h-full w-full w-8 h-8 rounded-full mr-2"} height={35} width={35} src={item.full_logo} />
                          <div
                            className={
                              "text-base font-normal " + `text-[${text90}]`
                            }
                          >
                            {item.name}
                            {item.details ? <sup className={'ml-1'}>{item.details}</sup> : null}
                          </div>
                        </div>

                        <div
                          className={
                            "font-normal text-xs " + `text-[${text40}]`
                          }
                        >
                          <SingleCheckbox
                            onClick={(event) => {
                              exchangeResult[index].checked =
                                !exchangeResult[index].checked;
                              setExchangeResult([...exchangeResult]);
                            }}
                            checked={exchangeResult[index].checked}
                            value={item.id}
                            key={item.id}
                          />
                        </div>
                      </div>
                    );
                  })}

                </div>
              </>
            ) : (
              ""
            )}
          </div>

          <div
            className={
              "border-t  border-solid py-3 xl:py-6 " +
              `border-[${useTheme().colors.surface80Divider}]`
            }
          >
            <div
              className={"flex items-center justify-between"}
              onClick={(e) => setStrategiesPanel(!strategiesPanel)}
            >
              <div
                className={
                  "text-xs xl:text-base font-semibold mb-2 " +
                  `text-[${useTheme().colors.text50}]`
                }
              >
                Strategies
              </div>

              {strategiesPanel ? (
                <ArrowUpOutline fill={text50} />
              ) : (
                <ArrowDownOutline fill={text50} />
              )}
            </div>

            {strategiesPanel ? (
              <>
                <div className={"flex items-center justify-between"}>
                  <div
                    className={
                      "text-sm font-medium relative " + `text-[${text50}]`
                    }
                  >
                    Trend Following
                  </div>
                  <div
                    className={
                      "hover:cursor-pointer text-xs xl:text-base font-semibold my-4 " +
                      `text-[${primary50Main}]`
                    }
                    onClick={() => {
                      if (
                        strategiesResult.filter(
                          (item: any) => item.checked === true
                        ).length === strategiesResult.length
                      )
                        strategiesResult.map(
                          (item: any) => (item.checked = false)
                        );
                      else
                        strategiesResult.map(
                          (item: any) => (item.checked = true)
                        );
                      setStrategiesResult([...strategiesResult]);
                    }}
                  >
                    {strategiesResult.filter(
                      (item: any) => item.checked === true
                    ).length === strategiesResult.length
                      ? "Deselect all"
                      : "Select all"}
                  </div>
                </div>
                <>
                  {strategiesResult.map((item: any, index: number) => {
                    return (
                      <div
                        className={
                          "flex items-center justify-between mb-4 last:mb-0"
                        }
                      >
                        <div
                          className={
                            "text-base font-normal " + `text-[${text90}]`
                          }
                        >
                          {item.name}
                        </div>

                        <div
                          className={
                            "font-normal text-xs " + `text-[${text40}]`
                          }
                        >
                          <SingleCheckbox
                            onClick={(event, item) => {
                              strategiesResult[index].checked = !strategiesResult[index].checked;
                              setStrategiesResult([...strategiesResult]);
                              // props.strategiesSelected(item);
                            }}
                            checked={item.checked}
                            value={item}
                            key={item.id}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              </>
            ) : (
              ""
            )}
          </div>

          <div
            className={
              "border-t  border-solid py-3 xl:py-6 " +
              `border-[${useTheme().colors.surface80Divider}]`
            }
          >
            <div
              className={"flex items-center justify-between"}
              onClick={(e) => setTimeframePanel(!timeframePanel)}
            >
              <div
                className={
                  "text-xs xl:text-base font-semibold mb-2 " +
                  `text-[${useTheme().colors.text50}]`
                }
              >
                Timeframe
              </div>

              {timeframePanel ? (
                <ArrowUpOutline fill={text50} />
              ) : (
                <ArrowDownOutline fill={text50} />
              )}
            </div>

            {timeframePanel && (
              <section>
                <div className={"flex items-center justify-between"}>
                  <div
                    className={
                      "text-sm font-medium relative " + `text-[${text50}]`
                    }
                  >
                    Minute
                  </div>
                  <div
                    className={
                      "hover:cursor-pointer text-xs xl:text-base font-semibold my-4 " +
                      `text-[${primary50Main}]`
                    }
                    onClick={() => {
                      if (
                        timeframeResult
                          .slice(0, 4)
                          .filter((item: any) => item.checked === true)
                          .length === 4
                      )
                        timeframeResult
                          .slice(0, 4)
                          .map((item: any) => (item.checked = false));
                      else
                        timeframeResult
                          .slice(0, 4)
                          .map((item: any) => (item.checked = true));
                      setTimeframeResult([...timeframeResult]);
                    }}
                  >
                    {timeframeResult
                      .slice(0, 4)
                      .filter((item: any) => item.checked === true).length === 4
                      ? "Deselect all"
                      : "Select all"}
                  </div>
                </div>
                <>
                  {timeframeResult
                    .slice(0, 4)
                    .map((item: any, index: number) => {
                      return (
                        <div
                          className={
                            "flex items-center justify-between mb-4 last:mb-0"
                          }
                          onClick={() => {
                            timeframeResult[index].checked =
                              !timeframeResult[index].checked;
                            setTimeframeResult([...timeframeResult]);
                          }}
                        >
                          <div
                            className={
                              "text-base font-normal " + `text-[${text90}]`
                            }
                          >
                            {item.title}
                          </div>

                          <div
                            className={
                              "font-normal text-xs " + `text-[${text40}]`
                            }
                          >
                            <SingleCheckbox
                              onClick={() => ""}
                              checked={item.checked}
                              value={1}
                              key={1}
                            />
                          </div>
                        </div>
                      );
                    })}
                </>

                <div className={"flex items-center justify-between"}>
                  <div
                    className={
                      "text-sm font-medium relative " + `text-[${text50}]`
                    }
                  >
                    Hour
                  </div>
                  <div
                    className={
                      "hover:cursor-pointer text-xs xl:text-base font-semibold my-4 " +
                      `text-[${primary50Main}]`
                    }
                    onClick={() => {
                      if (
                        timeframeResult
                          .slice(4, 10)
                          .filter((item: any) => item.checked === true)
                          .length === 6
                      )
                        timeframeResult
                          .slice(4, 10)
                          .map((item: any) => (item.checked = false));
                      else
                        timeframeResult
                          .slice(4, 10)
                          .map((item: any) => (item.checked = true));
                      setTimeframeResult([...timeframeResult]);
                    }}
                  >
                    {timeframeResult
                      .slice(4, 10)
                      .filter((item: any) => item.checked === true).length === 6
                      ? "Deselect all"
                      : "Select all"}
                  </div>
                </div>
                <>
                  {timeframeResult
                    .slice(4, 10)
                    .map((item: any, index: number) => {
                      return (
                        <div
                          className={
                            "flex items-center justify-between mb-4 last:mb-0"
                          }
                          onClick={() => {
                            timeframeResult[index + 4].checked =
                              !timeframeResult[index + 4].checked;
                            setTimeframeResult([...timeframeResult]);
                          }}
                        >
                          <div
                            className={
                              "text-base font-normal " + `text-[${text90}]`
                            }
                          >
                            {item.title}
                          </div>

                          <div
                            className={
                              "font-normal text-xs " + `text-[${text40}]`
                            }
                          >
                            <SingleCheckbox
                              onClick={() => ""}
                              checked={item.checked}
                              value={2}
                              key={2}
                            />
                          </div>
                        </div>
                      );
                    })}
                </>

                <div className={"flex items-center justify-between"}>
                  <div
                    className={
                      "text-sm font-medium relative " + `text-[${text50}]`
                    }
                  >
                    Day
                  </div>
                  <div
                    className={
                      "hover:cursor-pointer text-xs xl:text-base font-semibold my-4 " +
                      `text-[${primary50Main}]`
                    }
                    onClick={() => {
                      if (
                        timeframeResult
                          .slice(10, 12)
                          .filter((item: any) => item.checked === true)
                          .length === 2
                      )
                        timeframeResult
                          .slice(10, 12)
                          .map((item: any) => (item.checked = false));
                      else
                        timeframeResult
                          .slice(10, 12)
                          .map((item: any) => (item.checked = true));
                      setTimeframeResult([...timeframeResult]);
                    }}
                  >
                    {timeframeResult
                      .slice(10, 12)
                      .filter((item: any) => item.checked === true).length === 2
                      ? "Deselect all"
                      : "Select all"}
                  </div>
                </div>
                {timeframeResult
                  .slice(10, 12)
                  .map((item: any, index: number) => {
                    return (
                      <div
                        className={
                          "flex items-center justify-between mb-4 last:mb-0"
                        }
                        onClick={() => {
                          timeframeResult[index + 10].checked =
                            !timeframeResult[index + 10].checked;
                          setTimeframeResult([...timeframeResult]);
                        }}
                      >
                        <div
                          className={
                            "text-base font-normal " + `text-[${text90}]`
                          }
                        >
                          {item.title}
                        </div>

                        <div
                          className={
                            "font-normal text-xs " + `text-[${text40}]`
                          }
                        >
                          <SingleCheckbox
                            onClick={() => ""}
                            checked={item.checked}
                            value={3}
                            key={3}
                          />
                        </div>
                      </div>
                    );
                  })}
                <div className={"flex items-center justify-between"}>
                  <div
                    className={
                      "text-sm font-medium relative " + `text-[${text50}]`
                    }
                  >
                    Week
                  </div>
                  <div
                    className={
                      "hover:cursor-pointer text-xs xl:text-base font-semibold my-4 " +
                      `text-[${primary50Main}]`
                    }
                    onClick={() => {
                      if (
                        timeframeResult
                          .slice(12, 13)
                          .filter((item: any) => item.checked === true)
                          .length === 1
                      )
                        timeframeResult
                          .slice(12, 13)
                          .map((item: any) => (item.checked = false));
                      else
                        timeframeResult
                          .slice(12, 13)
                          .map((item: any) => (item.checked = true));
                      setTimeframeResult([...timeframeResult]);
                    }}
                  >
                    {timeframeResult
                      .slice(12, 13)
                      .filter((item: any) => item.checked === true).length === 1
                      ? "Deselect all"
                      : "Select all"}
                  </div>
                </div>
                {timeframeResult
                  .slice(12, 13)
                  .map((item: any, index: number) => {
                    return (
                      <div
                        className={
                          "flex items-center justify-between mb-4 last:mb-0"
                        }
                        onClick={() => {
                          timeframeResult[index + 12].checked =
                            !timeframeResult[index + 12].checked;
                          setTimeframeResult([...timeframeResult]);
                        }}
                      >
                        <div
                          className={
                            "text-base font-normal " + `text-[${text90}]`
                          }
                        >
                          {item.title}
                        </div>

                        <div
                          className={
                            "font-normal text-xs " + `text-[${text40}]`
                          }
                        >
                          <SingleCheckbox
                            onClick={() => ""}
                            checked={item.checked}
                            value={4}
                            key={4}
                          />
                        </div>
                      </div>
                    );
                  })}
              </section>
            )}
          </div>

          <div
            className={
              "border-t  border-solid py-3 xl:py-6 " +
              `border-[${useTheme().colors.surface80Divider}]`
            }
          >
            <div
              className={"flex items-center justify-between"}
              onClick={(e) => setPairPanel(!pairPanel)}
            >
              <div
                className={
                  "text-xs xl:text-base font-semibold mb-2 " +
                  `text-[${useTheme().colors.text50}]`
                }
              >
                Pair
              </div>

              {pairPanel ? (
                <ArrowUpOutline fill={text50} />
              ) : (
                <ArrowDownOutline fill={text50} />
              )}
            </div>

            {pairPanel && (
                <div className={"mt-4"}>
                  {pairsResult.map((item: any, index: number) => {
                    return (
                      <div className={'flex items-center justify-between mb-4 last:mb-0'}
                        onClick={() => {
                          pairsResult[index].checked = !pairsResult[index].checked
                          setPairsResult([...pairsResult])
                        }}>
                        <div className={'flex items-center'}>
                          <div
                            className={'text-base font-normal ' + `text-[${text90}]`}>{item.name}
                          </div>
                        </div>

                        <div className={'font-normal text-xs ' + `text-[${text40}]`}>
                          <SingleCheckbox onClick={() => ''} checked={item.checked} value={item.id}
                            key={item.id} />
                        </div>
                      </div>
                    )
                  })}

                  <div
                    className={
                      "flex items-center justify-between mb-4 last:mb-0"
                    }
                  >
                    <div
                      className={"text-base font-normal " + `text-[${text40}]`}
                    >
                      ETH
                    </div>

                    <div
                      className={"font-normal text-xs " + `text-[${text40}]`}
                    >
                      Coming soon
                    </div>
                  </div>
                </div>
            )}
          </div>
        </div>
      </div>


      <div
        className={
          "flex absolute w-full bottom-0 items-center justify-between border-t border-solid px-4 pb-4 pt-3 xl:px-6 xl:py-8 gap-3 " +
          `border-[${useTheme().colors.surface80Divider}]` +
          `text-[${useTheme().colors.text50}] bg-[${useTheme().colors.surface50Background}] `
        }
      >
        <Button
          text={"Cancel"}
          className={
            "px-6 text-xs xl:text-base rounded-3xl border border-solid font-medium  h-9 xl:h-12 w-2/4 " +
            `text-[${useTheme().colors.text50}]`
          }
          onClick={(e: any) => props.closeFilter()}
        />
        <Button
          text="Save"
          buttonStyleType="BPrimary"
          className="w-2/4 h-9 xl:h-12 text-xs xl:text-base"
          onClick={handleSaveClick}
        />
      </div>
      
    </div>
  );
}
