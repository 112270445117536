import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}
const MenuO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z" fill={props.fill}/>
            <path d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z" fill={props.fill}/>
            <path d="M3 17.75H21C21.41 17.75 21.75 17.41 21.75 17C21.75 16.59 21.41 16.25 21 16.25H3C2.59 16.25 2.25 16.59 2.25 17C2.25 17.41 2.59 17.75 3 17.75Z" fill={props.fill}/>
        </svg>
    );
};

export default MenuO;
