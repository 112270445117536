
import TextField from "../../../components/ds/Textfield";
import { useTheme } from "styled-components";
import EyeO from "../../../assets/icons/Eye-o";
import EyeSlashO from "../../../assets/icons/Eye-slash-o";
import Button from "../../../components/ds/Button";
import { NavLink } from "react-router-dom";
import { Formik } from "formik";
import AuthService from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../useAuth";
import { useState } from "react";
import toast from "react-hot-toast";
import { useCommon } from "../../../context/commonContext";


export default function SignIn() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPass, setShowPass] = useState(false);
  const text50 = useTheme().colors.text50;
  const primary50Main = useTheme().colors.primary50Main;
const {getProfile}=useCommon()

  function google() {
    window.open("https://core-api.finomate.io/api/auth/google-login");
  }

  return (
    <div className={`flex xl:flex-row flex-col overflow-auto h-screen bg-[${useTheme().colors.surface50Background}]`}>
      <div className={`xl:w-4/12 w-full h-full items-center xl:p-28 pt-16 px-28 relative xl:bg-[${useTheme().colors.surface60}]`}>
        <svg
          width="480"
          height="480"
          viewBox="0 0 480 480"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: "100%", height: "100%" }}
        >
          <path
            d="M178.133 457.601C273.544 457.601 350.889 442.279 350.889 423.378C350.889 404.478 273.544 389.156 178.133 389.156C82.7231 389.156 5.37778 404.478 5.37778 423.378C5.37778 442.279 82.7231 457.601 178.133 457.601Z"
            fill="#D3D8DA"
          />
          <path
            d="M166.178 35.1105C168.178 42.9772 164.089 50.8883 156.933 54.0438L168.222 98.8883C168.578 100.311 167.689 101.822 166.267 102.177C164.8 102.533 163.333 101.644 162.978 100.222L151.689 55.3772C143.867 56.0438 136.533 50.9327 134.533 43.1105C132.311 34.355 137.644 25.5105 146.356 23.2883C155.111 21.0661 163.956 26.355 166.178 35.1105Z"
            fill="#034A5E"
          />
          <path
            d="M190.622 358.888C190.622 362.976 187.333 366.265 183.244 366.265C179.156 366.265 175.867 362.976 175.867 358.888C175.867 355.776 177.778 353.11 180.533 352.043V306.399C180.533 304.888 181.733 303.688 183.244 303.688C184.711 303.688 185.956 304.888 185.956 306.399V352.043C188.667 353.11 190.622 355.776 190.622 358.888Z"
            fill="#034A5E"
          />
          <path
            d="M287.644 411.109C287.644 416.843 292.311 421.509 298.044 421.509C303.778 421.509 308.444 416.843 308.444 411.109C308.444 407.243 306.356 403.865 303.2 402.087L311.111 317.998C311.333 315.643 309.6 313.554 307.244 313.332C304.889 313.109 302.8 314.843 302.578 317.198L294.667 401.287C290.578 402.665 287.644 406.532 287.644 411.109Z"
            fill="#034A5E"
          />
          <path
            d="M261.689 290.71L292.578 312.71C292.044 314.221 291.778 315.866 291.778 317.599C291.778 325.91 298.533 332.666 306.844 332.666C315.156 332.666 321.911 325.91 321.911 317.599C321.911 309.288 315.156 302.533 306.844 302.533C303.333 302.533 300.133 303.733 297.6 305.733L266.667 283.733C264.756 282.355 262.044 282.799 260.667 284.755C259.333 286.666 259.778 289.333 261.689 290.71Z"
            fill="#034A5E"
          />
          <path
            d="M81.0222 411.109C81.0222 416.843 76.3556 421.509 70.6222 421.509C64.8889 421.509 60.2222 416.843 60.2222 411.109C60.2222 407.243 62.3111 403.865 65.4667 402.087L57.5556 317.998C57.3333 315.643 59.0667 313.554 61.4222 313.332C63.7778 313.109 65.8667 314.843 66.0889 317.198L74 401.287C78.0889 402.665 81.0222 406.532 81.0222 411.109Z"
            fill="#034A5E"
          />
          <path
            d="M106.933 290.71L76.0444 312.71C76.5778 314.221 76.8444 315.866 76.8444 317.599C76.8444 325.91 70.0889 332.666 61.7778 332.666C53.4667 332.666 46.7111 325.91 46.7111 317.599C46.7111 309.288 53.4667 302.533 61.7778 302.533C65.2889 302.533 68.4889 303.733 71.0222 305.733L101.911 283.733C103.822 282.355 106.533 282.799 107.911 284.755C109.333 286.666 108.889 289.333 106.933 290.71Z"
            fill="#034A5E"
          />
          <path
            d="M382.711 155.376C381.289 155.376 380.489 155.376 379.644 155.376C343.244 155.376 306.844 155.376 270.444 155.42C266.089 155.42 262.578 154.176 260.4 150.176C257.022 143.865 261.289 136.265 268.444 135.865C269.422 135.82 270.4 135.82 271.378 135.82C307.422 135.82 343.467 135.82 379.511 135.82C380.356 135.82 381.156 135.82 382.533 135.82C381.778 134.976 381.289 134.398 380.8 133.909C377.111 130.176 373.244 126.576 369.689 122.665C364.578 117.02 367.378 108.354 374.8 106.62C377.689 105.954 380.4 106.576 382.756 108.354C383.467 108.887 384.133 109.554 384.8 110.176C394.044 119.42 403.289 128.665 412.533 137.909C417.422 142.843 417.467 148.398 412.578 153.243C403.2 162.665 393.822 172.043 384.4 181.42C379.022 186.754 371.289 185.687 368.133 179.376C366.222 175.598 366.933 171.287 370.178 167.954C373.644 164.354 377.2 160.887 380.756 157.376C381.378 156.798 381.822 156.309 382.711 155.376Z"
            fill="#034A5E"
          />
          <path
            d="M183.244 338.268C253.569 338.268 310.578 281.259 310.578 210.935C310.578 140.611 253.569 83.6016 183.244 83.6016C112.92 83.6016 55.9111 140.611 55.9111 210.935C55.9111 281.259 112.92 338.268 183.244 338.268Z"
            fill="#1BC8F8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M304.175 250.915C287.41 301.654 239.603 338.268 183.244 338.268C112.92 338.268 55.9111 281.259 55.9111 210.935C55.9111 201.676 56.8994 192.647 58.7763 183.949C73.9652 238.217 116.187 280.537 168.667 292.134C216.434 302.688 267.447 286.619 304.175 250.915Z"
            fill="#07ACDA"
          />
          <path
            d="M475.2 149.778C474.8 152.712 474.489 155.6 474 158.534C469.911 182.4 457.511 200.756 437.156 213.689C421.2 223.823 403.6 227.6 384.933 225.156C361.156 222.089 342.4 210.4 328.578 190.756C325.333 186.134 326.311 180.267 330.667 177.067C335.111 173.778 341.067 174.623 344.444 179.334C350.844 188.356 358.8 195.423 368.8 200.267C394.311 212.667 426.133 204.889 443.156 182.223C452.578 169.645 456.889 155.467 455.244 139.912C452.889 117.912 441.822 101.156 421.911 91.4671C398.222 79.956 375.644 83.1116 355.244 99.8227C351.111 103.2 347.911 107.734 344.444 111.867C341.244 115.734 336.711 117.112 332.444 115.2C326.8 112.667 324.844 106 328.356 100.667C332.178 94.8893 336.756 89.7338 341.956 85.2005C354.133 74.5782 368.267 68.0893 384.267 65.956C399.289 64.0005 413.822 66.0449 427.689 72.3116C443.822 79.6005 456.267 91.0671 464.978 106.445C470.711 116.534 474 127.423 474.844 139.023C474.889 139.778 475.067 140.534 475.2 141.289C475.2 144.178 475.2 146.978 475.2 149.778Z"
            fill="#1BC8F8"
          />
          <path
            d="M151.6 311.112L119.467 347.067C121.422 349.778 122.578 353.112 122.578 356.756C122.578 365.912 115.156 373.334 106 373.334C96.8444 373.334 89.4222 365.912 89.4222 356.756C89.4222 347.601 96.8444 340.178 106 340.178C108.4 340.178 110.711 340.712 112.8 341.645L145.156 305.423C146.711 303.645 149.467 303.512 151.2 305.067C153.022 306.667 153.2 309.378 151.6 311.112Z"
            fill="#034A5E"
          />
          <path
            d="M136.8 435.333C136.8 441.733 131.6 446.933 125.2 446.933C118.8 446.933 113.6 441.733 113.6 435.333C113.6 431.511 115.467 428.088 118.356 425.999L101.911 356.666C101.378 354.355 102.8 352.044 105.111 351.511C107.422 350.977 109.733 352.399 110.267 354.666L126.711 423.822C132.356 424.577 136.8 429.422 136.8 435.333Z"
            fill="#034A5E"
          />
          <path
            d="M273.333 259.245L318.844 275.201C318.444 278.534 319.067 282.001 320.8 285.156C325.244 293.156 335.289 296.09 343.289 291.645C351.289 287.201 354.178 277.156 349.778 269.156C345.378 261.156 335.289 258.223 327.289 262.667C325.156 263.823 323.422 265.423 322.044 267.245L276.178 251.112C273.956 250.312 271.511 251.512 270.711 253.734C269.956 256.001 271.111 258.445 273.333 259.245Z"
            fill="#034A5E"
          />
          <path
            d="M395.6 224.178C398.711 218.578 396.667 211.512 391.067 208.401C385.467 205.29 378.4 207.334 375.333 212.934C373.467 216.312 373.467 220.179 374.933 223.423L326.978 276.134C325.378 277.867 325.511 280.579 327.244 282.179C328.978 283.779 331.689 283.645 333.289 281.912L381.2 229.334C386.533 231.467 392.756 229.378 395.6 224.178Z"
            fill="#034A5E"
          />
          <path
            d="M184.622 260.755C141.644 260.755 106.667 225.777 106.667 182.799C106.667 139.822 141.644 104.844 184.622 104.844C227.6 104.844 262.578 139.822 262.578 182.799C262.578 225.777 227.6 260.755 184.622 260.755ZM184.622 123.644C152 123.644 125.467 150.177 125.467 182.799C125.467 215.422 152 241.955 184.622 241.955C217.244 241.955 243.778 215.422 243.778 182.799C243.778 150.177 217.244 123.644 184.622 123.644Z"
            fill="#034A5E"
          />
          <path
            d="M184.622 234.399C213.12 234.399 236.222 211.297 236.222 182.799C236.222 154.301 213.12 131.199 184.622 131.199C156.124 131.199 133.022 154.301 133.022 182.799C133.022 211.297 156.124 234.399 184.622 234.399Z"
            fill="#034A5E"
          />
          <path
            d="M210.889 192.044C224.414 192.044 235.378 181.08 235.378 167.555C235.378 154.03 224.414 143.066 210.889 143.066C197.364 143.066 186.4 154.03 186.4 167.555C186.4 181.08 197.364 192.044 210.889 192.044Z"
            fill="#1BC8F8"
          />
        </svg>
        <div className={`font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 text-[${useTheme().colors.text30}]`}>
          ©2022
          <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
        </div>
      </div>
      <div className={"xl:w-8/12 w-full h-full 2xl:px-80 xl:px-56 2xl:pt-40 xl:pt-6 p-4"}>
        <div className={`font-normal xl:text-4xl text-3xl mb-2 text-[${useTheme().colors.text90}]`}>
          Welcome Back
        </div>

        <div className={`font-normal xl:text-base text-xs mb-8 text-[${useTheme().colors.text50}]`}>
          We’re happy to see you again . To use your account, you should log in
          first.
        </div>

        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors: any = {};

            if (!values.password) {
              errors.password = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2));
              const params = new URLSearchParams();
              params.append("email", values.email);
              params.append("password", values.password);

              AuthService.login(params)
                .then(({ data }) => {
                  if (data.token_type === "2fa") {
                    navigate("/google-2fa?token=" + data.token);
                  } else {
                    if (data) {
                      login(data.token).then(() => {
                        localStorage.setItem("token", data.token);
                        localStorage.setItem("userEmail", data.user.email);
                        localStorage.setItem(
                          "userFirst_name",
                          data.user.first_name
                        );
                        localStorage.setItem(
                          "userLast_name",
                          data.user.last_name
                        );
                        localStorage.setItem("userRole", data.user.role);
                        getProfile()
                        navigate("/");

                      });

                    }
                  }
                })
                .catch((err) => {
                  // Do something
                  toast.error(err.response.data.message)
   
                  setTimeout(() => {
                    if (err.response.status === 401) {
                      navigate("/");
                    }
                    if (err.response.status === 403) {
                      if (
                        err?.response?.data?.message ===
                        "Your email address is not verified."
                      ) {
                        navigate("/settings/profile");
                      } else {
                        navigate("/plans");
                      }
                    }
                    if (err.response.status === 404) {
                      navigate("*");
                    }
                  }, 3000);
                });
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                type={"email"}
                name={"email"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                size={"Big"}
                label={"Email"}
                parentClassName={"xl:mb-6 mb-4"}
              />
              <TextField
                type={showPass ? "text" : "password"}
                name={"password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                size={"Big"}
                label={"Password"}
                parentClassName={"xl:mb-6 mb-4"}
                hint={"Min 6 characters"}
                suffixOnClick={() => setShowPass(!showPass)}
                suffixIcon={
                  showPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />

              <NavLink to={"/forgetPassword"}>
                <div
                  className={
                    "xl:text-base text-xs font-medium mt-2 text-right " +
                    `text-[${primary50Main}]`
                  }
                >
                  Forgot Password?
                </div>
              </NavLink>

              <Button
                buttonStyleType={"BPrimary"}
                text={"Sign in"}
                status={!isValid}
                className={"w-full h-12 mt-8"}
              />
            </form>
          )}
        </Formik>

        <div
          className={
            "font-normal xl:text-base text-xs 2xl:mt-8 mt-4 text-center " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          Don’t have an account?
          <NavLink
            to={"/signUp"}
            className={
              "font-normal xl:text-base text-xs pl-1.5 " +
              `text-[${useTheme().colors.primary50Main}]`
            }
          >
            Sign up
          </NavLink>
        </div>

        <Button
          buttonStyleType={"MSecondary"}
          text={"Sign in with  Google"}
          onClick={(e: any) => google()}
          className={"w-full h-12 2xl:mt-8 mt-4 mb-4"}
        />
    
      </div>
    </div>
  );
}
