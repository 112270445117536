import { Hint, Input } from "./styles";


export interface TextField_Options {
    required?: any;
    prefixIcon?: any;
    suffixIcon?: any;
    label?: any;
    placeholder?: any;
    hint?: any;
    size: any;
    focus?: any;
    onClick?: () => void;
    onChange?: any
    onBlur?: any;
    suffixOnClick?: any;
    onMouseDown?: () => void;
    onMouseLeave?: () => void;
    type?: string;
    name?: string;
    value?: string;
    parentClassName?: string;
    suffixIconClassName?: string;
    prefixIconClassName?: string;
    readonly?: any;
    ref?: any;
}

export default function TextField(props: TextField_Options) {

    return (
        <Input onMouseDown={props.onMouseDown} onMouseLeave={props.onMouseLeave} ref={props.ref} size={props.size} focus={props.focus}
            required={props.required} className={props.parentClassName}>
            {props.label && <label>{props.label}</label>}
            <div className={'inputHolderWrapper mt-2'}>
                {props.prefixIcon && <div className={props.prefixIconClassName + ' iconHolder'}>
                    {props.prefixIcon}
                </div>}
                <input type={props.type} readOnly={props.readonly} value={props.value} name={props.name} placeholder={props.placeholder} onChange={props.onChange} onBlur={props.onBlur} />
                {props.suffixIcon && <div className={props.suffixIconClassName} onClick={props.suffixOnClick}>
                    {props.suffixIcon}
                </div>}
            </div>
            {props.hint && <Hint>{props.hint}</Hint>}
        </Input>
    )
}
