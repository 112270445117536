import { useTheme } from "styled-components";
import TextField from "../../../components/ds/Textfield";
import EyeSlashO from "../../../assets/icons/Eye-slash-o";
import EyeO from "../../../assets/icons/Eye-o";
import Button from "../../../components/ds/Button";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import toast from "react-hot-toast";

export default function NewPassword() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    let { id } = useParams();
    const [email, setEmail] = useState<any>('')
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [token, setToken] = useState('');

    const [showPass, setShowPass] = useState(false)
    const [confirmShowPass, setConfirmShowPass] = useState(false)

    const mode = useTheme().colors.mode
    const success50Main = useTheme().colors.success50Main;
    const error50Main = useTheme().colors.error50Main;
    const text50 = useTheme().colors.text50;

    useEffect(() => {
        if (searchParams.get("email")) {
            setEmail(searchParams.get("email"))
        }
        if (id) {
            setToken(id)
        }
    }, [])

    return (
        <div className={'flex xl:flex-row flex-col overflow-auto h-screen ' + `bg-[${useTheme().colors.surface50Background}]`}>
            <div
                className={'xl:w-4/12 w-full h-full items-center xl:p-28 pt-16 px-28 relative ' + `xl:bg-[${useTheme().colors.surface60}]`}>
                <svg width="480" height="480" viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style={{ height: '100%', width: '100%' }}>
                    <path
                        d="M225.334 462.8C320.744 462.8 398.089 447.478 398.089 428.578C398.089 409.677 320.744 394.355 225.334 394.355C129.923 394.355 52.5781 409.677 52.5781 428.578C52.5781 447.478 129.923 462.8 225.334 462.8Z"
                        fill="#D3D8DA" />
                    <path
                        d="M246.489 42.7984C246.356 50.8872 240.4 57.5095 232.623 58.7095L231.956 104.932C231.956 106.398 230.711 107.598 229.2 107.598C227.689 107.598 226.534 106.354 226.534 104.843L227.2 58.6206C219.511 57.2428 213.689 50.4428 213.823 42.3095C213.956 33.2872 221.378 26.0872 230.4 26.2206C239.423 26.3984 246.623 33.7761 246.489 42.7984Z"
                        fill="#034A5E" />
                    <path
                        d="M237.823 364.087C237.823 368.176 234.534 371.464 230.445 371.464C226.356 371.464 223.067 368.176 223.067 364.087C223.067 360.976 224.979 358.309 227.734 357.242V311.598C227.734 310.087 228.934 308.887 230.445 308.887C231.912 308.887 233.156 310.087 233.156 311.598V357.242C235.867 358.309 237.823 360.976 237.823 364.087Z"
                        fill="#034A5E" />
                    <path
                        d="M334.845 416.309C334.845 422.042 339.511 426.709 345.245 426.709C350.978 426.709 355.645 422.042 355.645 416.309C355.645 412.442 353.556 409.064 350.4 407.286L358.311 323.198C358.534 320.842 356.8 318.753 354.445 318.531C352.089 318.309 350 320.042 349.778 322.398L341.867 406.486C337.778 407.864 334.845 411.731 334.845 416.309Z"
                        fill="#034A5E" />
                    <path
                        d="M308.889 295.91L339.778 317.91C339.244 319.421 338.978 321.065 338.978 322.798C338.978 331.11 345.733 337.865 354.044 337.865C362.355 337.865 369.111 331.11 369.111 322.798C369.111 314.487 362.355 307.732 354.044 307.732C350.533 307.732 347.333 308.932 344.8 310.932L313.911 288.932C312 287.554 309.289 287.998 307.911 289.954C306.533 291.865 306.978 294.532 308.889 295.91Z"
                        fill="#034A5E" />
                    <path
                        d="M128.222 416.309C128.222 422.042 123.556 426.709 117.822 426.709C112.089 426.709 107.422 422.042 107.422 416.309C107.422 412.442 109.511 409.064 112.667 407.286L104.756 323.198C104.533 320.842 106.267 318.753 108.622 318.531C110.978 318.309 113.067 320.042 113.289 322.398L121.2 406.486C125.289 407.864 128.222 411.731 128.222 416.309Z"
                        fill="#034A5E" />
                    <path
                        d="M154.133 295.91L123.244 317.91C123.778 319.421 124.044 321.065 124.044 322.798C124.044 331.11 117.289 337.865 108.978 337.865C100.667 337.865 93.9111 331.11 93.9111 322.798C93.9111 314.487 100.667 307.732 108.978 307.732C112.489 307.732 115.689 308.932 118.222 310.932L149.111 288.932C151.022 287.554 153.733 287.998 155.111 289.954C156.533 291.865 156.089 294.532 154.133 295.91Z"
                        fill="#034A5E" />
                    <path
                        d="M230.445 343.464C300.769 343.464 357.778 286.454 357.778 216.13C357.778 145.806 300.769 88.7969 230.445 88.7969C160.12 88.7969 103.111 145.806 103.111 216.13C103.111 286.454 160.12 343.464 230.445 343.464Z"
                        fill="#1BC8F8" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M105.986 189.097C121.183 243.388 163.406 285.729 215.867 297.33C263.634 307.884 314.646 291.815 351.375 256.11C355.529 243.537 357.778 230.096 357.778 216.13C357.778 145.806 300.769 88.7969 230.444 88.7969C169.396 88.7969 118.382 131.758 105.986 189.097Z"
                        fill="#1BC8F8" />
                    <path
                        d="M318.756 281.377L366.489 288.132C366.756 291.466 368 294.799 370.356 297.555C376.267 304.532 386.711 305.421 393.689 299.555C400.667 293.643 401.556 283.199 395.645 276.221C389.734 269.243 379.289 268.355 372.311 274.221C370.445 275.777 369.022 277.643 368.045 279.732L320 272.888C317.645 272.532 315.467 274.177 315.156 276.532C314.8 278.843 316.445 281.021 318.756 281.377Z"
                        fill="#034A5E" />
                    <path
                        d="M419.378 203.287C419.467 196.887 414.4 191.598 408 191.509C401.6 191.42 396.311 196.487 396.222 202.887C396.178 206.709 397.955 210.176 400.8 212.309L383.2 281.331C382.622 283.642 384 285.953 386.311 286.531C388.622 287.109 390.933 285.731 391.511 283.42L409.067 214.531C414.755 213.998 419.289 209.198 419.378 203.287Z"
                        fill="#034A5E" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M407.467 125.204C401.392 125.204 396.452 130.144 396.452 136.219V163.775C396.452 169.849 401.392 174.79 407.467 174.79C413.55 174.79 418.482 169.886 418.482 163.775V136.219C418.482 130.144 413.542 125.204 407.467 125.204ZM387.549 136.219C387.549 125.227 396.475 116.301 407.467 116.301C418.459 116.301 427.385 125.227 427.385 136.219V163.775C427.385 174.819 418.451 183.693 407.467 183.693C396.475 183.693 387.549 174.767 387.549 163.775V136.219Z"
                        fill="#034A5E" />
                    <path
                        d="M430.444 207.42H384.044C378.578 207.42 374.089 202.976 374.089 197.465V155.42C374.089 149.954 378.533 145.465 384.044 145.465H430.444C435.911 145.465 440.4 149.909 440.4 155.42V197.465C440.4 202.976 435.911 207.42 430.444 207.42Z"
                        fill="#1BC8F8" />
                    <path
                        d="M406.978 195.419C403.689 195.419 401.022 192.753 401.022 189.464V183.331C401.022 180.042 403.689 177.375 406.978 177.375C410.267 177.375 412.934 180.042 412.934 183.331V189.464C412.934 192.753 410.267 195.419 406.978 195.419Z"
                        fill="#034A5E" />
                    <path
                        d="M198.801 316.311L166.667 352.266C168.623 354.978 169.779 358.311 169.779 361.955C169.779 371.111 162.356 378.533 153.201 378.533C144.045 378.533 136.623 371.111 136.623 361.955C136.623 352.8 144.045 345.378 153.201 345.378C155.601 345.378 157.912 345.911 160.001 346.844L192.356 310.622C193.912 308.844 196.667 308.711 198.401 310.266C200.223 311.822 200.401 314.578 198.801 316.311Z"
                        fill="#034A5E" />
                    <path
                        d="M184.001 440.532C184.001 446.932 178.801 452.132 172.401 452.132C166.001 452.132 160.801 446.932 160.801 440.532C160.801 436.71 162.668 433.288 165.556 431.199L149.112 361.865C148.579 359.554 150.001 357.243 152.312 356.71C154.623 356.176 156.934 357.599 157.468 359.865L173.912 429.021C179.556 429.732 184.001 434.621 184.001 440.532Z"
                        fill="#034A5E" />
                    <path
                        d="M231.823 265.954C188.845 265.954 153.867 230.976 153.867 187.999C153.867 145.021 188.845 110.043 231.823 110.043C274.801 110.043 309.778 145.021 309.778 187.999C309.778 230.976 274.801 265.954 231.823 265.954ZM231.823 128.843C199.201 128.843 172.667 155.376 172.667 187.999C172.667 220.621 199.201 247.154 231.823 247.154C264.445 247.154 290.978 220.621 290.978 187.999C290.978 155.376 264.445 128.843 231.823 128.843Z"
                        fill="#034A5E" />
                    <path
                        d="M231.823 239.598C260.321 239.598 283.423 216.496 283.423 187.998C283.423 159.501 260.321 136.398 231.823 136.398C203.325 136.398 180.223 159.501 180.223 187.998C180.223 216.496 203.325 239.598 231.823 239.598Z"
                        fill="#034A5E" />
                    <path
                        d="M258.267 200.443C271.792 200.443 282.756 189.479 282.756 175.954C282.756 162.429 271.792 151.465 258.267 151.465C244.742 151.465 233.778 162.429 233.778 175.954C233.778 189.479 244.742 200.443 258.267 200.443Z"
                        fill="#1BC8F8" />
                    <path
                        d="M360.675 100.151V99.7452C360.72 95.4345 361.172 92.004 362.029 89.4537C362.887 86.9034 364.106 84.8383 365.686 83.2585C367.265 81.6787 369.161 80.223 371.373 78.8914C372.704 78.0789 373.901 77.1197 374.961 76.0138C376.022 74.8854 376.857 73.5877 377.467 72.1207C378.098 70.6537 378.414 69.0287 378.414 67.2458C378.414 65.034 377.895 63.1156 376.857 61.4907C375.819 59.8657 374.431 58.6131 372.693 57.7329C370.955 56.8527 369.026 56.4126 366.904 56.4126C365.054 56.4126 363.271 56.7963 361.555 57.5637C359.84 58.331 358.407 59.5384 357.256 61.186C356.105 62.8335 355.439 64.9889 355.259 67.652H346.728C346.908 63.8153 347.901 60.5315 349.707 57.8006C351.535 55.0698 353.938 52.9821 356.917 51.5377C359.919 50.0933 363.248 49.3711 366.904 49.3711C370.876 49.3711 374.329 50.161 377.263 51.7408C380.22 53.3207 382.499 55.4873 384.102 58.2407C385.727 60.9941 386.539 64.1312 386.539 67.652C386.539 70.1346 386.156 72.3802 385.388 74.3889C384.644 76.3975 383.56 78.1917 382.138 79.7716C380.739 81.3514 379.046 82.7507 377.06 83.9694C375.074 85.2107 373.483 86.5197 372.287 87.8964C371.091 89.2506 370.222 90.8643 369.68 92.7375C369.139 94.6107 368.845 96.9466 368.8 99.7452V100.151H360.675ZM365.008 120.193C363.338 120.193 361.905 119.595 360.709 118.398C359.513 117.202 358.915 115.769 358.915 114.099C358.915 112.429 359.513 110.996 360.709 109.8C361.905 108.604 363.338 108.005 365.008 108.005C366.679 108.005 368.112 108.604 369.308 109.8C370.504 110.996 371.102 112.429 371.102 114.099C371.102 115.205 370.82 116.221 370.256 117.146C369.714 118.071 368.981 118.816 368.055 119.38C367.153 119.922 366.137 120.193 365.008 120.193Z"
                        fill="#034A5E" />
                    <path
                        d="M425.289 73.3316C423.689 79.6871 418 83.9093 411.778 83.9093L410.8 87.776C410.312 89.8204 408.267 91.0649 406.178 90.5316C404.223 90.0427 402.978 87.9538 403.467 85.9093L405.245 78.7982C405.734 76.7538 407.823 75.5093 409.823 75.9982L410 76.0427C410.045 76.0427 410.134 76.0871 410.178 76.0871C413.645 76.9316 417.112 74.8871 417.956 71.4204C418.8 67.9538 416.756 64.4871 413.289 63.6427C411.378 63.1538 409.378 63.5982 407.867 64.7093C406.178 65.9982 403.823 65.6871 402.534 63.9538C401.334 62.2649 401.645 59.9093 403.334 58.7093C406.712 56.1316 411.067 55.2871 415.156 56.3093C422.578 58.2649 427.156 65.8205 425.289 73.3316ZM408.089 99.5093C407.556 101.598 405.556 102.798 403.512 102.309L403.067 102.22C401.023 101.732 399.823 99.6871 400.356 97.5982C400.845 95.6427 402.889 94.3538 404.889 94.8871L405.334 94.976C407.378 95.4649 408.578 97.5093 408.089 99.5093Z"
                        fill="#034A5E" />
                </svg>

                <div className={'font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 ' + `text-[${useTheme().colors.text30}]`}>
                    ©2022 <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
                </div>
            </div>
            <div className={'xl:w-8/12 w-full h-full  2xl:px-80 xl:px-56 2xl:pt-40 xl:pt-6 p-4'}>

                <div className={'font-normal xl:text-4xl text-3xl mb-2 ' + `text-[${useTheme().colors.text90}]`}>
                    New Password
                </div>

                <div className={'font-normal xl:text-base text-xs mb-8 ' + `text-[${useTheme().colors.text50}]`}>
                    Please enter and confirm your new password
                </div>


                <TextField size={'Big'} label={'Password'} hint={'Min 6 characters'} parentClassName={'mb-6'}
                    onChange={(event: any) => setPassword(event.target.value)}
                    suffixOnClick={() => setShowPass(!showPass)} type={showPass ? 'text' : 'password'}
                    suffixIcon={showPass ? <EyeSlashO fill={text50} /> : <EyeO fill={text50} />} />
                <TextField size={'Big'} label={'Confirm password'}
                    onChange={(event: any) => setPassword_confirmation(event.target.value)}
                    suffixOnClick={() => setConfirmShowPass(!confirmShowPass)}
                    type={confirmShowPass ? 'text' : 'password'}
                    suffixIcon={confirmShowPass ? <EyeSlashO fill={text50} /> : <EyeO fill={text50} />} />


                <Button buttonStyleType={'BPrimary'}
                    text={'Change password'}
                    className={'w-full h-12 mt-8 mb-8'}
                    onClick={() => {
                        AuthService.resetPassword({ email, token, password, password_confirmation }).then((response: any) => {
                            toast.error(response.data.message)
                            navigate('/')
                        }).catch((err) => {
                            toast.error(err.response.data.message)
                            setTimeout(() => {
                                if (err.response.status === 401) {
                                    navigate('/')
                                }
                                if (err.response.status === 403) {
                                    if (err?.response?.data?.message === 'Your email address is not verified.') {
                                        navigate('/settings/profile')
                                    } else {
                                        navigate('/plans')
                                    }
                                }
                                if (err.response.status === 404) {
                                    navigate('*')
                                }
                            }, 3000);
                        })

                    }} />


                <NavLink to={'/login'} className={'font-normal xl:text-base text-xs block m-auto text-center ' + `text-[${useTheme().colors.primary50Main}]`}>
                    Back to login
                </NavLink>
            </div>


        </div>
    )
}
