import { CartDes, CartTitle, ItemCart, Pattern } from "./listCartStyles";
import MoreO from "../../assets/icons/More-o";
import { useTheme } from "styled-components";
import bannerBG from './cartPattern.png'
import bannerBGLight from './cartPatternLight.png'
import DropDown from "../../components/ds/DropDown";
import { useRef, useState } from "react";
import { useOutsideClick } from "../../components/ds/Hooks/useOutsideClick";
import noPhoto from "../../assets/images/no-photo.png";

export interface CartItem_Options {
    cartTitle?: any;
    cartDes?: any;
    pairs?: any;

    editAction?: any;
    deleteAction?: any;
}

export default function ListCart(props: CartItem_Options) {
    const [action, setAction] = useState(false)

    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, () => {
        setAction(false)
    })
    return (
        <ItemCart className={'mb-4 lg:mb-0'}>
            <div className={'flex  justify-between p-4 xl:p-6'}>
                <div>
                    <CartTitle className={'font-medium text-sm xl:text-lg mb-2'}>
                        {props.cartTitle}
                    </CartTitle>
                    <CartDes className={'font-normal text-xs xl:text-base '}>
                        {props.cartDes}
                    </CartDes>
                </div>
                <div ref={wrapperRef}>
                    <div onClick={(event) => {
                        event.preventDefault()
                        setAction(!action)
                    }}>
                        <MoreO fill={useTheme().colors.text60} />
                    </div>
                    {action && <DropDown onEdit={() => props.editAction()} onDelete={() => props.deleteAction()} />}
                </div>
            </div>

            <Pattern className={'flex px-4 py-3 xl:px-6 xl:py-4 rounded-bl-3xl rounded-br-3xl bg-cover bg-no-repeat relative '} style={useTheme().colors.mode === 'dark' ? { backgroundImage: `url(${bannerBG}` } : { backgroundImage: `url(${bannerBGLight}` }}>
                {props.pairs.filter((item: any, index: number) => index < 5).map((filteredItem: any, i: number) => {
                    return (
                        <div className={'logo'} style={{ left: (i * (-12)) + 'px' }}>
                            <img className={'h-full w-full  rounded-full'} src={filteredItem.logo ? filteredItem.logo : noPhoto} />
                        </div>

                    )
                })}


                {props.pairs.length > 5 && <div className={'total relative xl:py-2 xl:px-4 p-2 rounded-[20px] font-normal text-xs xl:text-base h-8 xl:h-10'} style={{ left: (5 * (-12)) + 'px' }}>
                    {props.pairs.length - 5} more
                </div>}
            </Pattern>
        </ItemCart>
    )
}
