import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import Button from "../../components/ds/Button";

export default function ExchangeAPI() {
  return (
    <div className={"w-full xl:w-4/5 2xl:w-3/5"}>
      <div
        className={`border border-solid rounded-3xl p-4 mb-6 border-[${
          useTheme().colors.surface80Divider
        }]`}
      >
        <div
          className={`font-semibold text-base xl:text-xl mb-2 text-[${
            useTheme().colors.text90
          }]`}
        >
          Binance
          <a
            className={`font-semibold text-xs xl:text-base pl-1.5 text-[${
              useTheme().colors.primary50Main
            }]`}
          >
            (How to connect?)
          </a>
        </div>

        <div
          className={
            "flex flex-col xl:flex-row justify-between items-center gap-3"
          }
        >
          <TextField
            size={"Big"}
            placeholder={"Your API"}
            parentClassName={"w-full"}
          />

          <Button
            buttonStyleType={"BPrimary"}
            text={"Connect"}
            className={"h-12 w-full xl:w-fit"}
          />
        </div>
      </div>

      <div
        className={`border border-solid rounded-3xl p-4 mb-6 border-[${
          useTheme().colors.surface80Divider
        }]`}
      >
        <div
          className={`font-semibold text-base xl:text-xl mb-2 text-[${
            useTheme().colors.text90
          }]`}
        >
          Cocoin
          <a
            className={`font-semibold text-xs xl:text-base pl-1.5 text-[${
              useTheme().colors.primary50Main
            }]`}
          >
            (How to connect?)
          </a>
        </div>

        <div
          className={
            "flex flex-col xl:flex-row justify-between items-center gap-3"
          }
        >
          <TextField
            size={"Big"}
            placeholder={"Your API"}
            parentClassName={"w-full"}
          />

          <Button
            buttonStyleType={"BPrimary"}
            text={"Connect"}
            className={"h-12 w-full xl:w-fit"}
          />
        </div>
      </div>
    </div>
  );
}
