import { Bottom, ChangeMode, Collapse, CopyRight, EachMode, Side, Upgrade, } from "./styles";
import { NavLink, useLocation, useNavigate, useSearchParams, } from "react-router-dom";
import { useTheme } from "styled-components";
import HomeB from "../../../assets/icons/Home-b";
import { useEffect, useState } from "react";
import SidebarItem from "../SidebarItem";
import { useThemeContext } from "../../../theme";
import { Mode } from "../../../types/theme";
import ListO from "../../../assets/icons/List-o";
import HomeO from "../../../assets/icons/Home-o";
import NotificationO from "../../../assets/icons/Notification-o";
import ChartO from "../../../assets/icons/Chart-o";
import WalletO from "../../../assets/icons/Wallet-o";
import ListB from "../../../assets/icons/List-b";
import NotificationB from "../../../assets/icons/Notification-b";
import WalletB from "../../../assets/icons/Wallet-b";
import SettingO from "../../../assets/icons/Setting-o";
import SettingB from "../../../assets/icons/Setting-b";
import PremiumO from "../../../assets/icons/Premium-o";
import SunB from "../../../assets/icons/Sun-b";
import MoonO from "../../../assets/icons/Moon-o";
import SunO from "../../../assets/icons/Sun-o";
import MoonB from "../../../assets/icons/Moon-b";
import ArrowLeftOutline from "../../../assets/icons/Arrow-left-outline";
import ArrowRightOutline from "../../../assets/icons/Arrow-right-outline";
import EmailO from "../../../assets/icons/Email-o";
import EmailB from "../../../assets/icons/Email-b";
import AccountService from "../../../services/account.service";
import CloseO from "../../../assets/icons/Close-o";
import noPhoto from "../../../assets/images/no-photo.png";
import moment from "moment/moment";
import useAuth from "../../../useAuth";
import { useCommon } from "../../../context/commonContext";


export interface Sidebar_Option {
    width?: any;
    collapse?: any;
    setCollapse?: () => void;
    closeSidebar?: any;
    mobileMenu?: boolean;
}

export default function Sidebar(props: Sidebar_Option) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const { mode, changeMode } = useThemeContext();
    const [active, setActive] = useState(true);

    const toggleMode = () => {
        changeMode(mode === Mode.Light ? Mode.Dark : Mode.Light);
        if (mode === "light") {
            setActive(true);
            localStorage.setItem("theme", "dark");
        } else {
            setActive(false);
            localStorage.setItem("theme", "light");
        }
    };

    const location = useLocation();

    const menuItems = [
        {
            id: 1,
            normalIcon: <HomeO fill={useTheme().colors.text50} />,
            activeIcon: <HomeB fill={useTheme().colors.primary50Main} />,
            title: "dashboard",
            to: "/",
            badge: "",
            active: true,
        },
        {
            id: 2,
            normalIcon: <ListO fill={useTheme().colors.text50} />,
            activeIcon: <ListB fill={useTheme().colors.primary50Main} />,
            title: "lists",
            to: "/lists",
            badge: "",
            active: false,
        },
        {
            id: 3,
            normalIcon: <NotificationO fill={useTheme().colors.text50} />,
            activeIcon: <NotificationB fill={useTheme().colors.primary50Main} />,
            title: "alarms",
            to: "/alarms",
            badge: "",
            active: false,
        },
        {
            id: 4,
            normalIcon: <ChartO fill={useTheme().colors.text50} />,
            activeIcon: <ChartO fill={useTheme().colors.primary50Main} />,
            title: "chart",
            to: "/chart",
            badge: "",
            active: false,
        },
        {
            id: 5,
            normalIcon: <WalletO fill={useTheme().colors.text50} />,
            activeIcon: <WalletB fill={useTheme().colors.primary50Main} />,
            title: "wallet",
            to: "/wallet",
            badge: "",
            active: false,
        },
        {
            id: 6,
            normalIcon: <EmailO fill={useTheme().colors.text50} />,
            activeIcon: <EmailB fill={useTheme().colors.primary50Main} />,
            title: "tickets",
            to: "/tickets",
            badge: "",
            active: false,
        },
        {
            id: 7,
            normalIcon: <SettingO fill={useTheme().colors.text50} />,
            activeIcon: <SettingB fill={useTheme().colors.primary50Main} />,
            title: "settings",
            to: "/settings/profile",
            badge: "",
            active: false,
        },
    ];

    const moreItemsMobile = [
        { id: 1, title: "FAQ", to: "https://finomate.io/#faq" },
        { id: 2, title: "Terms of Use", to: "https://finomate.io/terms.html" },
        { id: 3, title: "About", to: "https://finomate.io/about-us.html" },
        { id: 4, title: "Contact", to: "https://finomate.io/contact-us.html" },
    ];

    const text50 = useTheme().colors.text50;
    const text60 = useTheme().colors.text60;
    const text20 = useTheme().colors.text20;
    const text90 = useTheme().colors.text90;
    const text80Body = useTheme().colors.text80Body;
    const primary50Main = useTheme().colors.primary50Main;
    const surface80Divider = useTheme().colors.surface80Divider;
    const surface50Background = useTheme().colors.surface50Background;
    const [searchParams] = useSearchParams();
    const [guide, setGuide] = useState(false);
    const [step, setStep] = useState("1");
    const { profile, gitsDays } = useCommon()

    useEffect(() => {
        if (searchParams.get("onBoarding")) {
            setGuide(true);
        }
    }, []);

    function logout() {
        AccountService.logout(token)
            .then((response) => {
                if (response.status === 200) {
                    navigate("/login");
                    localStorage.clear();
                }
            })
            .catch((err) => {
            });
    }

    function Steps() {
        if (step === "1") {
            return (
                <div className={`rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[116px] bg-[${surface50Background}]`}>
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={`font-semibold text-xl text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div className={`w-full h-px my-6 xl:hidden bg-[${surface80Divider}]`}></div>

                    <div className={`font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl text-[${text90}]`}>
                        Dashboard
                    </div>

                    <div className={`text-base mb-3 text-xs xl:font-normal text-[${text80Body}]`}>
                        On this page, you can see the list of the latest opportunities
                        detected by Finomate, and by selecting any of these opportunities,
                        you can access the charting page of that opportunity.
                    </div>

                    <div className={`hidden xl:block w-full h-px my-6 bg-[${surface80Divider}]`}></div>

                    <div className={"flex items-center justify-center my-3 xl:hidden gap-2"}>
                        <div className={`w-2 h-2 rounded-full bg-[${primary50Main}]`}></div>
                        <div className={`w-1.5 h-1.5 rounded-full bg-[${surface80Divider}]`}></div>
                        <div className={`w-1.5 h-1.5 rounded-full bg-[${surface80Divider}]`}></div>
                        <div className={`w-1.5 h-1.5 rounded-full bg-[${surface80Divider}]`}></div>
                        <div className={`w-1.5 h-1.5 rounded-full bg-[${surface80Divider}]`}></div>
                        <div className={`w-1.5 h-1.5 rounded-full bg-[${surface80Divider}]`}></div>
                        <div className={`w-1.5 h-1.5 rounded-full bg-[${surface80Divider}]`}></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={`font-semibold font-base text-[${text90}]`}>
                                Step 1
                            </div>
                            <div className={`font-semibold font-base text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div
                            onClick={() => setStep("2")}
                            className={
                                "hover:cursor-pointer text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                `border-[${surface80Divider}]` +
                                ` text-[${text50}]`
                            }
                        >
                            Next
                        </div>
                    </div>
                </div>
            );
        } else if (step === "2") {
            return (
                <div
                    className={
                        "rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[180px] " +
                        `bg-[${surface50Background}]`
                    }
                >
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={"font-semibold text-xl " + `text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div
                        className={
                            "w-full h-px my-6 xl:hidden " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={
                            "font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl " +
                            `text-[${text90}]`
                        }
                    >
                        Lists
                    </div>

                    <div
                        className={
                            "text-base mb-3 text-xs xl:font-normal " + `text-[${text80Body}]`
                        }
                    >
                        In this section, you can create specific lists according to your
                        trading needs (coin, time frame, ...) and you no longer need to use
                        the filter to check the latest detected opportunities.
                    </div>

                    <div
                        className={
                            "hidden xl:block w-full h-px my-6 " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={"flex items-center justify-center my-3 xl:hidden gap-2"}
                    >
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={"w-2 h-2 rounded-full " + `bg-[${primary50Main}]`}
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={"font-semibold font-base " + `text-[${text90}]`}>
                                Step 2
                            </div>
                            <div className={"font-semibold font-base " + `text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div className={"flex gap-2 w-full xl:w-fit"}>
                            <div
                                onClick={() => setStep("1")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Previous
                            </div>
                            <div
                                onClick={() => setStep("3")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Next
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (step === "3") {
            return (
                <div
                    className={
                        "rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[244px] " +
                        `bg-[${surface50Background}]`
                    }
                >
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={"font-semibold text-xl " + `text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div
                        className={
                            "w-full h-px my-6 xl:hidden " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={
                            "font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl " +
                            `text-[${text90}]`
                        }
                    >
                        Alarms
                    </div>

                    <div
                        className={
                            "text-base mb-3 text-xs xl:font-normal " + `text-[${text80Body}]`
                        }
                    >
                        Make your own alarm! In this section, you can ask Finomate to inform
                        you. You can use the "Add new alarm" button to create your own
                        alarm.
                    </div>

                    <div
                        className={
                            "hidden xl:block w-full h-px my-6 " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={"flex items-center justify-center my-3 xl:hidden gap-2"}
                    >
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={"w-2 h-2 rounded-full " + `bg-[${primary50Main}]`}
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={"font-semibold font-base " + `text-[${text90}]`}>
                                Step 3
                            </div>
                            <div className={"font-semibold font-base " + `text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div className={"flex gap-2 w-full xl:w-fit"}>
                            <div
                                onClick={() => setStep("2")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Previous
                            </div>
                            <div
                                onClick={() => setStep("4")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Next
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (step === "4") {
            return (
                <div
                    className={
                        "rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[308px] " +
                        `bg-[${surface50Background}]`
                    }
                >
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={"font-semibold text-xl " + `text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div
                        className={
                            "w-full h-px my-6 xl:hidden " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={
                            "font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl " +
                            `text-[${text90}]`
                        }
                    >
                        Chart
                    </div>

                    <div
                        className={
                            "text-base mb-3 text-xs xl:font-normal " + `text-[${text80Body}]`
                        }
                    >
                        There must be some text here. This text is a tutorial containing
                        what is this section and how user can work with it.
                    </div>

                    <div
                        className={
                            "hidden xl:block w-full h-px my-6 " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={"flex items-center justify-center my-3 xl:hidden gap-2"}
                    >
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={"w-2 h-2 rounded-full " + `bg-[${primary50Main}]`}
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={"font-semibold font-base " + `text-[${text90}]`}>
                                Step 4
                            </div>
                            <div className={"font-semibold font-base " + `text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div className={"flex gap-2 w-full xl:w-fit"}>
                            <div
                                onClick={() => setStep("3")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Previous
                            </div>
                            <div
                                onClick={() => setStep("5")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Next
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (step === "5") {
            return (
                <div
                    className={
                        "rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[372px] " +
                        `bg-[${surface50Background}]`
                    }
                >
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={"font-semibold text-xl " + `text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div
                        className={
                            "w-full h-px my-6 xl:hidden " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={
                            "font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl " +
                            `text-[${text90}]`
                        }
                    >
                        Wallet
                    </div>

                    <div
                        className={
                            "text-base mb-3 text-xs xl:font-normal " + `text-[${text80Body}]`
                        }
                    >
                        By using the chart tab, you have free and exclusive access to
                        Trading View
                    </div>

                    <div
                        className={
                            "hidden xl:block w-full h-px my-6 " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={"flex items-center justify-center my-3 xl:hidden gap-2"}
                    >
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={"w-2 h-2 rounded-full " + `bg-[${primary50Main}]`}
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={"font-semibold font-base " + `text-[${text90}]`}>
                                Step 5
                            </div>
                            <div className={"font-semibold font-base " + `text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div className={"flex gap-2 w-full xl:w-fit"}>
                            <div
                                onClick={() => setStep("4")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Previous
                            </div>
                            <div
                                onClick={() => setStep("6")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Next
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (step === "6") {
            return (
                <div
                    className={
                        "rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[436px] " +
                        `bg-[${surface50Background}]`
                    }
                >
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={"font-semibold text-xl " + `text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div
                        className={
                            "w-full h-px my-6 xl:hidden " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={
                            "font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl " +
                            `text-[${text90}]`
                        }
                    >
                        Settings
                    </div>

                    <div
                        className={
                            "text-base mb-3 text-xs xl:font-normal " + `text-[${text80Body}]`
                        }
                    >
                        Using wallet, you can recharge your account, pay your bill, renew
                        your subscription, etc
                    </div>

                    <div
                        className={
                            "hidden xl:block w-full h-px my-6 " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={"flex items-center justify-center my-3 xl:hidden gap-2"}
                    >
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={"w-2 h-2 rounded-full " + `bg-[${primary50Main}]`}
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={"font-semibold font-base " + `text-[${text90}]`}>
                                Step 6
                            </div>
                            <div className={"font-semibold font-base " + `text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div className={"flex gap-2 w-full xl:w-fit"}>
                            <div
                                onClick={() => setStep("5")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Previous
                            </div>
                            <div
                                onClick={() => setStep("7")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Next
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (step === "7") {
            return (
                <div
                    className={
                        "rounded-3xl p-6 w-fit xl:w-[480px] absolute left-[15px] right-[15px]  xl:left-[304px] xl:top-[500px] " +
                        `bg-[${surface50Background}]`
                    }
                >
                    <span className="absolute top-[40px] left-[-16px] hidden xl:block">
                        <svg
                            width="16"
                            height="32"
                            viewBox="0 0 16 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M0 16L16 0L16 32L0 16Z" fill={surface50Background} />
                        </svg>
                    </span>

                    <div className={"flex justify-between xl:hidden "}>
                        <div className={"font-semibold text-xl " + `text-[${text90}]`}>
                            Tutorial
                        </div>

                        <div onClick={() => setGuide(false)}>
                            <CloseO fill={text60} />
                        </div>
                    </div>

                    <div
                        className={
                            "w-full h-px my-6 xl:hidden " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={
                            "font-semibold mb-2 xl:mb-6 text-sm xl:text-2xl " +
                            `text-[${text90}]`
                        }
                    >
                        Tickets
                    </div>

                    <div
                        className={
                            "text-base mb-3 text-xs xl:font-normal " + `text-[${text80Body}]`
                        }
                    >
                        There must be some text here. This text is a tutorial containing
                        what is this section and how user can work with it.
                    </div>

                    <div
                        className={
                            "hidden xl:block w-full h-px my-6 " + `bg-[${surface80Divider}]`
                        }
                    ></div>

                    <div
                        className={"flex items-center justify-center my-3 xl:hidden gap-2"}
                    >
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={
                                "w-1.5 h-1.5 rounded-full " + `bg-[${surface80Divider}]`
                            }
                        ></div>
                        <div
                            className={"w-2 h-2 rounded-full " + `bg-[${primary50Main}]`}
                        ></div>
                    </div>
                    <div className={"flex justify-between items-center"}>
                        <div className={"hidden xl:flex items-center"}>
                            <div className={"font-semibold font-base " + `text-[${text90}]`}>
                                Step 7
                            </div>
                            <div className={"font-semibold font-base " + `text-[${text50}]`}>
                                /7
                            </div>
                        </div>

                        <div className={"flex gap-2 w-full xl:w-fit"}>
                            <div
                                onClick={() => setStep("6")}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${surface80Divider}]` +
                                    ` text-[${text50}]`
                                }
                            >
                                Previous
                            </div>
                            <div
                                onClick={() => setGuide(false)}
                                className={
                                    "cursor-pointer w-1/2 xl:w-fit text-base font-medium border border-solid rounded-3xl h-12 px-6 flex items-center justify-center " +
                                    `border-[${primary50Main}]` +
                                    ` text-[${text20}]` +
                                    ` bg-[${primary50Main}]`
                                }
                            >
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    function accountStatus() {
        if (profile?.active_plan === null && !profile?.trial_plan) {
            return <>{profile?.account_status} {gitsDays > 0 && `+ ${gitsDays} days gift`}</>;
        } else if (profile?.active_plan === null && profile?.trial_plan) {
            return (
                <>
                    {7 - moment().diff(profile.created_at, "days") + parseInt(gitsDays)}{" "}
                    days left {gitsDays > 0 && `+ ${gitsDays} days gift`}
                </>
            );
        } else if (profile?.active_plan) {
            return (
                <>
                    {gitsDays === 0
                        ? `${profile?.daysToExpire} days left`
                        : `${parseInt(profile?.daysToExpire) + parseInt(gitsDays)
                        } days left`}{" "}
                    {gitsDays > 0 && `+ ${gitsDays} days gift`}
                </>
            );
        } else if (!profile?.active_plan) {
            return <>{profile?.account_status} {gitsDays > 0 && `+ ${gitsDays} days gift`}</>;
        }
    }

    const theme = useTheme();
    const Icon = mode === "light" ? SunB : SunO;

    const darkIcon = MoonB;
    const lightIcon = MoonO;
    const Icon2 = mode === "dark" ? darkIcon : lightIcon;

    const darkFillColor = theme.colors.primary50Main;
    const lightFillColor = theme.colors.text50;
    const fillColor = mode === "dark" ? darkFillColor : lightFillColor;

    return (
        <>
            <Side className={" py-4 2xl:py-12 flex"}>
                <div>
                    <Collapse className={"hidden 2xl:flex"} onClick={props.setCollapse}>
                        {props.collapse ? (
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            <ArrowLeftOutline fill={useTheme().colors.text50} />
                        ) : (
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            <ArrowRightOutline fill={useTheme().colors.text50} />
                        )}
                    </Collapse>
                    <ul className={"relative"}>
                        {menuItems.map((item, index: number) => (
                            <li
                                className={"px-6 relative xl:px-10 mb-2.5 xl:mb-2 item" + index}
                                key={item.id}
                            >
                                <NavLink
                                    to={item.to}
                                    onClick={(e) => props.closeSidebar(!props.mobileMenu)}
                                >
                                    {({ isActive }) => (
                                        <SidebarItem
                                            title={item.title}
                                            collapse={props.collapse}
                                            status={isActive && location.pathname === item.to}
                                            icon={
                                                isActive && location.pathname === item.to
                                                    ? item.activeIcon
                                                    : item.normalIcon
                                            }
                                            badge={item.badge}
                                            className={
                                                isActive && location.pathname === item.to
                                                    ? "activeItem"
                                                    : ""
                                            }
                                            titleClassName={"Font-Headline-6"}
                                        />
                                    )}
                                </NavLink>
                            </li>
                        ))}
                    </ul>

                    {profile?.active_plan ? (
                        ""
                    ) : (
                        <NavLink to={"/plans"}>
                            <Upgrade collapse={props.collapse} className={"Font-Bold"}>
                                <PremiumO fill={text20} />
                                <span className="text-sm my-auto">
                                    {props.collapse ? "" : "Upgrade now"}
                                </span>
                            </Upgrade>
                        </NavLink>
                    )}

                    <div className={"px-4 block lg:hidden"}>
                        <div
                            className={`my-4 w-full h-px bg-[${useTheme().colors.surface80Divider
                                }]`}
                        />

                        {moreItemsMobile.map((item) => {
                            return (
                                <a href={item.to} target={"_blank"}>
                                    <div
                                        className={`text-xs font-medium mb-2.5 text-[${text50}]`}
                                    >
                                        {item.title}
                                    </div>
                                </a>
                            );
                        })}

                        <div
                            className={`my-4 w-full h-px bg-[${useTheme().colors.surface80Divider
                                }]`}
                        />

                        <div className={"flex"}>
                            <div className={"w-10 h-10 rounded-full bg-cyan-100 mr-2"}>
                                <img
                                    className={"w-full h-full rounded-full object-cover"}
                                    src={profile?.avatar != null ? profile?.avatar : noPhoto}
                                />
                            </div>
                            <div>
                                <div
                                    className={`text-sm font-medium text-[${useTheme().colors.text90
                                        }]`}
                                >
                                    {profile?.first_name} {profile?.last_name}
                                </div>
                                <div
                                    className={`text-xs font-normal text-[${useTheme().colors.text60
                                        }]`}
                                >
                                    {profile?.email}
                                </div>
                            </div>
                        </div>

                        <div className={"text-center mt-4"}>
                            <div
                                className={`rounded-3xl w-fit py-1 px-4 font-semibold text-base m-auto text-[${useTheme().colors.text30
                                    }] bg-[${useTheme().colors.surface60}]`}
                            >
                                {profile?.active_plan
                                    ? profile?.active_plan?.plan?.name
                                    : "Freemium"}
                            </div>

                            <div
                                className={`text-sm font-normal my-1 text-[${useTheme().colors.text60
                                    }]`}
                            >
                                {accountStatus()}
                            </div>
                        </div>

                        <div
                            className={`my-4 w-full h-px bg-[${useTheme().colors.surface80Divider
                                }]`}
                        />

                        <div
                            className={`text-xs font-semibold text-[${useTheme().colors.error50Main
                                }]`}
                            onClick={(e) => logout()}
                        >
                            Logout
                        </div>
                    </div>
                </div>

                <Bottom className={"hidden lg:block"}>
                    <ChangeMode collapse={props.collapse}>
                        <EachMode
                            collapse={props.collapse}
                            mode={mode === "light"}
                            onClick={toggleMode}
                        >
                            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                            <Icon
                                fill={
                                    mode === "light"
                                        ? theme.colors.primary50Main
                                        : theme.colors.text50
                                }
                            />

                            {props.collapse ? "" : "Light"}
                        </EachMode>
                        <EachMode
                            collapse={props.collapse}
                            mode={mode === "dark"}
                            onClick={toggleMode}
                        >
                            {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
                            <Icon2 fill={fillColor} />
                            {props.collapse ? "" : "Dark"}
                        </EachMode>
                    </ChangeMode>

                    <CopyRight className={"Font-Body hidden 2xl::block"}>
                        ©2022 <span>Finomate</span>
                    </CopyRight>
                </Bottom>
            </Side>

            {guide && (
                <div
                    className={"fixed  flex items-center  w-full z-20 h-screen top-0"}
                    style={{ backgroundColor: "rgba(14, 15, 17, 0.48)" }}
                >
                    {Steps()}
                </div>
            )}
        </>
    );
}
