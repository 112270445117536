import styled, {css} from "styled-components";

export const LabelCheckboxWrapper = styled.div<{ checked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  border-radius: 6px;
  position: relative;
  height: 20px;
  cursor: pointer;
  width: 20px;
  border: 2px solid #707070;

  ${({checked}) =>
          checked
                  ? css`
                    background-color: #015dab;
                  `
                  : css`
                    background-color: white;
                  `}
  &::after {
    z-index: 10;
    right: -100px;
    width: 100px !important;
    top: 10px;
    content: "";
    color: #676767;
    width: 65px;
    height: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 10px;
    pointer-events: none !important;
    font-size: 13px;
    opacity: 0;
    position: absolute;
    transition: all 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    &::after {
      content: "Select record";
      transition: all 0.3s;
      transition-delay: 1.3s;
      opacity: 1;
    }
  }
`;
export const SingleCheckboxWrapper = styled.div<{ checked: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.1s;
  border-radius: 100%;
  height: 24px;
  cursor: pointer;
  width: 24px;
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.surface80Divider};
  @media screen and (max-width: 1280px){
    height: 16px;
    width: 16px;
  }
  &:hover {
    box-shadow: 0px 3px 6px #00000029;
  }

  ${({checked}) =>
          checked
                  ? css`
                    background-color: ${({theme}) => theme.colors.primary50Main};
                    border: 1px solid ${({theme}) => theme.colors.primary50Main};
                  `
                  : css`
                    background-color: ${({theme}) => theme.colors.surface50Background};
                    border: 1px solid ${({theme}) => theme.colors.surface80Divider};
                  `}
  //&::after {
  //  right: -100px;
  //  width: 100px !important;
  //  top: 10px;
  //  content: "";
  //  color: #676767;
  //  width: 65px;
  //  height: 24px;
  //  background: #ffffff 0% 0% no-repeat padding-box;
  //  box-shadow: 0px 3px 15px #00000029;
  //  border-radius: 10px;
  //  pointer-events: none !important;
  //  font-size: 13px;
  //  opacity: 0;
  //  position: absolute;
  //  transition: all 0.3s;
  //  display: flex;
  //  flex-direction: row;
  //  align-items: center;
  //  justify-content: center;
  //}

  //&:hover {
  //  &::after {
  //    content: "Select record";
  //    transition: all 0.3s;
  //    transition-delay: 1.3s;
  //    opacity: 1;
  //  }
  //}
`;
export const CheckIconImg = styled.img<{ checked: boolean }>`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: all 0.5s;
  @media screen and (max-width: 1280px){
    scale: 0.6;
  }
  ${({checked}) =>
          checked
                  ? css`
                    opacity: 1;
                  `
                  : css`
                    opacity: 0;
                  `}
`;
