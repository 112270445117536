import styled, {css} from "styled-components";

export const Head = styled.div<{ drop: boolean }>`
  background-color: ${({theme}) => theme.colors.surface50Background};
  border-bottom: 1px solid ${({theme}) => theme.colors.surface80Divider};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${({drop}) => {
    if (drop === true) {
      return css`
        &:after {
          content: '';
          position: fixed;
          width: 100%;
          height: calc(100% - 97px);
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 3;
          background: rgba(14, 15, 17, 0.48);
          backdrop-filter: blur(1px);
        }
      `
    }
  }}
 
`


export const Menu = styled.div`
  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      color: ${({theme}) => theme.colors.text50};
      margin: 0 10px;
    }
  }
`
