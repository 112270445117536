import React from "react";

export interface IconProperty {
    fill: string | '#56676C';
}

const EyeSlashO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0579 10.0021L10.0019 15.058L9.99994 15.06L2.52994 22.53C2.37994 22.68 2.18994 22.75 1.99994 22.75C1.80994 22.75 1.61994 22.68 1.46994 22.53C1.17994 22.24 1.17994 21.76 1.46994 21.47L4.8432 18.0967C3.87998 17.231 3.0112 16.191 2.25994 15C1.19994 13.35 1.19994 10.66 2.25994 9C4.69994 5.18 8.24994 2.98 11.9999 2.98C14.0891 2.98 16.1513 3.66538 17.9809 4.95907L21.4699 1.47C21.7599 1.18 22.2399 1.18 22.5299 1.47C22.8199 1.76 22.8199 2.24 22.5299 2.53L15.0599 10L15.0579 10.0021ZM14.4846 8.45536C13.7597 7.94449 12.8981 7.67 11.9999 7.67C9.60994 7.67 7.66994 9.61 7.66994 12C7.66994 12.8982 7.94443 13.7597 8.4553 14.4846L5.90447 17.0355C5.02384 16.2443 4.22349 15.2875 3.51994 14.19C2.76994 13.02 2.76994 10.98 3.51994 9.81C5.67994 6.42 8.76994 4.48 11.9999 4.48C13.6946 4.48 15.3811 5.01384 16.9031 6.03686L14.4846 8.45536ZM11.9999 9.17C12.4999 9.17 12.9799 9.3 13.3999 9.54L9.53994 13.4C9.29994 12.98 9.16994 12.5 9.16994 12C9.16994 10.44 10.4399 9.17 11.9999 9.17Z" fill={props.fill}/>
            <path d="M11.9999 21.02C10.6699 21.02 9.36994 20.75 8.11994 20.22C7.73994 20.06 7.55994 19.62 7.71994 19.24C7.87994 18.86 8.31994 18.68 8.69994 18.84C9.75994 19.29 10.8699 19.52 11.9899 19.52C15.2199 19.52 18.3099 17.58 20.4699 14.19C21.2199 13.02 21.2199 10.98 20.4699 9.81C20.1599 9.32 19.8199 8.85 19.4599 8.41C19.1999 8.09 19.2499 7.62 19.5699 7.35C19.8899 7.09 20.3599 7.13 20.6299 7.46C21.0199 7.94 21.3999 8.46 21.7399 9C22.7999 10.65 22.7999 13.34 21.7399 15C19.2999 18.82 15.7499 21.02 11.9999 21.02Z" fill={props.fill}/>
            <path d="M11.9499 15.66C12.0199 16.02 12.3399 16.27 12.6899 16.27C12.707 16.27 12.7229 16.2688 12.7384 16.2677C12.7684 16.2655 12.797 16.2634 12.8299 16.27C14.5499 15.95 15.9299 14.58 16.2499 12.85C16.3299 12.44 16.0599 12.05 15.6499 11.97C15.2399 11.9 14.8499 12.16 14.7699 12.57C14.5699 13.67 13.6499 14.59 12.5499 14.79C12.1399 14.86 11.8699 15.25 11.9499 15.66Z" fill={props.fill}/>
        </svg>
    );
};

export default EyeSlashO;
