import { createContext, useContext, ReactNode, useState } from "react";
import AccountService from "../services/account.service";
import { useNavigate } from "react-router";
import BaseService from "../services/base.service";

type commenContextType = {
    profile: any;
    getProfile: () => void;
    gitsDays: any;
    getGitsDays: () => void;
    pairsResult: any;
    getPairs: () => void;
    editProfileHandler: (newProfile: any) => void;
    exchange: any;
    getExchanges: () => void;


};

const commonContextDefaultValues: commenContextType = {
    profile: {},
    getProfile: () => { },
    gitsDays: {},
    getGitsDays: () => { },
    pairsResult: {},
    getPairs: () => { },
    editProfileHandler: (newProfile: any) => { },
    exchange: {},
    getExchanges: () => { }



};

const commonContext = createContext<commenContextType>(commonContextDefaultValues);

export function useCommon() {
    return useContext(commonContext);
}

type Props = {
    children: ReactNode;
};



export function CommonProvider({ children }: Props) {
    const [profile, setProfile] = useState<any>(null);
    const [gitsDays, setGitsDays] = useState<any>(null);
    const [pairsResult, setPairsResult] = useState<any>([]);
    const [exchange, setExchange] = useState<any>([]);

    let navigate = useNavigate();
    const getProfile = async () => {
        let token = localStorage.getItem("token");

        if (token) {
            AccountService.profile(token)
                .then((response: any) => {
                    let profileData = response.data.data;
                    if (profileData.active_plan?.expire_at) {
                        let expireDate = new Date(
                            profileData?.active_plan?.expire_at
                        ).getTime();
                        let now = new Date().getTime();
                        profileData.daysToExpire = Math.round(
                            (expireDate - now) / (60 * 60 * 24 * 1000)
                        );
                    }
                    if (profileData?.created_at) {
                        let createdAt = new Date(profileData.created_at).getTime();
                        let now = new Date().getTime();
                        profileData.createdDay = Math.round(
                            (createdAt - now) / (60 * 60 * 24 * 1000)
                        );

                        if (profileData.createdDay < 0) {
                            profileData.createdDay = 0;
                        }
                    }
                    setProfile(response.data.data);

                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        navigate("/");
                        localStorage.removeItem("token");
                    }
                    if (error.response.status === 403) {
                        if (error?.response?.data?.message ==="Your email address is not verified.") {
                            navigate("/settings/profile");
                        } else {
                            navigate("/plans");
                        }
                    }
                    if (error.response.status === 404) {
                        localStorage.removeItem("token");
                        navigate("*");
                    }
                });
        }
    }
    const getPairs = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            BaseService.getPairs(token)
                .then((response) => {
                    setPairsResult(response.data);
                })
                .catch((error) => {
                    console.log('error', error)
                    if (error.response.status === 401) {
                        navigate("/");
                        localStorage.removeItem("token");
                    }
                    if (error.response.status === 403) {
                        if (
                            error?.response?.data?.message ===
                            "Your email address is not verified."
                        ) {
                            navigate("/settings/profile");
                        } else {
                            navigate("/plans");
                        }
                    }
                    if (error.response.status === 404) {
                        localStorage.removeItem("token");
                        navigate("*");
                    }
                });

        }
    }
    const getGitsDays = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            AccountService.giftsDays(token)
                .then((response: any) => {
                    setGitsDays(response?.data?.days);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
    const getExchanges = async () => {
        let token = localStorage.getItem("token");
        if (token) {
            BaseService.getExchanges(token)
                .then((response) => {
                    setExchange(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const editProfileHandler = (newProfile: any) => {
        setProfile(newProfile)
    }

    const value = {
        profile,
        editProfileHandler,
        getProfile,
        gitsDays,
        getGitsDays,
        pairsResult,
        getPairs,
        exchange,
        getExchanges
    };
    return (
        <>
            <commonContext.Provider value={value}>
                {children}
            </commonContext.Provider>
        </>
    );
}