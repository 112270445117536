import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}


const NotificationB = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3399 12.83L19.3399 14.49C19.7299 15.13 19.7999 15.89 19.5399 16.58C19.2799 17.27 18.7399 17.8 18.0399 18.03C16.0999 18.68 14.0599 19 12.0199 19C9.97994 19 7.93994 18.68 5.99994 18.02C5.25994 17.77 4.68994 17.25 4.44994 16.58C4.19994 15.9 4.28994 15.16 4.68994 14.49L5.69994 12.82C5.90994 12.46 6.09994 11.76 6.09994 11.35V8.82C6.09994 6.5 7.44994 4.49 9.39994 3.52C9.91994 2.59 10.8999 2 11.9899 2C13.0899 2 14.0499 2.57 14.5699 3.49C16.5599 4.44 17.9399 6.47 17.9399 8.82V11.35C17.9399 11.76 18.1299 12.46 18.3399 12.83Z" fill={props.fill}/>
            <path d="M11.9999 22C13.2999 22 14.4099 21.17 14.8299 20.01C14.6599 20.03 14.4999 20.05 14.3399 20.07C14.2049 20.0903 14.0653 20.1014 13.9242 20.1127C13.8563 20.1181 13.7881 20.1235 13.7199 20.13C13.1599 20.18 12.5899 20.21 12.0199 20.21C11.4399 20.21 10.8599 20.18 10.2899 20.13C10.0499 20.11 9.80994 20.08 9.57994 20.05C9.51413 20.0406 9.45054 20.0334 9.38811 20.0263C9.31773 20.0184 9.24883 20.0106 9.17994 20C9.31994 20.41 9.55994 20.81 9.87994 21.11C10.4299 21.68 11.2099 22 11.9999 22Z" fill={props.fill}/>
        </svg>
    );
};

export default NotificationB;
