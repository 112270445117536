import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const CloseO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5303 7.53027C17.8232 7.23738 17.8232 6.7625 17.5303 6.46961C17.2374 6.17672 16.7626 6.17672 16.4697 6.46961L12 10.9393L7.53034 6.46961C7.23745 6.17672 6.76257 6.17672 6.46968 6.46961C6.17679 6.7625 6.17679 7.23738 6.46968 7.53027L10.9393 11.9999L6.46967 16.4696C6.17678 16.7625 6.17678 17.2374 6.46967 17.5303C6.76256 17.8232 7.23744 17.8232 7.53033 17.5303L12 13.0606L16.4697 17.5303C16.7626 17.8232 17.2375 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7625 17.5303 16.4696L13.0607 11.9999L17.5303 7.53027Z" fill={props.fill}/>
        </svg>
    );
};

export default CloseO;
