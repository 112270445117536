import type { ThemeContext, Theme, ThemeProviderProps } from "../types/theme";
import { createContext, useContext, useState } from "react";
import { LightTheme, DarkTheme } from "../theme/Themes";
import { ThemeProvider } from "styled-components";
import { Mode } from "../types/theme";

let current = localStorage.getItem('theme')
const initialValue = {
    changeMode: () => {},
    mode: (current?current.toString():Mode.Dark) || 'dark'
};

// @ts-ignore
const AppThemeContext = createContext<ThemeContext>(initialValue);

export const AppThemeProvider = ({ children }: ThemeProviderProps) => {
    const [theme, setTheme] = useState<Theme>((!current ||current===Mode.Dark)?DarkTheme:LightTheme);
    const [mode, setMode] = useState<any>(current || Mode.Dark);

    const changeMode = (mode: Mode) => {
        setTheme(mode === Mode.Light ? LightTheme : DarkTheme);
        setMode(mode);
    };

    return (
        <AppThemeContext.Provider value={{ mode, changeMode }}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </AppThemeContext.Provider>
    );
};

export const useThemeContext = () => useContext(AppThemeContext);

export default AppThemeProvider;