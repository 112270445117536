import PageTitles from "../../components/ds/PageTitle";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import Button from "../../components/ds/Button";
import WithdrawO from "../../assets/icons/Withdraw-o";
import { NavLink } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import AccountService from "../../services/account.service";

import useAuth from "../../useAuth";
import toast from "react-hot-toast";

export default function Withdraw() {
    const text50 = useTheme().colors.text50;
    const text30 = useTheme().colors.text30;
    const text20 = useTheme().colors.text20;
    const primary50Main = useTheme().colors.primary50Main;
    const surface80Divider = useTheme().colors.surface80Divider;
    const { token } = useAuth();
    const error50Main = useTheme().colors.error50Main;

    return (
        <>
            <NavLink to={'/wallet'}>
                <PageTitles text={'Withdraw'} arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />} />
            </NavLink>
            <div className={'lg:w-2/5'}>
                <div className={`text-xs xl:text-base font-normal text-[${useTheme().colors.text80Body}]`}>
                    If you want move your finances to your wallets, submit a whitdraw request here. Requests are confirm by Finomate and then processed.
                </div>


                <div
                    className={`w-full p-4 flex items-center rounded-2xl gap-4 my-6 bg-[${useTheme().colors.warning40}]`}>
                    <div>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                d="M29.0133 21.228L20.48 5.86797C19.3333 3.8013 17.7466 2.66797 16 2.66797C14.2533 2.66797 12.6666 3.8013 11.52 5.86797L2.98664 21.228C1.90664 23.188 1.78664 25.068 2.6533 26.548C3.51997 28.028 5.22664 28.8413 7.46664 28.8413H24.5333C26.7733 28.8413 28.48 28.028 29.3466 26.548C30.2133 25.068 30.0933 23.1746 29.0133 21.228Z"
                                fill="#ECBF1D" />
                            <path
                                d="M16.0001 19.6667C15.4534 19.6667 15.0001 19.2133 15.0001 18.6667V12C15.0001 11.4533 15.4534 11 16.0001 11C16.5467 11 17.0001 11.4533 17.0001 12V18.6667C17.0001 19.2133 16.5467 19.6667 16.0001 19.6667Z"
                                fill="#ECBF1D" />
                            <path
                                d="M16.0001 24C15.9201 24 15.8267 23.9867 15.7334 23.9733C15.6534 23.96 15.5734 23.9333 15.4934 23.8933C15.4134 23.8667 15.3334 23.8267 15.2534 23.7733C15.1867 23.72 15.1201 23.6667 15.0534 23.6133C14.8134 23.36 14.6667 23.0133 14.6667 22.6667C14.6667 22.32 14.8134 21.9733 15.0534 21.72C15.1201 21.6667 15.1867 21.6133 15.2534 21.56C15.3334 21.5067 15.4134 21.4667 15.4934 21.44C15.5734 21.4 15.6534 21.3733 15.7334 21.36C15.9067 21.32 16.0934 21.32 16.2534 21.36C16.3467 21.3733 16.4267 21.4 16.5067 21.44C16.5867 21.4667 16.6667 21.5067 16.7467 21.56C16.8134 21.6133 16.8801 21.6667 16.9467 21.72C17.1867 21.9733 17.3334 22.32 17.3334 22.6667C17.3334 23.0133 17.1867 23.36 16.9467 23.6133C16.8801 23.6667 16.8134 23.72 16.7467 23.7733C16.6667 23.8267 16.5867 23.8667 16.5067 23.8933C16.4267 23.9333 16.3467 23.96 16.2534 23.9733C16.1734 23.9867 16.0801 24 16.0001 24Z"
                                fill="#ECBF1D" />
                        </svg>
                    </div>


                    <div className={`text-xs xl:text-base font-normal text-[${useTheme().colors.text80Body}]`}>
                        To withdraw Tether on the Tron network, just enter your TRC20 address in the destination wallet
                        address box.
                    </div>
                </div>


                <Formik
                    initialValues={{ amount: '', wallet_address: '' }}
                    validate={values => {
                        const errors: any = {};
                        if (!values.amount) {
                            errors.amount = 'Please enter amount value'
                        }
                        if (!values.wallet_address) {
                            errors.wallet_address = 'Please enter wallet address'
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        AccountService.walletWithdraw(values, token).then(({ data }) => {
                            toast.success(data.message)
                        }).catch((err) => {
                            toast.error(err.response.data.message)
                        })
                        setSubmitting(false);
                    }}
                >
                    {({
                        values,
                        isValid,
                        setFieldValue,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <TextField size={'Big'} placeholder={'Amount'} name={'amount'} value={values.amount}
                                    onChange={handleChange} />
                                <ErrorMessage name="amount" component="div" className={`text-[${error50Main}]`} />

                                <div className="flex flex-row gap-3	 mt-2 mb-4 xl:mt-4 xl:mb-6">
                                    <div className="basis-1/3 xl:basis-1/3">
                                        <div onClick={() => setFieldValue('amount', '50')}
                                            className={`hover:cursor-pointer border border-solid px-4 rounded-3xl h-12 text-xs xl:text-base font-medium flex items-center justify-center ${values.amount === '50' ? `border-[${primary50Main}]` : `border-[${surface80Divider}]`} ${values.amount === '50' ? ` text-[${primary50Main}]` : ` text-[${text50}]`}`}>
                                            Withdraw 50
                                        </div>
                                    </div>
                                    <div className="basis-1/3 xl:basis-1/3">
                                        <div onClick={() => setFieldValue('amount', '100')}
                                            className={`hover:cursor-pointer border border-solid px-4 rounded-3xl h-12 text-xs xl:text-base font-medium flex items-center justify-center ${values.amount === '100' ? `border-[${primary50Main}]` : `border-[${surface80Divider}]`} ${values.amount === '100' ? ` text-[${primary50Main}]` : ` text-[${text50}]`}`}>
                                            Withdraw 100
                                        </div>
                                    </div>
                                    <div className="basis-1/3 xl:basis-1/3">
                                        <div onClick={() => setFieldValue('amount', '250')}
                                            className={`hover:cursor-pointer border border-solid px-4 rounded-3xl h-12 text-xs xl:text-base font-medium flex items-center justify-center ${values.amount === '250' ? `border-[${primary50Main}]` : `border-[${surface80Divider}]`} ${values.amount === '250' ? ` text-[${primary50Main}]` : ` text-[${text50}]`}`}>
                                            Withdraw 250
                                        </div>
                                    </div>
                                </div>

                                <TextField size={'Big'} placeholder={'Destination Wallet Address '}
                                    name={'wallet_address'} value={values.wallet_address} onChange={handleChange}
                                    hint={'BE CAREFUL! Entering wrong address may result in losing your finances. '} />
                                <ErrorMessage name="wallet_address" component="div" className={`text-[${error50Main}]`} />

                                <div className={'flex gap-2 my-6'}>
                                    <div className={`text-xs xl:text-base font-semibold text-[${text50}]`}>Withdraw
                                        fee
                                    </div>
                                    <div className={`text-base xl:text-xl font-semibold text-[${text30}]`}>0.8
                                        USDT
                                    </div>
                                </div>


                                <Button text={'Confirm withdraw request'}
                                    className={'flex w-full text-xs xl:text-base h-12 items-center justify-center '}
                                    status={!isValid} buttonStyleType={'BPrimary'}
                                    icon={<WithdrawO fill={text20} />} />
                            </div>

                        </form>

                    )}
                </Formik>
            </div>
        </>
    )
}
