import { useTheme } from "styled-components";
import MoreO from "../../assets/icons/More-o";
import { NavLink } from "react-router-dom";

export default function ListCart(props: any) {
  const surface60 = useTheme().colors.surface60;
  const surface70 = useTheme().colors.surface70;
  const text80Body = useTheme().colors.text80Body;
  const text90 = useTheme().colors.text90;
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const { item } = props;
  const { index } = props;

  function SenderType(data: any) {
    if (data === 0) {
      return "FinoBot";
    } else if (data === 1) {
      return "User";
    } else if (data === 2) {
      return "Support";
    }
  }
  return (
    <>
      <div className={`table-row  mb-2 bg-[${surface60}] hover:bg-[${surface70}]`}>
        <div className={`table-cell rounded-tl-2xl rounded-bl-2xl lg:rounded-tl-3xl lg:rounded-bl-3xl align-middle p-3  lg:py-5 lg:pl-6 text-left font-semibold text-base xl:text-xl text-[${text50}]`} >
          {index + 1}
        </div>
        <div className={`table-cell align-middle py-3 lg:py-5 text-left font-semibold text-base lg:text-xl text-[${text90}]`}>
          {item.subject}
        </div>
        <div className={`hidden lg:table-cell align-middle py-3 lg:py-5 text-left font-semibold text-base lg:text-xl text-[${text90}]`}>
          {item.department.name}
        </div>
        <div className={`table-cell align-middle py-3 lg:py-5 text-left font-semibold text-base lg:text-xl text-[${text90}]`}>
          {item.status_text}
        </div>
        <div className={`hidden lg:table-cell align-middle py-3 lg:py-5 text-left font-semibold text-base lg:text-xl text-[${text90}]`}>
          {SenderType(item.message.type)}
        </div>
        <div className={`relative table-cell rounded-tr-2xl rounded-br-2xl lg:rounded-tr-3xl lg:rounded-br-3xl align-middle  py-5 mr-6  text-left text-[${text80Body}]`}>
          <NavLink to={`/tickets/details/${item.id}`}>
            <MoreO fill={text60} />
          </NavLink>
        </div>
      </div>
      <div className={"h-4"}></div>
    </>
  );
}
