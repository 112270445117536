import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import PageTitles from "../../components/ds/PageTitle";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import Button from "../../components/ds/Button";
import TelegramO from "../../assets/icons/Telegram-o";
import React, { useEffect, useRef, useState } from "react";
import ArrowDownOutline from "../../assets/icons/Arrow-down-outline";
import { SingleCheckbox } from "../../components/ds/Checkboxes";
import AlarmService from "../../services/alarm.service";
import BaseService from "../../services/base.service";
import { ErrorMessage, Formik } from "formik";
import Tag from "../../components/ds/Tag";
import NotificationO from "../../assets/icons/Notification-o";
import { useOutsideClick } from "../../components/ds/Hooks/useOutsideClick";
import AccountService from "../../services/account.service";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import noPhoto from "../../assets/images/no-photo.png";
import Loading from "../../assets/icons/Loading";
import useAuth from "../../useAuth";
import { useCommon } from "../../context/commonContext";
import toast from "react-hot-toast";

export default function AddAlarm() {
  const navigate = useNavigate();
  const [resultStrategies, setResultStrategies] = useState<any>([]);
  const [resultTimeFrame, setResultTimeFrame] = useState<any>([]);
  const [exchangeId, setexchangeId] = useState<any>();
  const [result, setResult] = useState([]);
  const [resultFilter, setResultFilter] = useState([]);
  const [selected, setSelected] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [notification, setNotification] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [buy, setBuy] = useState(false);
  const [sell, setSell] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [userID, setUserID] = useState<any>("");
  const [value, setValue] = useState<any>({ title: "", description: "" });
  const primary50Main = useTheme().colors.primary50Main;
  const error50Main = useTheme().colors.error50Main;
  const primary20 = useTheme().colors.primary20;
  const surface80Divider = useTheme().colors.surface80Divider;
  const text40 = useTheme().colors.text40;
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const text90 = useTheme().colors.text90;
  const text30 = useTheme().colors.text30;
  const text80Body = useTheme().colors.text80Body;
  const surface50Background = useTheme().colors.surface50Background;
  const [searchParams] = useSearchParams();
  const { token } = useAuth();
  const wrapperRef0 = useRef(null);
  const wrapperRef = useRef(null);
  const wrapperRef2 = useRef(null);


  const { pairsResult, profile, exchange } = useCommon();


  useOutsideClick(wrapperRef0, () => {
    setDropdown(false);
  });
  useOutsideClick(wrapperRef, () => {
    setDropdown3(false);
  });
  useOutsideClick(wrapperRef2, () => {
    setDropdown2(false);
  });

  useEffect(() => {
    if (searchParams.get("id")) {
      setUserID(searchParams.get("id"));
      setEdit(true);
      AlarmService.getOne(searchParams.get("id"), token)
        .then((response) => {

          if (response && response.data) {
            const { entity } = response?.data;
            setexchangeId(+entity.exchange_id)
            setValue({ title: entity?.name, description: entity?.description });
            setSelected(entity?.pairs);
            if (entity.opportunity === "both") {
              setBuy(true);
              setSell(true);
            } else if (entity.opportunity === "buy") setBuy(true);
            else if (entity.opportunity === "sell") setSell(true);
            if (entity.telegram === 1) {
              setTelegram(true);
            }
            if (entity.notify === 1) {
              setNotification(true);
            }
            BaseService.getStrategies(token)
              .then((response) => {
                let data = response.data;
                for (let sub of entity.strategy) {
                  for (let item of data) {
                    if (item.id === parseInt(sub)) item.checked = true;
                  }
                }
                setResultStrategies(data);
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              });
            BaseService.getTimeFrame(token)
              .then((response) => {
                let data = [];
                for (let item of response.data) {
                  data.push({ title: item, checked: false });
                }
                for (let sub of entity.time_frame) {
                  for (let item of data) {
                    if (item.title === sub) item.checked = true;
                  }
                }
                setResultTimeFrame(data);
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
    } else {
      BaseService.getStrategies(token)
        .then((response: any) => {
          setResultStrategies(response.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });

      BaseService.getTimeFrame(token)
        .then((response: any) => {
          let data = [];
          for (let item of response.data) {
            data.push({ title: item, checked: false });
          }
          setResultTimeFrame(data);
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
    }
  }, [error50Main, navigate, searchParams]);


  function getParisHandler(id: any) {
    if (id) {
      BaseService.getPairsExchange(id, token)
        .then((response: any) => {
          setResult(response.data);
          setResultFilter(response.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
        
    } else {
      setResultFilter(pairsResult);
    }

  }

  function selectedPairs(item: any) {
    if (selected.findIndex((s: any) => s.id === item.id) === -1) {
      setSelected([...selected, item]);
    }
  }

  function checkTelegram() {
    AccountService.profile(token)
      .then((response: any) => {
        if (response.data.data.telegram_id) {
          setIsOpen(false);
          setTelegram(true);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }





  const customStyles = {
    content: {
      height: "fit-content",
      display: "block",
      margin: "auto",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      borderRadius: "24px",
      color: useTheme().colors.text80Body,
      width: "fit-content",
      maxWidth: "500px",
    },
  };
  function joinBot() {
    window.open("https://t.me/FinoMateBot?start=" + profile?.id, "_blank");
  }


  return (
    <section>
      <NavLink to={"/alarms"}>
        <PageTitles
          text={edit ? "Edit Alarm" : "New Alarm"}
          arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />}
        />
      </NavLink>

      <Formik key={'add-alarm'}
        enableReinitialize
        initialValues={value}
        validate={(values) => {
          const errors: any = {};
          if (!values.title) {
            errors.title = "Required";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          setTimeout(() => {
            let params = new URLSearchParams();
            params.append("name", values.title);
            params.append(
              "opportunity",
              buy && sell ? "both" : buy ? "buy" : "sell"
            );
            params.append("sms", "0");
            params.append("telegram", telegram ? "1" : "0");
            params.append("notify", notification ? "1" : "0");
            params.append("_params", "patch");

            selected.forEach((item: any, index: number) => {
              params.append(`pairs[${index}]`, item.id);
            });
            params.append('exchange_id', exchangeId)

            let strategyIndex = 0;
            let timeFrameIndex = 0;
            for (let item of resultStrategies) {
              if (item.checked) {
                params.append(`strategy[${strategyIndex}]`, item.id);
                strategyIndex++;
              }
            }
            for (let item of resultTimeFrame) {
              if (item.checked) {
                params.append(`time_frame[]`, item.title);
                timeFrameIndex++;
              }
            }
            if (edit) {
              AlarmService.edit(userID, params, token)
                .then(({ data }) => {
                  if (data) {
                    setLoading(true);
                    toast.success("Alarm edited")
                    navigate("/alarms");
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  setSubmitting(false);
                  toast.error(err.response.data.message)
                });
            } else {
              AlarmService.create(params, token)
                .then(({ data }) => {
                  if (data) {
                    setLoading(false);
                    toast.success("Alarm added")
                    navigate("/alarms");
                  } else {
                  }
                })
                .catch((err) => {
                  setLoading(false);
                  setSubmitting(false);
                  toast.error(err.response.data.message)
                });
            }
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={"w-full lg:w-4/5 2xl:w-3/5 mt-4"}>
              <div className={"mb-6"}>
                <TextField
                  size={"Big"}
                  label={"Title"}
                  name={"title"}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={"Alarm Title "}
                  required={true}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className={`text-[${error50Main}]`}
                />
              </div>

              <div className={"mb-6"}>
                <div
                  className={`text-base font-semibold mb-2 text-[${text50}]`}
                >
                  Exchanges
                </div>
                <div className={"w-full flex items-center gap-2 flex-wrap"}>
                  {exchange.map((item: any, index: number) => {
                    return (
                      <div
                        className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${item.id !== exchangeId ? `text-[${text30}]` : `text-[${primary50Main}]`
                          } ${item.id !== exchangeId ? `bg-transparent` : `bg-[${primary20}]`} ${item.id !== exchangeId
                            ? `border-[${surface80Divider}]`
                            : `border-[${primary50Main}]`
                          }`}
                        onClick={() => {
                          setexchangeId(item.id)
                          getParisHandler(item.id)
                        }}
                      >
                        <img className={"h-full w-full w-8 h-8 rounded-full mr-2"} src={item.full_logo} />
                        {item.name}
                        {item.details ? `(${item.details})` : null}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={`mb-6 ${exchangeId ? "" : "pointer-events-none"}`} ref={wrapperRef0}>
                <TextField
                  size={"Big"}
                  label={"Pairs"}
                  placeholder={"Type pairs here"}
                  required={true}
                  suffixIcon={<ArrowDownOutline fill={text50} />}
                  suffixOnClick={() => setDropdown(!dropdown)}
                  type={"autocomplete"}
                  onChange={(event: any) => {
                    if (event.target.value) {
                      setDropdown(true);
                      setResultFilter(
                        result.filter(
                          (item: any) =>
                            item.symbol?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 ||
                            item.description?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
                        )
                      );
                    } else {
                      setResultFilter(result);
                    }
                  }}
                  onBlur={handleBlur}
                />

                {dropdown && (
                  <div
                    className={`py-2 overflow-auto  h-60  mt-2 rounded-3xl border-[0.5px] border-solid border-[${surface80Divider}] bg-[${surface50Background}] shadow-[16px_24px_48px_rgba(27,30,34,0.08)]`}
                  >
                    {resultFilter.length == 0 ? <div className={`hover:cursor-pointer flex items-center justify-between py-2 px-6 hover:bg-[${surface50Background}]`}>
                      <div className={"flex items-center"}>
                        <div className={text50}>Not Found Pair</div>
                      </div>

                    </div> : null}
                    {resultFilter.map((item: any) => {
                      return (
                        <div
                          className={`hover:cursor-pointer flex items-center justify-between py-2 px-6 hover:bg-[${surface50Background}]`}
                          key={item.id}
                          onClick={(e) => selectedPairs(item)}
                        >
                          <div className={"flex items-center"}>
                            <div className={"w-8 h-8 rounded-full  mr-2"}>
                              <img
                                className={"h-full w-full rounded-full"}
                                alt={item.symbol}
                                src={item.logo ? item.logo : noPhoto}
                              />
                            </div>
                            <div>
                              <div
                                className={`font-normal text-base text-[${text80Body}]`}
                              >
                                {item.symbol}
                              </div>
                              <div
                                className={`font-medium text-sm text-[${text50}]`}
                              >
                                {item.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className={"flex flex-wrap mt-2"}>
                  {selected?.map((item: any) => {
                    return (
                      <Tag
                        icon={item.logo}
                        text={item.symbol}
                        onClick={() =>
                          setSelected([
                            ...selected.filter((s: any) => s.id !== item.id),
                          ])
                        }
                      />
                    );
                  })}
                </div>
              </div>


              <div className={"mb-6"}>
                <div className={`text-sm font-medium mb-2 text-[${text50}]`}>
                  Strategies
                  <span className={`text-[${error50Main}]`}> *</span>
                </div>

                <div className={"flex flex-wrap xl:flex-nowrap   gap-3"}>
                  <div className={"w-full xl:w-1/4 flex items-center gap-2"}>
                    <div
                      onClick={() => setBuy(!buy)}
                      className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!buy ? `text-[${text30}]` : `text-[${primary50Main}]`
                        } ${!buy ? `bg-transparent` : `bg-[${primary20}]`} ${!buy
                          ? `border-[${surface80Divider}]`
                          : `border-[${primary50Main}]`
                        }`}
                    >
                      Buy
                    </div>

                    <div
                      onClick={() => setSell(!sell)}
                      className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!sell ? `text-[${text30}]` : `text-[${primary50Main}]`
                        } ${!sell ? `bg-transparent` : `bg-[${primary20}]`} ${!sell
                          ? `border-[${surface80Divider}]`
                          : `border-[${primary50Main}]`
                        }`}
                    >
                      Sell
                    </div>
                  </div>

                  <div className={"w-full xl:w-2/5"} >
                    <div className={"relative"} ref={wrapperRef}>
                      <div
                        className={`flex   items-center justify-between border  border-solid rounded-[24px] h-12 px-5 border-[${surface80Divider}]`}
                        onClick={() => setDropdown3(!dropdown3)}
                      >
                        <span
                          className={`text-base font-normal text-[${text30}]`}
                        >
                          {resultStrategies.filter(
                            (i: any) => i.checked === true
                          ).length > 0
                            ? `${resultStrategies.filter(
                              (i: any) => i.checked === true
                            ).length
                            } items selected`
                            : "Select Strategies"}
                        </span>

                        <div
                          className={dropdown3 ? "rotate-180" : ""}
                          onClick={(e) => setDropdown3(!dropdown3)}
                        >
                          <ArrowDownOutline fill={text50} />
                        </div>
                      </div>

                      {dropdown3 && (
                        <div
                          className={`border border-solid rounded-[24px] h-80 overflow-auto z-20 px-2 absolute top-14 w-96 border-[${surface80Divider}] bg-[${surface50Background}]`}
                        >
                          <div
                            className={"flex items-center justify-between"}
                          >
                            <div
                              className={`text-sm font-medium relative text-[${text50}]`}
                            >
                              Trend Following
                            </div>
                            <div
                              className={`hover:cursor-pointer text-xs xl:text-base font-semibold my-4 text-[${primary50Main}]`}
                              onClick={() => {
                                if (
                                  resultStrategies.filter(
                                    (item: any) => item.checked === true
                                  ).length === resultStrategies.length
                                )
                                  resultStrategies.map(
                                    (item: any) => (item.checked = false)
                                  );
                                else
                                  resultStrategies.map(
                                    (item: any) => (item.checked = true)
                                  );
                                setResultStrategies([...resultStrategies]);
                              }}
                            >
                              {resultStrategies.filter(
                                (item: any) => item.checked === true
                              ).length === resultStrategies.length
                                ? "Deselect all"
                                : "Select all"}
                            </div>
                          </div>
                          {resultStrategies.map(
                            (item: any, index: number) => {
                              return (
                                <div
                                  className={
                                    "flex items-center justify-between mb-4 last:mb-0"
                                  }
                                >
                                  <div className={"flex items-center"}>
                                    <div
                                      className={
                                        "w-8 h-8 rounded-full mr-2 bg-cyan-100"
                                      }
                                    ></div>
                                    <div
                                      className={`text-base font-normal text-[${text90}]`}
                                    >
                                      {item?.name}
                                    </div>
                                  </div>

                                  <div
                                    className={`font-normal text-xs text-[${text40}]`}
                                  >
                                    <SingleCheckbox
                                      onClick={() => {
                                        resultStrategies[index].checked =
                                          !resultStrategies[index].checked;
                                        setResultStrategies([
                                          ...resultStrategies,
                                        ]);
                                      }}
                                      checked={item.checked}
                                      value={1}
                                      key={1}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"w-full xl:w-2/5"} >
                    <div className={"relative"} ref={wrapperRef2}>
                      <div
                        className={`flex   items-center justify-between border  border-solid rounded-[24px] h-12 px-5 border-[${surface80Divider}]`}
                        onClick={() => setDropdown2(!dropdown2)}
                      >
                        <span
                          className={`text-base font-normal text-[${text30}]`}
                        >
                          {resultTimeFrame.filter(
                            (i: any) => i.checked === true
                          ).length > 0
                            ? `${resultTimeFrame.filter(
                              (i: any) => i.checked === true
                            ).length
                            } items selected`
                            : "Select timeframes"}
                        </span>

                        <div
                          className={dropdown2 ? "rotate-180" : ""}
                          onClick={(e) => setDropdown2(!dropdown2)}
                        >
                          <ArrowDownOutline fill={text50} />
                        </div>
                      </div>

                      {dropdown2 && (
                        <div
                          className={`border border-solid rounded-[24px] h-80 overflow-auto px-2 z-20 absolute top-14 w-96 border-[${surface80Divider}] bg-[${surface50Background}]`}
                        >
                          <div
                            className={"flex items-center justify-between"}
                          >
                            <div
                              className={`text-sm font-medium relative text-[${text50}]`}
                            >
                              Trend Following
                            </div>
                            <div
                              className={`hover:cursor-pointer text-xs xl:text-base font-semibold my-4 text-[${primary50Main}]`}
                              onClick={() => {
                                if (
                                  resultTimeFrame.filter(
                                    (item: any) => item.checked === true
                                  ).length === resultTimeFrame.length
                                )
                                  resultTimeFrame.map(
                                    (item: any) => (item.checked = false)
                                  );
                                else
                                  resultTimeFrame.map(
                                    (item: any) => (item.checked = true)
                                  );
                                setResultTimeFrame([...resultTimeFrame]);
                              }}
                            >
                              {resultTimeFrame.filter(
                                (item: any) => item.checked === true
                              ).length === resultTimeFrame.length
                                ? "Deselect all"
                                : "Select all"}
                            </div>
                          </div>
                          {resultTimeFrame.map(
                            (item: any, index: number) => {
                              return (
                                <div
                                  className={
                                    "flex items-center justify-between mb-4 last:mb-0"
                                  }
                                >
                                  <div className={"flex items-center"}>
                                    <div
                                      className={
                                        "w-8 h-8 rounded-full mr-2 bg-cyan-100"
                                      }
                                    ></div>
                                    <div
                                      className={`text-base font-normal text-[${text90}]`}
                                    >
                                      {item.title}
                                    </div>
                                  </div>

                                  <div
                                    className={`font-normal text-xs text-[${text40}]`}
                                  >
                                    <SingleCheckbox
                                      onClick={() => {
                                        resultTimeFrame[index].checked =
                                          !resultTimeFrame[index].checked;
                                        setResultTimeFrame([
                                          ...resultTimeFrame,
                                        ]);
                                      }}
                                      checked={item.checked}
                                      value={1}
                                      key={1}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <>
                <div className={`text-sm font-medium mb-2 text-[${text50}]`}>
                  Alarm type
                </div>

                <div className={"flex gap-2"}>

                  {profile?.telegram_id ?
                    <div
                      onClick={() => setTelegram(!telegram)}
                      className={`hover:cursor-pointer flex items-center h-12 px-5 rounded-3xl border border-solid text-base font-normal gap-1.5 ${telegram
                        ? `border-[${primary50Main}]`
                        : `border-[${surface80Divider}]`
                        } ${telegram
                          ? ` text-[${primary50Main}]`
                          : ` text-[${text30}]`
                        }`}
                    >
                      <TelegramO fill={telegram ? primary50Main : text30} />
                      Telegram
                    </div>
                    :
                    <div
                      onClick={() => setIsOpen(true)}
                      className={`hover:cursor-pointer flex items-center h-12 px-5 rounded-3xl border border-solid text-base font-normal gap-1.5 ${telegram
                        ? `border-[${primary50Main}]`
                        : `border-[${surface80Divider}]`
                        } ${telegram
                          ? ` text-[${primary50Main}]`
                          : ` text-[${text30}]`
                        }`}
                    >
                      <TelegramO fill={telegram ? primary50Main : text30} />
                      Telegram
                    </div>
                  }
                  <div
                    onClick={() => setNotification(!notification)}
                    className={`hover:cursor-pointer flex items-center h-12 px-5 rounded-3xl border border-solid text-base font-normal gap-1.5 ${notification
                      ? `border-[${primary50Main}]`
                      : `border-[${surface80Divider}]`
                      } ${notification
                        ? ` text-[${primary50Main}]`
                        : ` text-[${text30}]`
                      }`}
                  >
                    <NotificationO
                      fill={notification ? primary50Main : text30}
                    />
                    Notification
                  </div>
                </div>
              </>

              <div
                className={
                  "flex xl:w-2/4 items-center xl:justify-between justify-end  mr-0 pl-auto py-8 gap-3 "
                }
              >
                <NavLink to={"/alarms"} className={"xl:w-2/4"}>
                  <Button
                    text={"Cancel"}
                    className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 xl:w-full text-[${text50}]`}
                  />
                </NavLink>
                <Button
                  text={edit ? "Save" : "Add Alarm"}
                  status={!isValid || loading}
                  loading={loading ? <Loading /> : ""}
                  buttonStyleType={"BPrimary"}
                  className={"xl:w-2/4 h-12"}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>


      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div className={`font-semibold text-2xl text-[${text90}]`}>
            Telegram Account
          </div>

          <div onClick={() => setIsOpen(false)}>
            <CloseO fill={text60} />
          </div>
        </div>

        <div className={`text-base font-normal text-[${text80Body}]`}>
          To use Telegram notification, we need to confirm your account.
          Follow these steps to confirm your account:
        </div>

        <div className={`text-base font-normal text-[${text80Body}]`}>
          1-Tap on following link that will take you to Finomate Telegram
          Bot
        </div>
        <div className={`text-base font-normal text-[${text80Body}]`}>
          2- After opening Telegram chat, tap on “Start” Button.
        </div>
        <div className={`text-base font-normal text-[${text80Body}]`}>
          3- Now click on below button “Check”
        </div>
        <div className={`text-base font-normal text-[${text80Body}]`}>
          4- Wait for about 5 minutes for the Bot to confirm your account
        </div>

        <Button
          text={"Telegram Bot"}
          icon={<TelegramO fill={text50} />}
          buttonStyleType={"MSecondary"}
          className={
            "xl:w-2/4 h-12 flex items-center justify-center mx-auto my-6"
          }
          onClick={() => joinBot()}
        />
        <Button
          text={"Check"}
          buttonStyleType={"BPrimary"}
          onClick={() => checkTelegram()}
          className={"h-12 w-full"}
        />
      </Modal>

    </section>
  );
}
