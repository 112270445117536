import React from "react";

const SunO = (props: any) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 22C11 22.55 11.45 22.96 12 22.96C12.55 22.96 13 22.47 13 21.92C13 21.37 12.55 20.92 12 20.92C11.45 20.92 11 21.37 11 21.92V22Z" fill="#56676C"/>
            <path d="M18.43 19.85C18.63 20.04 18.88 20.14 19.14 20.14C19.4 20.14 19.65 20.04 19.84 19.85C20.23 19.46 20.23 18.83 19.84 18.44L19.71 18.31C19.32 17.92 18.69 17.92 18.3 18.31C17.91 18.7 17.91 19.33 18.3 19.72L18.43 19.85Z" fill="#56676C"/>
            <path d="M4.15 19.85C4.35 20.04 4.6 20.14 4.86 20.14C5.11 20.14 5.37 20.04 5.56 19.85L5.69 19.72C6.08 19.33 6.08 18.7 5.69 18.31C5.3 17.92 4.67 17.92 4.28 18.31L4.15 18.44C3.76 18.83 3.76 19.46 4.15 19.85Z" fill="#56676C"/>
            <path d="M21.92 13H22C22.55 13 22.96 12.55 22.96 12C22.96 11.45 22.47 11 21.92 11C21.37 11 20.92 11.45 20.92 12C20.92 12.55 21.37 13 21.92 13Z" fill="#56676C"/>
            <path d="M2 13H2.08C2.63 13 3.04 12.55 3.04 12C3.04 11.45 2.55 11 2 11C1.45 11 1 11.45 1 12C1 12.55 1.45 13 2 13Z" fill="#56676C"/>
            <path d="M18.3 5.7C18.5 5.89 18.75 5.99 19.01 5.99C19.27 5.99 19.52 5.89 19.71 5.7L19.84 5.57C20.23 5.18 20.23 4.55 19.84 4.16C19.45 3.77 18.82 3.77 18.43 4.16L18.3 4.29C17.91 4.68 17.91 5.31 18.3 5.7Z" fill="#56676C"/>
            <path d="M4.28 5.7C4.48 5.89 4.73 5.99 4.99 5.99C5.24 5.99 5.5 5.89 5.69 5.69C6.08 5.3 6.08 4.67 5.69 4.28L5.56 4.15C5.17 3.76 4.54 3.76 4.15 4.15C3.76 4.54 3.76 5.17 4.15 5.56L4.28 5.7Z" fill="#56676C"/>
            <path d="M11 2.08C11 2.63 11.45 3.04 12 3.04C12.55 3.04 13 2.55 13 2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2V2.08Z" fill="#56676C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.75 12C4.75 16 8 19.25 12 19.25C16 19.25 19.25 16 19.25 12C19.25 8 16 4.75 12 4.75C8 4.75 4.75 8 4.75 12ZM6.25 12C6.25 8.83 8.83 6.25 12 6.25C15.17 6.25 17.75 8.83 17.75 12C17.75 15.17 15.17 17.75 12 17.75C8.83 17.75 6.25 15.17 6.25 12Z" fill="#56676C"/>
        </svg>
    );
};

export default SunO;
