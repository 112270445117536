import http from './http-common'

let token = localStorage.getItem('token');

class AlarmService {
    create(data: any,customToken?:string) {
        return http.post<any>('alarms', data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-Type': 'application/x-www-form-urlencoded'

            },
        })
    }

    getAll(page?:any,filterParam='', customToken?:string){
        let url = `alarms?page=${page}`;
        if(filterParam.length>2){
            url+=`&name=${filterParam}`
        }
        return http.get<any>(url, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    getOne(id: any,customToken?:string) {
        return http.get<any>('alarms/' + id + '?with=pairs,exchange', {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    filter(id: any, data: any,customToken?:string) {
        return http.get<any>('alarms/' + id + '?with=' + data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    edit(id: any, data: any, customToken?:string) {
        return http.patch<any>('alarms/' + id, data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    }

    delete(id: any,customToken?:string) {
        return http.delete<any>('alarms/' + id, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }

    toggle(data: any, id: any, customToken?:string) {
        return http.patch<any>(`alarms/${id}/toggleStatus`, data, {
            headers: {
                'Authorization': 'Bearer ' + customToken || token
            },
        })
    }
}

export default new AlarmService()
