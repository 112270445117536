import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const SettingO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25024 12C8.25024 14.07 9.93024 15.75 12.0002 15.75C14.0702 15.75 15.7502 14.07 15.7502 12C15.7502 9.93 14.0702 8.25 12.0002 8.25C9.93024 8.25 8.25024 9.93 8.25024 12ZM9.75024 12C9.75024 10.76 10.7602 9.75 12.0002 9.75C13.2402 9.75 14.2502 10.76 14.2502 12C14.2502 13.24 13.2402 14.25 12.0002 14.25C10.7602 14.25 9.75024 13.24 9.75024 12Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1202 22.17C10.6502 22.48 11.3302 22.63 12.0002 22.63C12.6702 22.63 13.3502 22.48 13.8902 22.17L19.4302 18.97C21.6302 17.48 21.7602 17.25 21.7602 14.88V9.10999C21.7602 6.73999 21.6202 6.50999 19.3802 4.99999L13.8602 1.81999C12.8102 1.20999 11.1602 1.20999 10.1102 1.81999L4.57023 5.01999C2.37023 6.50999 2.24023 6.73999 2.24023 9.10999V14.89C2.24023 17.26 2.38023 17.49 4.62023 19L10.1202 22.17ZM10.8802 3.11999C11.1702 2.94999 11.5802 2.86999 12.0002 2.86999C12.4202 2.86999 12.8302 2.94999 13.1202 3.11999L18.5802 6.26999C19.4716 6.8731 19.8872 7.15432 20.0809 7.54543C20.2502 7.88711 20.2502 8.31267 20.2502 9.10999V14.88C20.2502 16.59 20.2502 16.59 18.6302 17.69L13.1302 20.87C12.5402 21.21 11.4702 21.21 10.8802 20.87L5.42023 17.72C4.5289 17.1169 4.1133 16.8357 3.91952 16.4445C3.75023 16.1029 3.75023 15.6773 3.75023 14.88V9.10999C3.75023 8.30389 3.75023 7.87779 3.92098 7.53732C4.11245 7.15555 4.51862 6.88145 5.38023 6.29999L10.8802 3.11999Z" fill={props.fill}/>
        </svg>
    );
};

export default SettingO;
