import PageTitles from "../../components/ds/PageTitle";
import { useTheme } from "styled-components";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import QuestionO from "../../assets/icons/Question-o";
import TextField from "../../components/ds/Textfield";
import TextAreaField from "../../components/ds/TextAreaField";
import Tag from "../../components/ds/Tag";
import Button from "../../components/ds/Button";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import BaseService from "../../services/base.service";
import { ErrorMessage, Formik } from "formik";
import WatchListService from "../../services/list.service";
import ArrowDownOutline from "../../assets/icons/Arrow-down-outline";
import Loading from "../../assets/icons/Loading";
import listService from "../../services/list.service";
import { useOutsideClick } from "../../components/ds/Hooks/useOutsideClick";
import noPhoto from "../../assets/images/no-photo.png";
import useAuth from "../../useAuth";
import { useCommon } from "../../context/commonContext";
import toast from "react-hot-toast";

export default function AddList() {
  const navigate = useNavigate();
  const text80Body = useTheme().colors.text80Body;
  const text30 = useTheme().colors.text30;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const surface80Divider = useTheme().colors.surface80Divider;
  const surface50Background = useTheme().colors.surface50Background;
  const primary50Main = useTheme().colors.primary50Main;
  const primary20 = useTheme().colors.primary20;

  
  const [strategiesResult, setStrategiesResult] = useState<any>([]);
  const [timeframeResult, setTimeframeResult] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [dropdown, setDropdown] = useState(false);
  const [edit, setEdit] = useState(false);
  const [buy, setBuy] = useState(false);
  const [sell, setSell] = useState(false);
  const [loading, setLoading] = useState(false);
  const error50Main = useTheme().colors.error50Main;
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState<any>("");
  const [formMode, setFormMode] = useState<any>("add");
  const [value, setValue] = useState<any>({ title: "", description: "" });
  const { token } = useAuth();


  const { exchange, pairsResult } = useCommon();
  const [exchangeResult, setExchangeResult] = useState<any>(exchange);
  const [result, setResult] = useState(pairsResult);
  const [resultFilter, setResultFilter] = useState(pairsResult);


  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setDropdown(false);
  });


  useEffect(() => {
    if (searchParams.get("id")) {
      setFormMode("edit");
      setUserId(searchParams.get("id"));
      setEdit(true);
      listService
        .getOne(searchParams.get("id"), token)
        .then((response) => {
          if (response && response.data) {
            const { entity } = response?.data;

            // check exchange 
            let newEx = exchange;
            newEx.map((item: any) => item.checked = false);
            newEx[exchange.findIndex((item: any) => item.id === entity.exchange_id)].checked = true
            setExchangeResult(newEx)

            setValue({
              title: entity?.title,
              description: entity?.description,
            });
            setSelected(entity?.pairs);
            if (entity.position_side === "all") {
              setBuy(true);
              setSell(true);
            } else if (entity.position_side === "buy") setBuy(true);
            else if (entity.position_side === "sell") setSell(true);
            BaseService.getStrategies(token)
              .then((response) => {
                let data = response.data;
                for (let sub of entity.strategy.split(",")) {
                  for (let item of data) {
                    if (item.id === parseInt(sub)) item.checked = true;
                  }
                }
                setStrategiesResult(data);
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              });
            BaseService.getTimeFrame(token)
              .then((response) => {
                let data = [];
                for (let item of response.data) {
                  data.push({ title: item, checked: false });
                }
                for (let sub of entity.time_frame.split(",")) {
                  for (let item of data) {
                    if (item.title === sub) item.checked = true;
                  }
                }
                setTimeframeResult(data);
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      BaseService.getStrategies(token)
        .then((response) => {
          setStrategiesResult(response.data);
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
      BaseService.getTimeFrame(token)
        .then((response) => {
          let data = [];
          for (let item of response.data) {
            data.push({ title: item, checked: false });
          }
          setTimeframeResult(data);
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
    }
  }, [searchParams]);

  function selectedPairs(item: any) {
    if (selected.findIndex((s: any) => s.id === item.id) === -1) {
      setSelected([...selected, item]);
    }
  }

  return (
    <>
      <NavLink to={"/lists"}>
        <PageTitles
          text={formMode === "add" ? "New List" : "Edit List"}
          arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />}
          icon={<QuestionO fill={useTheme().colors.primary50Main} />}
        />
      </NavLink>
      <div className={"w-full lg:w-4/5 2xl:w-3/5 mt-4"}>
        <Formik
          enableReinitialize
          initialValues={value}
          validate={(values) => {
            const errors: any = {};
            if (!values.title) {
              errors.title = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setLoading(true);
            setTimeout(() => {
              let params = new URLSearchParams();
              params.append("title", values.title);
              params.append("description", values.description);
              params.append(
                "position_side",
                buy && sell ? "all" : buy ? "buy" : "sell"
              );

              for (let item of selected) {
                params.append("pairs[]", item.id);
              }

              for (let item of exchangeResult) {
                if (item.checked) params.append("exchange", item.id);
              }

              for (let item of strategiesResult) {
                if (item.checked) params.append("strategy[]", item.id);
              }
              for (let item of timeframeResult) {
                if (item.checked) params.append("time_frame[]", item.title);
              }
              if (edit) {
                WatchListService.edit(userId, params, token)
                  .then(({ data }) => {
                    setSubmitting(false);
                    if (data) {
                      setLoading(false);
                      toast.success("List edited")
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    setSubmitting(false);
                    toast.error(err.response.data.message)
                  });
              } else {
                WatchListService.create(params, token)
                  .then(({ data }) => {
                    setSubmitting(false);
                    if (data) {
                      setLoading(false);
                      toast.success("List added")
                      navigate("/lists");
                    }
                  })
                  .catch((err) => {
                    // Do something
                    setLoading(false);
                    setSubmitting(false);
                    toast.error(err.response.data.message)
                  });
              }
            }, 400);
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,

          }) => (
            <form onSubmit={handleSubmit}>
              <div className={"mb-6"}>
                <TextField
                  size={"Big"}
                  label={"Title"}
                  name={"title"}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={"List Title"}
                  required={true}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className={`text-[${error50Main}]`}
                />
              </div>

              {/* set exchange comment (changed place) */}
              <div
                className={`border-b border-solid pb-6 mb-6 last:border-0 border-[${surface80Divider}]`}
              >
                <div
                  className={`text-base font-semibold mb-2 text-[${text50}]`}
                >
                  Exchanges
                </div>

                <div className={"flex items-center gap-2 flex-wrap"}>
                  {exchangeResult.map((item: any, index: number) => {
                    return (
                      <div
                        className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!exchangeResult[index].checked
                          ? `text-[${text30}]`
                          : `text-[${primary50Main}]`
                          } ${!exchangeResult[index].checked
                            ? `bg-transparent`
                            : `bg-[${primary20}]`
                          } ${!exchangeResult[index].checked
                            ? `border-[${surface80Divider}]`
                            : `border-[${primary50Main}]`
                          }`}
                        onClick={() => {

                          // let newEx = exchangeResult;
                          // newEx.map((item: any) => item.checked = false);
                          // newEx[index].checked = true;
                          // setExchangeResult(newEx)                          


                          exchangeResult.map(function (item: any, index: number) {
                            item.checked = false;
                          });
                          exchangeResult[index].checked = !exchangeResult[index].checked;
                          setExchangeResult([...exchangeResult]);
                        }}
                      >
                        <img
                          className={"h-full w-full w-8 h-8 rounded-full mr-2 "}
                          src={item.full_logo}
                        />

                        {item.name}
                        {item.details ? `(${item.details})` : null}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={"mb-6"} ref={wrapperRef}>
                <TextField
                  size={"Big"}
                  label={"Pairs"}
                  placeholder={"Type pairs here"}
                  required={true}
                  suffixIcon={<ArrowDownOutline fill={text50} />}
                  suffixOnClick={() => setDropdown(!dropdown)}
                  type={"autocomplete"}
                  onChange={(event: any) => {
                    if (event.target.value) {
                      setDropdown(true);
                      setResultFilter(
                        result.filter(
                          (item: any) =>
                            item.symbol
                              .toLowerCase()
                              .indexOf(event.target.value.toLowerCase()) > -1 ||
                            item.description
                              .toLowerCase()
                              .indexOf(event.target.value.toLowerCase()) > -1
                        )
                      );
                    } else {
                      setResultFilter(result);
                    }
                  }}
                  onBlur={handleBlur}
                />

                {dropdown && (
                  <div
                    className={`py-2 overflow-auto  h-60  mt-2 rounded-3xl border-[0.5px] border-solid border-[${surface80Divider}] bg-[${surface50Background}] shadow-[16px_24px_48px_rgba(27,30,34,0.08)]`}
                  >
                    {resultFilter.map((item: any) => {
                      return (
                        <div
                          className={`hover:cursor-pointer flex items-center justify-between py-2 px-6 hover:bg-[${surface50Background}]`}
                          key={item.id}
                          onClick={(e) => selectedPairs(item)}
                        >
                          <div className={"flex items-center"}>
                            <div className={"w-8 h-8 rounded-full  mr-2"}>
                              <img
                                className={"h-full w-full rounded-full"}
                                alt={item.symbol}
                                src={item.logo ? item.logo : noPhoto}
                              />
                            </div>
                            <div>
                              <div
                                className={`font-normal text-base text-[${text80Body}]`}
                              >
                                {item.symbol}
                              </div>
                              <div
                                className={`font-medium text-sm text-[${text50}]`}
                              >
                                {item.description}
                              </div>
                            </div>
                          </div>

                        </div>
                      );
                    })}
                  </div>
                )}

                <div className={"flex flex-wrap mt-2"}>
                  {selected.map((item: any) => {
                    return (
                      <Tag
                        icon={item.logo}
                        text={item.symbol}
                        onClick={() => {
                          setSelected([
                            ...selected.filter((s: any) => s.id !== item.id),
                          ]);
                        }}
                      />
                    );
                  })}
                </div>
              </div>

              <TextAreaField
                label={"Description (optional)"}
                name={"description"}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                required={false}
                placeholder={"Add some notes for yourself"}
              />

              <div className={`font-semibold mt-12 text-xl text-[${text90}]`}>
                Strategies
              </div>

              <div className="mt-12">
                <div
                  className={`border-b border-solid pb-6 mb-6 last:border-0 border-[${surface80Divider}]`}
                >
                  <div
                    className={`text-base font-semibold mb-2 text-[${text50}]`}
                  >
                    Position
                  </div>

                  <div className={"flex items-center gap-2"}>
                    <div
                      onClick={() => setBuy(!buy)}
                      className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!buy ? `text-[${text30}]` : `text-[${primary50Main}]`
                        } ${!buy ? `bg-transparent` : `bg-[${primary20}]`} ${!buy
                          ? `border-[${surface80Divider}]`
                          : `border-[${primary50Main}]`
                        }`}
                    >
                      Buy
                    </div>

                    <div
                      onClick={() => setSell(!sell)}
                      className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!sell ? `text-[${text30}]` : `text-[${primary50Main}]`
                        } ${!sell ? `bg-transparent` : `bg-[${primary20}]`} ${!sell
                          ? `border-[${surface80Divider}]`
                          : `border-[${primary50Main}]`
                        }`}
                    >
                      Sell
                    </div>
                  </div>
                </div>

                <div
                  className={`border-b border-solid pb-6 mb-6 last:border-0 border-[${surface80Divider}]`}
                >
                  <div
                    className={`text-base font-semibold mb-2 text-[${text50}]`}
                  >
                    Strategies
                    <span className={`text-[${error50Main}]`}> *</span>
                  </div>

                  <div className={"flex flex-wrap items-center gap-2"}>
                    {strategiesResult.map((item: any, index: number) => {
                      return (
                        <div
                          className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!item.checked
                            ? `text-[${text30}]`
                            : `text-[${primary50Main}]`
                            } ${!item.checked
                              ? `bg-transparent`
                              : `bg-[${primary20}]`
                            } ${!item.checked
                              ? `border-[${surface80Divider}]`
                              : `border-[${primary50Main}]`
                            }`}
                          onClick={() => {
                            strategiesResult[index].checked =
                              !strategiesResult[index].checked;
                            setStrategiesResult([...strategiesResult]);
                          }}
                        >
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  className={`border-b border-solid pb-6 mb-6 last:border-0 border-[${surface80Divider}]`}
                >
                  <div
                    className={`text-base font-semibold mb-2 text-[${text50}]`}
                  >
                    Timeframe
                    <span className={`text-[${error50Main}]`}> *</span>
                  </div>

                  <div className={"flex flex-wrap items-center gap-2"}>
                    {timeframeResult.map((item: any, index: number) => {
                      return (
                        <div
                          className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] ${!item.checked
                            ? `text-[${text30}]`
                            : `text-[${primary50Main}]`
                            } ${!item.checked
                              ? `bg-transparent`
                              : `bg-[${primary20}]`
                            } ${!item.checked
                              ? `border-[${surface80Divider}]`
                              : `border-[${primary50Main}]`
                            }`}
                          onClick={() => {
                            timeframeResult[index].checked =
                              !timeframeResult[index].checked;
                            setTimeframeResult([...timeframeResult]);
                          }}
                        >
                          {item.title}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                className={
                  "flex xl:w-2/4 items-center xl:justify-between justify-end  mr-0 pl-auto py-8 gap-3"
                }
              >
                <NavLink to={"/lists"} className={"xl:w-2/4"}>
                  <Button
                    text={"Cancel"}
                    className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 xl:w-full text-[${text50}]`}
                  />
                </NavLink>
                <Button
                  text={"Save"}
                  onClick={handleSubmit}
                  status={!isValid || selected.length === 0 || loading}
                  buttonStyleType={"BPrimary"}
                  loading={loading ? <Loading /> : ""}
                  className={"xl:w-2/4 h-12"}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
