import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import TextField from "../../../components/ds/Textfield";
import EyeSlashO from "../../../assets/icons/Eye-slash-o";
import EyeO from "../../../assets/icons/Eye-o";
import {
  createSearchParams,
  NavLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Button from "../../../components/ds/Button";
import { SingleCheckbox } from "../../../components/ds/Checkboxes";
import { Formik } from "formik";
import Modal from "react-modal";
import CloseO from "../../../assets/icons/Close-o";
import AuthService from "../../../services/auth.service";
import useAuth from "../../../useAuth";
import toast from "react-hot-toast";
import SignUpSvg from "../../../assets/svg-files/sign_up.svg";


export default function SignUp() {
  const [searchParams] = useSearchParams();
  const { login } = useAuth();

  const navigate = useNavigate();
  const [showPass, setShowPass] = React.useState(false);
  const [confirmShowPass, setConfirmShowPass] = React.useState(false);

  const [accept, setAccept] = React.useState(false);
  const text50 = useTheme().colors.text50;
  const text60 = useTheme().colors.text60;
  const text90 = useTheme().colors.text90;
  const text80Body = useTheme().colors.text80Body;
  const primary50Main = useTheme().colors.primary50Main;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [referral, setReferral] = React.useState<any>("");

  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "80%",
      display: "block",
      margin: "auto",
      overflow: "auto",
      maxWidth: "600px"
    },
  };

  const terms = [
    {
      id: 1,
      text: "Finomate is a tool for monitoring cryptocurrency market and there won't be any request for asking your account information on exchanges from us.",
    },
    {
      id: 2,
      text: "Finomate's main job is to find matched trading strategies for different cryptocurrencies and report it to user. By knowing this fact, we do not provide any recommendation to buy or sell your assets.",
    },
    {
      id: 3,
      text: "Finomate does not provide any financial or investment consultation.",
    },
    {
      id: 4,
      text: "Finomate is not responsible for any kind of asset losses due to trading cryptocurrencies.",
    },
    {
      id: 5,
      text: "We don't recommended any kind of exchange at all. We are not responsible for getting your account suspended.",
    },
    {
      id: 6,
      text: "We have right to update or change the providing services without notifying users.",
    },
    {
      id: 7,
      text: "We have complete right to do advertising in Finomate platform.",
    },
    {
      id: 8,
      text: "We do not approve or deny the validity of advertisements in Finomate.",
    },
    {
      id: 9,
      text: "If user forget his/her password and he/she had no access to the registered email, recovering Finomate account will be possible by only passing through authentication process.",
    },
    {
      id: 10,
      text: "Finomate Trade Assistant reserves the right to alter, revise, modify, and/or change these Terms at any time. Users will be acknowledged if any changes take place. Changes to our terms will be sent to users by email.",
    },
    {
      id: 11,
      text: "If we decide to stop this service for any reason, remaining days will refunded to the user with no right to protest.",
    },
    {
      id: 12,
      text: "After purchasing your subscription, you can use the platform for 7 days for free. In case you find out you don’t need it, you can request a refund. The refund request can be submitted only through our ticketing system. Remember that, upon finishing your 7 days period, your purchase considers final; it means you are satisfied with our service. After the 8th day, you cannot request a refund.",
    },
    {
      id: 13,
      text: "If maintenance time takes over 12 hours, 1 day will be added to the user's remaining days.",
    },
    {
      id: 14,
      text: "If you request the refund in 7 days period since you ordered Finomate, according to paragraph 13 of the terms of service, the paid amount will be refunded by us after 10 business days to your bank account.",
    },
  ];

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/");
    }

    if (searchParams.get("referral_id")) {
      const id = searchParams.get("referral_id");
      setReferral(id);
    }
    if (searchParams.get("onBoarding")) {
    }
  }, []);

  return (
    <div
      className={
        "flex xl:flex-row flex-col overflow-auto h-screen " +
        `bg-[${useTheme().colors.surface50Background}]`
      }
    >
      <div
        className={
          "xl:w-4/12 w-full h-full items-center xl:p-28 pt-16 px-28 relative " +
          `xl:bg-[${useTheme().colors.surface60}]`
        }
      >
        <img src={SignUpSvg} alt=""/>

        <div
          className={
            "font-normal text-base hidden xl:block absolute text-center right-0 left-0 bottom-8 " +
            `text-[${useTheme().colors.text30}]`
          }
        >
          ©2022{" "}
          <span className={`text-[${useTheme().colors.text50}]`}>Finomate</span>
        </div>
      </div>
      <div
        className={
          "xl:w-8/12 w-full h-full  2xl:px-80 xl:px-56 2xl:pt-40 xl:pt-6  overflow-x-auto p-4"
        }
      >
        <div
          className={
            "font-normal xl:text-4xl text-3xl mb-2 " +
            `text-[${useTheme().colors.text90}]`
          }
        >
          Create new Account
        </div>

        <div
          className={
            "font-normal xl:text-base text-xs mb-8 " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          We’re happy you’re trusting us. Fill the form to start working as fast
          as possible.
        </div>
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            password_confirmation: "",
            referral_id: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.first_name) {
              errors.first_name = "Required";
            }
            if (!values.last_name) {
              errors.last_name = "Required";
            }
            if (!values.password) {
              errors.password = "Required";
            }
            if (!values.password_confirmation) {
              errors.password_confirmation = "Required";
            }
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const params = new URLSearchParams();
            params.append("first_name", values.first_name);
            params.append("last_name", values.last_name);
            params.append("email", values.email);
            params.append("password", values.password);
            params.append(
              "password_confirmation",
              values.password_confirmation
            );
            params.append("referral_id", values.referral_id || referral);

            AuthService.register(params)
              .then(({ data }) => {
                if (data) {
                  localStorage.setItem("token", data.token);
                  localStorage.setItem("userEmail", data.user.email);
                  localStorage.setItem("userFirst_name", data.user.first_name);
                  localStorage.setItem("userLast_name", data.user.last_name);
                  localStorage.setItem("userRole", data.user.role);
                  login().then(() => {
                    navigate({
                      pathname: "/",
                      search: createSearchParams({
                        onBoarding: "onBoarding",
                      }).toString(),
                    });
                  });
                } 
              })
              .catch((err) => {
                toast.error(err.response.data.message);
              });
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={"flex gap-3"}>
                <TextField
                  type={"text"}
                  required={true}
                  name={"first_name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  size={"Big"}
                  label={"First Name"}
                  parentClassName={"xl:mb-6 mb-4 xl:w-1/2"}
                />
                <TextField
                  type={"text"}
                  required={true}
                  name={"last_name"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  size={"Big"}
                  label={"Last Name"}
                  parentClassName={"xl:mb-6 mb-4 xl:w-1/2"}
                />
              </div>
              <TextField
                type={"email"}
                required={true}
                name={"email"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                size={"Big"}
                label={"Email"}
                parentClassName={"xl:mb-6 mb-4"}
              />

              <TextField
                type={showPass ? "text" : "password"}
                name={"password"}
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                size={"Big"}
                label={"Password"}
                parentClassName={"xl:mb-6 mb-4"}
                hint={"Min 8 characters"}
                suffixOnClick={() => setShowPass(!showPass)}
                suffixIcon={
                  showPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />

              <TextField
                size={"Big"}
                label={"Confirm password"}
                required={true}
                value={values.password_confirmation}
                name={"password_confirmation"}
                onChange={handleChange}
                suffixOnClick={() => setConfirmShowPass(!confirmShowPass)}
                type={confirmShowPass ? "text" : "password"}
                parentClassName={"xl:mb-6 mb-4"}
                suffixIcon={
                  confirmShowPass ? (
                    <EyeSlashO fill={text50} />
                  ) : (
                    <EyeO fill={text50} />
                  )
                }
              />

              <TextField
                size={"Big"}
                name={"referral_id"}
                value={values.referral_id || referral}
                onChange={handleChange}
                label={"Referral ID (optional)"}
              />

              <div className={"flex items-center mt-6"}>
                <SingleCheckbox
                  onClick={() => setAccept(!accept)}
                  checked={accept}
                  value={1}
                  key={1}
                />

                <span className={"pl-2 " + `text-[${text80Body}]`}>
                  {" "}
                  I agree to{" "}
                  <a onClick={()=>setIsOpen(true)} className={`text-[${primary50Main}]`}>
                    terms and conditions.
                  </a>
                </span>
              </div>
              <Button
                buttonStyleType={"BPrimary"}
                text={"Sign up"}
                status={!isValid || !accept}
                className={"w-full h-12 mt-8"}
              />
            </form>
          )}
        </Formik>
        <div
          className={
            "font-normal xl:text-base text-xs mt-8 text-center mb-4 " +
            `text-[${useTheme().colors.text50}]`
          }
        >
          Have an account?
          <NavLink
            to={"/login"}
            className={
              "font-normal xl:text-base text-xs pl-1.5 " +
              `text-[${useTheme().colors.primary50Main}]`
            }
          >
            Sign in
          </NavLink>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={()=>setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
            Terms of use
          </div>

          <button onClick={()=>setIsOpen(false)}>
            <CloseO fill={text60} />
          </button>
        </div>

        {terms.map((item) => {
          return (
            <div className={"flex items-start mb-6"}>
              <div
                className={
                  "font-semibold text-[28px] " + `text-[${primary50Main}]`
                }
              >
                {item.id}
              </div>
              <div
                className={
                  "font-normal text-base  pl-4 " + `text-[${text80Body}]`
                }
              >
                {item.text}
              </div>
            </div>
          );
        })}
      </Modal>

    </div>
  );
}
