import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const EmailB = (props: any) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3H17C20 3 22 4.5 22 8V15.01C22 18.51 20 20.01 17 20.01H13.43C12.84 20.01 12.36 19.49 12.44 18.9C12.54 18.19 12.52 17.45 12.37 16.69C11.84 14.09 9.7 12.02 7.08 11.59C5.77 11.38 4.52 11.56 3.42 12.04C2.75 12.33 2 11.87 2 11.14V8C2 4.5 4 3 7 3ZM14.34 11.09L17.47 8.59C17.79 8.33 17.84 7.85 17.58 7.53C17.33 7.2 16.85 7.15 16.53 7.41L13.4 9.91C12.64 10.52 11.35 10.52 10.59 9.91L7.46 7.41C7.14 7.15 6.67 7.21 6.41 7.53C6.16 7.85 6.21 8.33 6.53 8.59L9.66 11.09C10.31 11.62 11.16 11.88 12 11.88C12.84 11.88 13.68 11.62 14.34 11.09Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1 18C1 15.23 3.24 13 6 13C8.77 13 11 15.24 11 18C11 20.76 8.76 23 6 23C3.24 23 1 20.77 1 18ZM6 15.96H3.96C3.58 15.96 3.28 16.27 3.28 16.64C3.28 17.01 3.58 17.32 3.96 17.32H6C6.38 17.32 6.68 17.01 6.68 16.64C6.68 16.27 6.38 15.96 6 15.96ZM3.28 19.36C3.28 19.73 3.58 20.04 3.96 20.04H8.04C8.42 20.04 8.73 19.73 8.73 19.36C8.73 18.99 8.43 18.68 8.05 18.68H3.96C3.59 18.68 3.28 18.99 3.28 19.36Z" fill={props.fill}/>
        </svg>
    );
};

export default EmailB;
