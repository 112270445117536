import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}

const ListO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75Z" fill={props.fill}/>
            <path d="M7.25 17C7.25 17.41 7.59 17.75 8 17.75H16C16.41 17.75 16.75 17.41 16.75 17C16.75 16.59 16.41 16.25 16 16.25H8C7.59 16.25 7.25 16.59 7.25 17Z" fill={props.fill}/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.33909 3.52523C7.66542 2.22236 8.84967 1.25 10.25 1.25H13.75C14.55 1.25 15.3 1.56 15.87 2.13C16.2636 2.52357 16.5332 3.00295 16.6624 3.52535C19.3742 3.74082 20.75 5.32899 20.75 8.25V18C20.75 21.02 19.02 22.75 16 22.75H8C4.98 22.75 3.25 21.02 3.25 18V8.25C3.25 5.32846 4.62633 3.74024 7.33909 3.52523ZM8.13 6.37C8.70001 6.94 9.45 7.25 10.25 7.25H13.75C13.774 7.25 13.798 7.24971 13.8219 7.24914C15.1802 7.21703 16.3159 6.27786 16.65 5.02C18.23 5.16 19.25 5.86 19.25 8.25V18C19.25 20.22 18.22 21.25 16 21.25H8C5.78 21.25 4.75 20.22 4.75 18V8.25C4.75 5.86037 5.76966 5.15023 7.34924 5.02007C7.48259 5.52483 7.74787 5.98786 8.13 6.37ZM15.25 4.25C15.25 5.08 14.58 5.75 13.75 5.75H10.25C9.85 5.75 9.47 5.59 9.19 5.31C8.91 5.03 8.75 4.65 8.75 4.25C8.75 3.42 9.42 2.75 10.25 2.75H13.75C14.15 2.75 14.53 2.91 14.81 3.19C15.09 3.47 15.25 3.85 15.25 4.25Z" fill={props.fill}/>
        </svg>
    );
};

export default ListO;
