import PageTitles from "../../components/ds/PageTitle";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import DashboardService from "../../services/dashboard.service";
import moment from "moment/moment";
import SearchResult from "../../components/ds/ResultPage/searchResult";
import useAuth from "../../useAuth";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "./Loading";

export default function Inbox() {
  const [result, setResult] = useState<any>([]);
  const [type, setType] = useState<any>("all");
  const text40 = useTheme().colors.text40;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const text80Body = useTheme().colors.text80Body;
  const surface80Divider = useTheme().colors.surface80Divider;
  const primary50Main = useTheme().colors.primary50Main;
  const [loading, setLoading] = React.useState(false);
  const { token } = useAuth();
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    GetInbox(type, page);
  }, [type, page]);

  function GetInbox(data: any, pageEnter: number) {

    DashboardService.getInboxes(data, token, pageEnter)
      .then((response: any) => {
        if (pageEnter === 1) {
          setResult(response.data.data);
        } else {
          setResult([...result, ...response.data.data]);
        }
        setLoading(false);
        if (response.data.meta.current_page !== response.data.meta.last_page) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  function ReadMessage(data: any) {
    setLoading(true);
    if (!data.read_at) {
      DashboardService.readInbox(data.id, token)
        .then((response) => {
          if (response) {
            setLoading(false);
            let index = result.findIndex((item: any) => item.id == data.id);
            let newResult = result;
            result[index].read_at = "1";
            setResult(newResult)
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
    }
  }

  return (
    <div >
      <div className={"mb-4 xl:mb-6"}>
        <PageTitles text={"Inbox"} />
      </div>

      <div className={"flex gap-1 xl:gap-3"}>
        <div
          className={
            "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
            `${type === "all"
              ? ` text-[${primary50Main}]`
              : ` text-[${text40}]`
            } ` +
            `${type === "all"
              ? ` border-[${primary50Main}]`
              : ` border-[${surface80Divider}]`
            }`
          }
          onClick={() => {
            setType("all");
          }}
        >
          All
        </div>
        <div
          className={
            "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
            `${type === "1" ? ` text-[${primary50Main}]` : ` text-[${text40}]`
            } ` +
            `${type === "1"
              ? ` border-[${primary50Main}]`
              : ` border-[${surface80Divider}]`
            }`
          }
          onClick={() => {
            setType("1");
          }}
        >
          Activities
        </div>
        <div
          className={
            "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
            `${type === "0" ? ` text-[${primary50Main}]` : ` text-[${text40}]`
            } ` +
            `${type === "0"
              ? ` border-[${primary50Main}]`
              : ` border-[${surface80Divider}]`
            }`
          }
          onClick={() => {
            setType("0");
          }}
        >
          Announcement
        </div>
        <div
          className={
            "hover:cursor-pointer border border-solid rounded-3xl px-4 xl:px-5 h-9 xl:h-12 flex items-center justify-center text-xs xl:text-base font-normal " +
            `${type === "2" ? ` text-[${primary50Main}]` : ` text-[${text40}]`
            } ` +
            `${type === "3"
              ? ` border-[${primary50Main}]`
              : ` border-[${surface80Divider}]`
            }`
          }
          onClick={() => {
            setType("2");
          }}
        >
          Updates
        </div>
      </div>

      <div className={`xl:my-6 my-3 w-full h-px bg-[${useTheme().colors.surface80Divider}]`}>
      </div>

      {result.length > 0 ? (
        <InfiniteScroll
          dataLength={result?.length} //This is important field to render the next data
          next={() => setPage(page + 1)}
          hasMore={hasMore}
          loader={<Loading />}
          height={"67vh"}
          className="md:pr-4"
        >
          {result?.map((item: any) => {
            return (
              <>
                <div
                  className={
                    "hover:cursor-pointer flex justify-between " +
                    `${!loading ? `hover:cursor-pointer` : `hover:cursor-wait`
                    }` +
                    ` ${loading ? ` opacity-50` : ""}`
                  }
                  onClick={() => ReadMessage(item)}
                >
                  <div className={"w-9/12"}>
                    <div className={"flex items-baseline"}>
                      {item.read_at ? (
                        ""
                      ) : (
                        <div
                          className={
                            "w-2 h-2 rounded-full mr-2 " +
                            `bg-[${primary50Main}]`
                          }
                        ></div>
                      )}
                      <div
                        className={
                          "xl:text-lg text-sm font-medium mb-2 " +
                          `text-[${text90}]`
                        }
                      >
                        {item.data.title}
                      </div>
                    </div>
                    <div
                      className={
                        "xl:font-base text-xs font-normal " +
                        `text-[${text80Body}]`
                      }
                      dangerouslySetInnerHTML={{ __html: item.data.text }}
                    />
                  </div>
                  <div
                    className={
                      "xl:text-sm text-[10px] font-medium " +
                      `text-[${text50}]`
                    }
                  >
                    {moment(item.created_at).format("MMM Do YYYY hh:mm")}
                  </div>
                </div>
                <div
                  className={
                    "xl:my-6 my-3 w-full h-px " + `bg-[${surface80Divider}]`
                  }
                ></div>
              </>
            );
          })}

        </InfiniteScroll>


      ) : (
        <SearchResult title={"No results"} />
      )}


      {/* {result.length ? (
        result?.map((item: any) => {
          return (
            <>
              <div
                className={
                  "hover:cursor-pointer flex justify-between " +
                  `${!loading ? `hover:cursor-pointer` : `hover:cursor-wait`
                  }` +
                  ` ${loading ? ` opacity-50` : ""}`
                }
                onClick={() => ReadMessage(item)}
              >
                <div className={"w-9/12"}>
                  <div className={"flex items-baseline"}>
                    {item.read_at ? (
                      ""
                    ) : (
                      <div
                        className={
                          "w-2 h-2 rounded-full mr-2 " +
                          `bg-[${primary50Main}]`
                        }
                      ></div>
                    )}
                    <div
                      className={
                        "xl:text-lg text-sm font-medium mb-2 " +
                        `text-[${text90}]`
                      }
                    >
                      {item.data.title}
                    </div>
                  </div>
                  <div
                    className={
                      "xl:font-base text-xs font-normal " +
                      `text-[${text80Body}]`
                    }
                    dangerouslySetInnerHTML={{ __html: item.data.text }}
                  />
                </div>
                <div
                  className={
                    "xl:text-sm text-[10px] font-medium " +
                    `text-[${text50}]`
                  }
                >
                  {moment(item.created_at).format("MMM Do YYYY hh:mm")}
                </div>
              </div>
              <div
                className={
                  "xl:my-6 my-3 w-full h-px " + `bg-[${surface80Divider}]`
                }
              ></div>
            </>
          );
        })
      ) : (
        <SearchResult title={"No results"} />
      )} */}
    </div>
  );
}
