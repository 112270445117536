import styled, {css} from "styled-components";

export const Input = styled.div<{ required: boolean, size: string, focus: any }>`
  label {
    font-weight: 500;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: ${({theme}) => theme.colors.text50};
  }

  ${({required}) => {
    if (required) {
      return css`
        label {
          &:after {
            content: '*';
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
            margin-left: 4px;
            color: ${({theme}) => theme.colors.error50Main};
          }
        }
      `
    }
  }}
  .inputHolderWrapper {
    min-width: 100%;
    background: ${({theme}) => theme.colors.surface50Background};
    border-color: ${({theme}) => theme.colors.surface80Divider};
    border-width: 1px;
    border-style: solid;
    border-radius: 24px;
    display: flex;
    align-items: center;

    .iconHolder {
      position: relative;
      display: flex;
      align-items: center;

      &:after {
        content: '|';
        color: ${({theme}) => theme.colors.surface80Divider};
        margin: auto 8px;
      }
    }

    input {
      height: calc(100% - 2px);
      width: 100%;
      border: unset;
      background: ${({theme}) => theme.colors.surface50Background};
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: ${({theme}) => theme.colors.text80Body};

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: ${({theme}) => theme.colors.text30};
        text-transform: capitalize;
      }
      
      &:focus {
        outline: none;
        border: none;
      }
      
    }
    
    input:-webkit-autofill{
      background-color: transparent!important;
    }

    input:autofill {
      background-color: transparent!important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({theme}) => theme.colors.text80Body};
      -webkit-box-shadow: 0 0 0px 1000px ${({theme}) => theme.colors.surface50Background} inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  ${({size}) => {
    if (size === 'Big') {
      return css`
        .inputHolderWrapper {
          height: 46px;
          padding: 0 20px;
        }
      `
    }

    if (size === 'Medium') {
      return css`
        .inputHolderWrapper {
          height: 34px;
          padding: 0 12px;
        }
      `
    }
  }}

  ${({focus}) => {
    if (focus) {
      return css`
        .inputHolderWrapper {
          border-color: ${({theme}) => theme.colors.primary50Main};
          border-width: 2px;
          border-style: solid;   
        }
      `
    }
  }}
`


export const Hint = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.text30};
  margin-top: 8px;

  @media screen and (max-width: 1280px){
    font-size: 8px;
  }
`