import { useTheme } from "styled-components";
import EditO from "../../../assets/icons/Edit-o";
import DeleteO from "../../../assets/icons/Delete-o";

export default function DropDown(props: any) {
    return (
        <div className={`w-48 xl:w-60 z-10 absolute right-6 border-[0.5px]  py-3 rounded-3xl shadow-[16px_24px_48px_rgba(27,30,34,0.08)] bg-[${useTheme().colors.surface50Background}] border-[${useTheme().colors.surface80Divider}]`}>
            <div onClick={(event) => {
                event.preventDefault()
                props.onEdit()
            }} className={`hover:cursor-pointer flex items-center py-4 px-6 hover:bg-[${useTheme().colors.surface60}]`}>
                <EditO fill={useTheme().colors.text50} />

                <div className={`text-base font-normal pl-3 text-[${useTheme().colors.text80Body}]`}>
                    Edit
                </div>
            </div>

            <div className={`hover:cursor-pointer flex items-center py-4 px-6 hover:bg-[${useTheme().colors.surface60}]`} onClick={(event) => {
                event.preventDefault()
                props.onDelete()
            }}>
                <DeleteO fill={useTheme().colors.error50Main} />

                <div className={`text-base font-normal pl-3 text-[${useTheme().colors.text80Body}]`}>
                    Delete
                </div>
            </div>
        </div>
    )
}
