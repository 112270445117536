import { useEffect, useState } from "react";
import Pusher from "pusher-js";

export const usePusher = (channelName: string, eventName: string) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    Pusher.logToConsole = true;
    const pusher = new Pusher("f6f5843086d76b9d7ead", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, setData);

    // Clean up function
    return () => {
      channel.unbind(eventName, setData);
      pusher.unsubscribe(channelName);
    };
  }, [channelName, eventName]);

  return data;
};
