import React, { useRef, useState } from "react";
import PageTitles from "../../components/ds/PageTitle";
import QuestionO from "../../assets/icons/Question-o";
import { useTheme } from "styled-components";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import Button from "../../components/ds/Button";
import AccountService from "../../services/account.service";
import ArrowDownOutline from "../../assets/icons/Arrow-down-outline";
import { useOutsideClick } from "../../components/ds/Hooks/useOutsideClick";
import settingMock from "../../assets/images/mock/setting.png";
import useAuth from "../../useAuth";
import toast from "react-hot-toast";

export default function Settings() {
  const navigate = useNavigate();
  const settingMenu = [
    { key: 1, title: "Profile", to: "/settings/profile" },
    { key: 2, title: "Security", to: "/settings/security" },
    {
      key: 3,
      title: "Exchange API",
      to: "/settings/exchangeAPI",
      disable: true,
    },
    { key: 4, title: "Billing", to: "/settings/billing" },
    { key: 5, title: "Gifts", to: "/settings/gifts" },
    { key: 6, title: "Invites", to: "/settings/invites" },
    { key: 7, title: "Sessions", to: "/settings/sessions" },
  ];
  const text30 = useTheme().colors.text30;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const text60 = useTheme().colors.text60;
  const text80Body = useTheme().colors.text80Body;
  const primary50Main = useTheme().colors.primary50Main;
  const primary20 = useTheme().colors.primary20;
  const surface80Divider = useTheme().colors.surface80Divider;
  const surface50Background = useTheme().colors.surface50Background;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [currentLink, setCurrentLink] = React.useState("profile");
  const wrapperRef = useRef(null);
  const [dropdown, setDropdown] = useState(false);
  const { token, logout } = useAuth();
  function handleLogout() {
    AccountService.logout(token)
      .then((response) => {
        if (response.status === 200) {
          logout();
          navigate("/login");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }
  useOutsideClick(wrapperRef, () => {
    setDropdown(false);
  });
  const customStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "fit-content",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
      maxWidth: "600px"
    },
  };

  function currentUrl(data: any) {
    const text = data.substr(10);
    setCurrentLink(text);
  }

  function ChangeRoute(data: any) {
    navigate(data);
    setDropdown(false);
  }

  function renderModal() {
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div className={"flex justify-between items-center mb-6"}>
            <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
              Logout
            </div>

            <button onClick={() => setIsOpen(false)}>
              <CloseO fill={text60} />
            </button>
          </div>

          <div
            className={"text-base font-normal mb-2 " + `text-[${text80Body}]`}
          >
            Are you sure you wanna logout your account?
          </div>
          {/*<div className={'text-sm font-medium ' + `text-[${useTheme().colors.text60}]`}>*/}
          {/*    Wanna remove your data too?*/}
          {/*</div>*/}

          <div className={"flex justify-end mt-6 gap-2"}>
            <Button
              text={"Cancel"}
              className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 text-[${text50}] border-[${surface80Divider}]`}
            />
            <Button
              buttonStyleType={"MWarning"}
              text={"Logout"}
              className={"flex h-12 justify-center items-center"}
              onClick={handleLogout}
            />
          </div>
        </Modal>
        <Modal
          isOpen={modalIsOpen2}
          onRequestClose={() => setIsOpen2(false)}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <div className={"flex justify-between items-center mb-6"}>
            <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
              How to use settings?
            </div>

            <button onClick={() => setIsOpen2(false)}>
              <CloseO fill={text60} />
            </button>
          </div>

          <div className={"w-full h-1/2"}>
            <img src={settingMock} />
          </div>

          <div className={"text-base " + `text-[${text80Body}]`}>
            click <a className={`text-[${primary50Main}]`}>here </a> to see
            more
          </div>
        </Modal>
      </>
    );

  }

  return (
    <>
      <div className={"mb-4 lg:mb-6"}>
        <PageTitles
          text={"Setting"}
          subTitle={currentLink}
          onClickIcon={() => setIsOpen2(true)}
          icon={<QuestionO fill={useTheme().colors.primary50Main} />}
        />
      </div>

      <div className={`mb-6 w-full h-px bg-[${useTheme().colors.surface80Divider}]`}></div>

      <div className={"flex flex-col lg:flex-row"}>
        <div
          className={
            "lg:w-1/5 2xl:w-1/6 border-r border-solid mr-6 hidden lg:block  " +
            `border-[${useTheme().colors.surface80Divider}]`
          }
        >
          <ul className={"flex flex-col gap-2"}>
            {settingMenu.map((item) => {
              if (item.disable) {
                return (
                  <li
                    className={
                      "px-4 h-12 flex items-center text-lg font-medium rounded-3xl cursor-not-allowed " +
                      `text-[${text50}]`
                    }
                  >
                    {item.title}
                  </li>
                );
              } else
                return (
                  <NavLink to={item.to} onClick={() => currentUrl(item.to)}>
                    {({ isActive }) => (
                      <li
                        className={
                          "px-4 h-12 flex items-center text-lg font-medium rounded-3xl " +
                          `${isActive ? `text-[${text90}]` : `text-[${text50}]`
                          } ` +
                          `${isActive ? `bg-[${primary20}]` : ""}`
                        }
                      >
                        {item.title}
                      </li>
                    )}
                  </NavLink>
                );
            })}
          </ul>

          <div
            className={
              "mb-2 mt-2 w-full h-px " +
              `bg-[${useTheme().colors.surface80Divider}]`
            }
          ></div>

          <div
            className={
              "px-4 h-12 flex items-center text-lg font-medium hover:cursor-pointer " +
              `text-[${useTheme().colors.error50Main}]`
            }
            onClick={() => setIsOpen(true)}
          >
            Logout
          </div>
        </div>

        <div className={"w-full lg:hidden"}>
          <div className={"relative mb-3"} ref={wrapperRef}>
            <div
              className={`flex   items-center justify-between border  border-solid rounded-xl h-9 px-5 border-[${surface80Divider}]`}
            >
              <span className={`text-base font-normal text-[${text30}]`}>
                {currentLink}
              </span>

              <div
                className={dropdown ? "rotate-180" : ""}
                onClick={(e) => setDropdown(!dropdown)}
              >
                <ArrowDownOutline fill={text50} />
              </div>
            </div>

            {dropdown && (
              <div className={`p-3 border border-solid rounded-xl border-[${surface80Divider}] bg-[${surface50Background}]`}>
                {settingMenu.map((item: any) => {
                  return (
                    <div
                      className={
                        "border-b border-solid py-2 last:border-none " +
                        `border-[${surface80Divider}]`
                      }
                    >
                      <div
                        className={
                          "text-xs font-normal " + `text-[${text50}]`
                        }
                        onClick={() => {
                          ChangeRoute(item.to);
                          currentUrl(item.to);
                        }}
                      >
                        {item.title}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className={"lg:w-5/6 lg:pl-6 h-[76.5vh] overflow-auto"}>
          <Outlet />
        </div>
      </div>

      {renderModal()}

    </>
  );
}
