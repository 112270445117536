import { useTheme } from "styled-components";
import MoneyO from "../../assets/icons/Money-o";
import Button from "../../components/ds/Button";
import CopyO from "../../assets/icons/Copy-o";
import TelegramO from "../../assets/icons/Telegram-o";
import React, { useEffect, useState } from "react";
import InviteService from "../../services/invite.service";
import { SingleCheckbox } from "../../components/ds/Checkboxes";
import { PieChart } from "react-minimal-pie-chart";
import Modal from "react-modal";
import CloseO from "../../assets/icons/Close-o";
import TextField from "../../components/ds/Textfield";
import inviteService from "../../services/invite.service";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useAuth from "../../useAuth";
import avatarPreview from "../../assets/images/avatarPreview.png";
import toast from "react-hot-toast";

export default function Invites() {
  const [friends, setFriends] = useState<any>([]);
  const [friendsCount, setFriendsCount] = useState<any>({
    pending: 0,
    registered: 0,
    subscribed: 0,
  });
  const [resultLevel, setResultLevel] = useState<any>([]);
  const [referralCode, setReferralCode] = useState<any>("");
  const [profit, setProfit] = useState<any>("");
  const surface60 = useTheme().colors.surface60;
  const text80Body = useTheme().colors.text80Body;
  const text40 = useTheme().colors.text40;
  const text50 = useTheme().colors.text50;
  const text90 = useTheme().colors.text90;
  const text60 = useTheme().colors.text60;
  const success50Main = useTheme().colors.success50Main;
  const success40 = useTheme().colors.success40;
  const primary50Main = useTheme().colors.primary50Main;
  const primary40 = useTheme().colors.primary40;

  const warning40 = useTheme().colors.warning40;
  const warning50Main = useTheme().colors.warning50Main;
  const error40 = useTheme().colors.error40;
  const error50Main = useTheme().colors.error50Main;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [nameInvite, setNameInvite] = React.useState("");
  const mode = useTheme().colors.mode;
  const [reward, setReward] = useState("");
  const { token } = useAuth();


  const customMobileStyles = {
    content: {
      backgroundColor: useTheme().colors.surface50Background,
      borderColor: useTheme().colors.surface50Background,
      width: "100%",
      maxWidth: "400px",
      padding: "1.5rem",
      borderRadius: "1.5rem",
      height: "fit-content",
      display: "block",
      margin: "auto",
    },
  };

  useEffect(() => {
    InviteService.getCurrentReward(token)
      .then((response) => {
        setReward(response?.data?.message);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });

    InviteService.levels(token)
      .then((response) => {
        setResultLevel(response.data.data);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });

    InviteService.profit(token)
      .then((response) => {
        setProfit(response.data.amount);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });

    InviteService.getMyReferralCode(token)
      .then((response) => {
        setReferralCode(response.data?.code);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
    InviteService.getMyFriends(token)
      .then((response) => {
        setFriends(response.data?.data?.list);
        setFriendsCount(response.data?.data?.counts);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }, []);

  function Status(data: any) {
    if (data === "pending") {
      return (
        <div
          className={` py-2 px-6 font-semibold text-xs lg:text-base rounded-[20px] bg-[${warning40}] text-[${warning50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    } else if (data === "subscribed") {
      return (
        <div
          className={`py-2 px-6 font-semibold text-xs lg:text-base rounded-[20px] bg-[${success40}] text-[${success50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    } else if (data === "registered") {
      return (
        <div
          className={`py-2 px-6 font-semibold text-xs lg:text-base rounded-[20px] bg-[${primary40}] text-[${primary50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    } else if (data === "failed") {
      return (
        <div
          className={`py-2 px-6 font-semibold text-xs lg:text-base rounded-[20px] bg-[${error40}] text-[${error50Main}] text-center rounded-2xl py-1 px-4`}
        >
          {data}
        </div>
      );
    }
  }

  function ChartStatus(data: any) {
    return (
      <>
        <div className={"flex items-center justify-between"}>
          <div className={"flex items-center"}>
            <div
              className={`w-3 h-3  bg-[${warning50Main}] rounded-full  mr-2`}
            ></div>

            <div className={"text-sm font-medium " + `text-[${text50}]`}>
              {"Pending"}
            </div>
          </div>
          <div
            className={"font-semibold text-base  pl-4  " + `text-[${text90}]`}
          >
            {friendsCount.pending}
          </div>
        </div>
        <div className={"flex items-center justify-between"}>
          <div className={"flex items-center"}>
            <div
              className={`w-3 h-3  bg-[${success50Main}] rounded-full  mr-2`}
            ></div>

            <div className={"text-sm font-medium " + `text-[${text50}]`}>
              {"Registered"}
            </div>
          </div>
          <div className={"font-semibold text-base pl-4 " + `text-[${text90}]`}>
            {friendsCount.registered}
          </div>
        </div>
        <div className={"flex items-center justify-between"}>
          <div className={"flex items-center"}>
            <div
              className={`w-3 h-3  bg-[${primary50Main}]  rounded-full  mr-2`}
            ></div>

            <div className={"text-sm font-medium " + `text-[${text50}]`}>
              {"Subscribed"}
            </div>
          </div>
          <div className={"font-semibold text-base pl-4 " + `text-[${text90}]`}>
            {friendsCount.subscribed}
          </div>
        </div>
      </>
    );
  }

  function changeReward(value: any) {
    setReward(value);
    let params = new URLSearchParams();
    params.append("mode", value);
    InviteService.changeReward(params, token)
      .then((response) => {
        if (value === "half") {
          resultLevel[0].status = "active";
          resultLevel[1].status = "pending"
        } else {
          resultLevel[0].status = "pending";
          resultLevel[1].status = "active"
        }
        toast.success(response.data.message)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  function renderModal() {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customMobileStyles}
      >
        <div className={"flex justify-between items-center mb-6"}>
          <div className={"text-2xl font-semibold " + `text-[${text90}]`}>
            Invite Friend
          </div>

          <button onClick={() => setIsOpen(false)}>
            <CloseO fill={text60} />
          </button>
        </div>

        <TextField
          value={nameInvite}
          onChange={(event: any) => setNameInvite(event.target.value)}
          size={"Big"}
          label={"name"}
        />
        <TextField
          value={email}
          onChange={(event: any) => setEmail(event.target.value)}
          size={"Big"}
          label={"Email"}
        />

        <div className={"flex justify-end mt-6 gap-2"}>
          <Button
            text={"Send Invite"}
            onClick={() => {
              if (nameInvite.length > 1 && email) {
                inviteService
                  .share(email, nameInvite, token)
                  .then((response: any) => {
                    setFriends([{
                      avatar: " ",
                      email: email,
                      name: nameInvite.length > 0 ? nameInvite : "guest",
                      status: "pending"
                    }, ...friends])

                    setEmail("")
                    setNameInvite("")
                    setIsOpen(false)
                    toast.success(response.data.message)
                  })
                  .catch((err) => {
                    toast.error(err.response.data.message)
                  });
              } else {
                toast.error("Entering name and email is required")
              }
            }
            }
            buttonStyleType={"BPrimary"}
            className={"font-medium w-full h-12"}
          />
        </div>
      </Modal>
    )
  }

  return (
    <div className={"w-full lg:w-4/5 2xl:w-4/5"}>
      <div
        className={
          "text-base lg:text-xl font-semibold mb-1.5 " +
          `text-[${useTheme().colors.text90}]`
        }
      >
        Invite your friends
      </div>

      <div
        className={
          "text-xs lg:text-base font-normal mb-6 " +
          `text-[${useTheme().colors.text80Body}]`
        }
      >
        For each friend that registers and subscribs wit your invite link, you
        will recieve 10% of their payment as reward. If more than 20 of your
        friends subscribe you will get 20% reward.
      </div>

      <div
        className={
          "p-2 lg:p-4 rounded-3xl w-full lg:w-max flex items-center gap-3 " +
          `bg-[${useTheme().colors.surface60}]`
        }
      >
        <div
          className={
            "w-12 h-12 rounded-full flex items-center justify-center " +
            `bg-[${useTheme().colors.success40}]`
          }
        >
          <MoneyO fill={useTheme().colors.success50Main} />
        </div>

        <div>
          <div
            className={
              "font-semibold text-base lg:text-xl mb-1 " +
              `text-[${useTheme().colors.text90}]`
            }
          >
            {profit}{" "}
            <sub
              className={
                "font-normal text-xs lg:text-base " +
                `text-[${useTheme().colors.text50}]`
              }
            >
              USDT
            </sub>
          </div>
          <div
            className={
              "text-xs lg:text-base font-normal " +
              `text-[${useTheme().colors.text50}]`
            }
          >
            Money earned from inviting your friends
          </div>
        </div>
      </div>

      <div
        className={
          "my-6 w-full h-px " + `bg-[${useTheme().colors.surface80Divider}]`
        }
      ></div>

      <div
        className={
          "text-xs lg:text-base font-semibold mb-1.5 " +
          `text-[${useTheme().colors.text50}]`
        }
      >
        Your invite code
      </div>

      <div className={"flex flex-col lg:flex-row justify-between gap-3"}>
        <div
          className={
            "h-12 w-full rounded-3xl py-2 px-4 lg:px-6 text-xs lg:text-base font-medium flex items-center " +
            `bg-[${useTheme().colors.surface70}] ` +
            `text-[${useTheme().colors.text50}]`
          }
        >
          {referralCode}
        </div>

        <div className={"flex gap-3"}>
          <CopyToClipboard
            text={referralCode}
          >
            <Button
              text={"Copy"}
              onClick={() =>{{
                navigator.clipboard.writeText(`https://dash.finomate.io/signUp?referral_id=` + referralCode)
                toast.success("copy done")
              }}
              }
              className={
                "flex w-1/2 lg:w-fit items-center justify-center text-xs lg:text-base h-12"
              }
              buttonStyleType={"BPrimary"}
              icon={<CopyO fill={useTheme().colors.text20} />}
            />
          </CopyToClipboard>

          <Button
            text={"Share"}
            className={
              "flex  w-1/2 lg:w-fit items-center justify-center text-xs lg:text-base h-12"
            }
            buttonStyleType={"BPrimary"}
            icon={<TelegramO fill={useTheme().colors.text20} />}
            onClick={() => setIsOpen(true)}
          />
        </div>
      </div>
      <div
        className={
          "my-6 w-full h-px " + `bg-[${useTheme().colors.surface80Divider}]`
        }
      ></div>

      <div className={"flex flex-col lg:flex-row"}>
        <div className={"lg:w-3/5"}>
          <div
            className={
              "text-xs lg:text-base font-semibold mb-1.5 " +
              `text-[${useTheme().colors.text90}]`
            }
          >
            Your Reward
          </div>

          <div
            className={
              "text-[8px] lg:text-sm font-normal mb-5 " +
              `text-[${useTheme().colors.text50}]`
            }
          >
            Your reward rate will be based on number of your friend that you
            invited to the app.
          </div>

          <div className={"flex mb-6"}>
            {resultLevel.map((item: any) => {
              return (
                <div
                  className={
                    "w-1/4 h-28 rounded-2xl flex flex-col items-center mr-3 justify-center py-4 px-6 " +
                    `bg-[${surface60}] ` +
                    `${item.status === "active"
                      ? `border-[${text90}] border-solid border `
                      : ``
                    }`
                  }
                >
                  <div
                    className={`font-semibold text-[28px] text-[${text80Body}]`}
                  >
                    {item.percent}%
                  </div>
                  <div className={`font-medium text-sm text-[${text40}]`}>
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>

          <div className={"text-base font-semibold " + `text-[${text90}]`}>
            Do you want to take the 20%reward or split it with your friend?
          </div>

          <div className={"flex my-2"}>
            <div className={"flex"}>
              <SingleCheckbox
                onClick={(event) => {
                  changeReward(event.target.value);
                }}
                checked={reward === "half"}
                value={"half"}
                key={1}
              />

              <span
                className={
                  "text-base font-normal pl-2 " + `text-[${text80Body}]`
                }
              >
                take 10% me and 10% my friend

              </span>
            </div>

            <div className={"flex pl-6"}>
              <SingleCheckbox
                onClick={(event) => {
                  changeReward(event.target.value);
                }}
                checked={reward === "full"}
                value={"full"}
                key={1}
              />

              <span
                className={
                  "text-base font-normal pl-2 " + `text-[${text80Body}]`
                }
              >
                take 20%
              </span>
            </div>
          </div>
        </div>
        <div className={"lg:w-2/5"}>
          <div
            className={
              "text-xs lg:text-base font-semibold mb-1.5 " +
              `text-[${useTheme().colors.text90}]`
            }
          >
            Invited Friends
          </div>
          <div className={"flex items-center gap-4"}>
            <div className={"lg:w-1/2"}>
              <PieChart
                lineWidth={25}
                data={[
                  {
                    title: "Pending",
                    value: friendsCount.pending,
                    color: warning50Main,
                  },
                  {
                    title: "Registerd",
                    value: friendsCount.registered,
                    color: success50Main,
                  },
                  {
                    title: "Subscribed",
                    value: friendsCount.subscribed,
                    color: primary50Main,
                  },
                ]}
              />
            </div>

            <div className={"lg:w-1/2"}>{ChartStatus(friendsCount)}</div>
          </div>
        </div>
      </div>

      <div
        className={
          "my-6 w-full h-px " + `bg-[${useTheme().colors.surface80Divider}]`
        }
      ></div>

      <div
        className={
          "text-xs lg:text-base font-semibold mb-1.5 " +
          `text-[${useTheme().colors.text50}]`
        }
      >
        Invited
      </div>
      {friends.length > 0 ? friends.map((item: any, index: number) => {
        return (
          <div className={"flex items-center justify-between mb-4"}>
            <div className={"flex items-center"}>
              <div className={"h-10 w-10 lg:w-16 lg:h-16 rounded-full "}>
                <img
                  className={"h-full w-full rounded-full"}
                  src={item.avatar && item.avatar !== 'https://core-api.finomate.io/assets/avatar.png' ? item.avatar : avatarPreview}
                />
              </div>
              <div className={"pl-3"}>
                <div
                  className={
                    "font-semibold text-xs lg:text-base " + `text-[${text90}]`
                  }
                >
                  {item.name}
                </div>
                <div
                  className={
                    "font-medium text-[10px] lg:text-sm " + `text-[${text50}]`
                  }
                >
                  {item.email}
                </div>
              </div>
            </div>
            {Status(item.status)}
          </div>
        );
      })
        :
        <p className={"text-xs lg:text-base font-semibold mb-1.5 text-sky-400"}>
          You have not invited anyone yet.
          You can use the benefits by inviting people.
        </p>
      }

      {renderModal()}
    </div>
  );
}
