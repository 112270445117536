import { Pattern } from "../Lists/listCartStyles";
import MoreO from "../../assets/icons/More-o";
import { useTheme } from "styled-components";
import DropDown from "../../components/ds/DropDown";
import { useEffect, useRef, useState } from "react";
import AlarmService from "../../services/alarm.service";
import { useOutsideClick } from "../../components/ds/Hooks/useOutsideClick";
import noPhoto from "../../assets/images/no-photo.png";
import useAuth from "../../useAuth";
import toast from "react-hot-toast";



export default function ListCart(props: any) {
  const [action, setAction] = useState(false);
  const { item } = props;
  const { index } = props;

  const surface60 = useTheme().colors.surface60;
  const surface70 = useTheme().colors.surface70;
  const text80Body = useTheme().colors.text80Body;
  const text90 = useTheme().colors.text90;
  const text60 = useTheme().colors.text60;
  const text50 = useTheme().colors.text50;
  const primary50Main = useTheme().colors.primary50Main;
  const surface50Background = useTheme().colors.surface50Background;
  const { token } = useAuth();

  function toggleAlarm(id: any) {
    let params = new URLSearchParams();
    params.append("_method", "patch");
    AlarmService.toggle(params, id, token)
      .then((response: any) => {
        if (response?.data?.changes?.is_active === false) {
          toast.success("Alarm turned off")
        } else {
          toast.success("Alarm turned on")
        }

        props.changeToggle(item.is_active);
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    setAction(false);
  });

  
  return (
    <>
      <div className={`table-row  bg-[${surface60}] hover:bg-[${surface70}]`}>
        <div
          className={`table-cell rounded-tl-2xl rounded-bl-2xl lg:rounded-tl-3xl lg:rounded-bl-3xl align-middle p-3  lg:py-5 lg:pl-6 text-left font-semibold text-base lg:text-xl text-[${text50}]`}
        >
          {index + 1}
        </div>
        <div
          className={`table-cell align-middle py-3 lg:py-5 text-left font-semibold text-base lg:text-xl text-[${text90}]`}
        >
          {item.name}
        </div>
        <div
          className={
            "hidden lg:table-cell align-middle  py-3 lg:py-5 text-center "
          }
        >
          <Pattern className={"flex relative "}>
            {item.pairs
              .filter((item: any, index: number) => index < 5)
              .map((filteredItem: any, i: number) => {
                return (
                  <div className={"logo"} style={{ left: i * -12 + "px" }}>
                    <img
                      className={"h-full w-full  rounded-full"}
                      src={filteredItem.logo ? filteredItem.logo : noPhoto}
                    />
                  </div>
                );
              })}

            {item?.pairs?.length > 5 && (
              <div
                className={
                  "total relative lg:py-2 lg:px-4 p-2 rounded-[20px] font-normal text-xs lg:text-base h-8 lg:h-10"
                }
                style={{ left: 5 * -12 + "px" }}
              >
                {item?.pairs?.length - 5} more
              </div>
            )}
          </Pattern>
        </div>
        <div
          onClick={() => toggleAlarm(item.id)}
          className={
            "table-cell align-middle  py-3 lg:py-5 text-right lg:text-center p-3 "
          }
        >
          <div
            className={
              "hover:cursor-pointer w-11 pl-auto h-6 rounded-xl relative " +
              `${item.is_active ? `bg-[${primary50Main}]` : `bg-[${surface70}]`
              }`
            }
          >
            <div
              className={
                "w-4 h-4 rounded-full absolute top-[4px] " +
                `bg-[${surface50Background}] ` +
                `${item.is_active ? `left-[4px]` : "right-[4px]"}`
              }
            ></div>
          </div>
        </div>
        <div
          ref={wrapperRef}
          className={`pr-4 relative table-cell rounded-tr-2xl rounded-br-2xl lg:rounded-tr-3xl lg:rounded-br-3xl align-middle  py-5 mr-6  text-left text-[${text80Body}]`}
        >
          <div
            className={"flex justify-end"}
            onClick={(event) => {
              event.preventDefault();
              setAction(!action);
            }}
          >
            <MoreO fill={text60} />
          </div>
          {action && (
            <DropDown
              onEdit={() => props.editAction()}
              onDelete={() => props.deleteAction(item.id)}
            />
          )}
        </div>
      </div>
      <div className={"h-4"}></div>
    </>
  );
}
