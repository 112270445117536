import Button from "../../components/ds/Button";
import UploadO from "../../assets/icons/Upload-o";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import avatarPreview from "../../assets/images/avatarPreview.png";
import React, { ChangeEvent, useEffect, useState } from "react";
import AccountService from "../../services/account.service";
import useAuth from "../../useAuth";
import { useCommon } from "../../context/commonContext";
import toast from "react-hot-toast";

export default function Profile() {
  const text50 = useTheme().colors.text50;
  const success50Main = useTheme().colors.success50Main;
  const { profile, editProfileHandler } = useCommon();
  const [result, setResult] = useState<any>(profile);
  const [avatar, setAvatar] = useState<any>(profile?.avatar);
  const hiddenFileInput = React.useRef<any>(null);

  const { token } = useAuth();

  useEffect(() => {
    if (profile) {
      setResult(profile);
      setAvatar(profile.avatar);
    }
  }, []);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setAvatar({
        file: e.target.files[0],
        displayMode: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  function updateProfile() {
    let formData = new FormData();
    formData.append("_method", "patch");
    formData.append("first_name", result.first_name);
    formData.append("last_name", result.last_name);
    if (avatar?.file) {
      formData.append("avatar", avatar.file);
      if (avatar.file?.size >= 1024000) {
        toast.error("Pleas upload a picture smaller than 1MB")
      } else {
        AccountService.updateProfile(formData, token)
          .then((response: any) => {
            toast.success(response.data.message)
            editProfileHandler({ ...profile, last_name: result.last_name, first_name: result.first_name, avatar: avatar.displayMode })
          })
          .catch((err) => {
            toast.error(err.response.data.message)
          });
      }
    } else {

      AccountService.updateProfile(formData, token)
        .then((response: any) => {
          console.log(avatar);
          toast.success(response.data.message)
          editProfileHandler({ ...profile, last_name: result.last_name, first_name: result.first_name })

        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
    }
  }

  function ConfirmEmail() {
    if (!result?.email_verified_at) {
      AccountService.verifyEmail(token)
        .then((response: any) => {
          toast.success("Email confirm successfully , Please check your email")
        })
        .catch((err) => {
          toast.error(err.response.data.message)
        });
    }
  }

  function clearAvatar() {
    let formData = new FormData();
    formData.append("_method", "patch");
    formData.append("avatar", "");
    AccountService.updateProfile(formData, token)
      .then((response: any) => {
        toast.success(response.data.message)
        setAvatar(null);
        editProfileHandler({ ...profile, avatar: null })
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }

  function renderAvatar() {
    if (avatar) {
      return (
        <img
          className={"h-full w-full object-cover rounded-full"}
          src={avatar.displayMode ? avatar.displayMode : avatar}
        />
      );
    } else {
      return (
        <img
          className={"h-full w-full object-cover rounded-full"}
          src={avatarPreview}
        />
      );
    }
  }

  return (
    <div className={"w-full lg:w-4/5  2xl:w-4/5"}>
      <div className={"flex items-center mb-12"}>
        <div
          className={"w-12 h-12 xl:w-24 xl:h-24 rounded-full bg-cyan-100 mr-4"}
        >
          {renderAvatar()}
        </div>
        <input
          type="file"
          onChange={handleFileChange}
          ref={hiddenFileInput}
          style={{ display: "none" }}
        />
        <Button
          onClick={() => hiddenFileInput.current.click()}
          buttonStyleType={"BPrimary"}
          text={"Upload"}
          className={"flex h-12 justify-center items-center"}
          icon={<UploadO fill={useTheme().colors.text20} />}
        />

        {profile?.avatar && (
          <Button
            text={"Delete"}
            onClick={() => clearAvatar()}
            className={
              "px-6 rounded-3xl border border-solid font-medium text-base h-12 ml-2 text-center " +
              `text-[${text50}]`
            }
          />
        )}
      </div>

      <div className={"xl:flex mb-6 gap-3"}>
        <div className={"xl:w-1/2"}>
          <TextField
            size={"Big"}
            label={"First Name"}
            name={"first_name"}
            onChange={(event: any) =>
              setResult({ ...result, first_name: event.target.value })
            }
            value={result?.first_name}
          />
        </div>
        <div className={"xl:w-1/2"}>
          <TextField
            size={"Big"}
            label={"Last Name"}
            name={"last_name"}
            onChange={(event: any) =>
              setResult({ ...result, last_name: event.target.value })
            }
            value={result?.last_name}
          />
        </div>
      </div>

      <div className={"xl:flex items-end mb-6 gap-3"}>
        <TextField
          size={"Big"}
          label={"Email"}
          readonly={true}
          value={result?.email}
          parentClassName={"w-full"}
        />

        <Button
          text={result?.email_verified_at ? "Verified" : "Confirm email"}
          onClick={() => ConfirmEmail()}
          className={
            "block px-4 mt-2 mr-0 pl-auto  xl:mt-0 xl:px-6 xl:w-40 r" +
            "ounded-3xl border border-solid font-medium text-xs xl:text-base h-12 box-content " +
            `${result?.email_verified_at
              ? ` text-[${success50Main}]`
              : ` text-[${text50}]`
            }` +
            ` border-[${useTheme().colors.surface80Divider}]` +
            `${result?.email_verified_at
              ? ` hover:cursor-not-allowed`
              : ` hover:cursor-pointer`
            }`
          }
        />
      </div>

      <div
        className={
          "flex xl:w-2/4 items-center justify-end   mr-0 pl-auto mt-12 py-8 gap-3 "
        }
      >
        {/*<Button text={'Cancel'}*/}
        {/*        className={'px-6 rounded-3xl border border-solid font-medium text-base h-12 xl:w-2/4 ' + `text-[${useTheme().colors.text50}]`}/>*/}
        <Button
          text={"Save changes"}
          onClick={() => updateProfile()}
          buttonStyleType={"BPrimary"}
          className={"font-medium xl:wfull h-12"}
        />
      </div>
    </div>
  );
}
