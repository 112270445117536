import React from "react";

export interface IconProperty{
    fill: string | '#56676C';
}


const DeleteO = (props: IconProperty) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.97977 3.54L7.76603 4.81271C6.82864 4.86361 5.89308 4.93526 4.95967 5.03001L2.91967 5.23001C2.50967 5.27001 2.20967 5.63001 2.24967 6.05001C2.28967 6.47001 2.65967 6.77001 3.07967 6.73001L5.11967 6.53001C10.3497 6.00001 15.6297 6.20001 20.9197 6.73001H20.9997C21.3797 6.73001 21.7097 6.44001 21.7397 6.05001C21.7797 5.64001 21.4797 5.27001 21.0697 5.23001C19.4584 5.07187 17.8481 4.94221 16.2402 4.85247L16.2398 4.85L16.0198 3.55L16.0188 3.54413C15.8688 2.62414 15.6448 1.25 13.3098 1.25H10.6898C8.36143 1.25 8.14008 2.5781 7.98011 3.53794L7.97977 3.54ZM14.5498 3.8L14.7156 4.77967C12.9059 4.7083 11.1001 4.69217 9.30034 4.74763L9.46977 3.79L9.47128 3.781C9.61991 2.89906 9.64335 2.76 10.6998 2.76H13.3198C14.3798 2.76 14.4098 2.93 14.5498 3.8ZM8.78965 22.75H15.2097C18.6997 22.75 18.8397 20.82 18.9497 19.26L19.5997 9.19001C19.6297 8.77001 19.3097 8.42001 18.8997 8.39001C18.4897 8.37001 18.1297 8.68001 18.0997 9.09001L17.4497 19.16C17.3497 20.68 17.3097 21.25 15.2097 21.25H8.78965C6.7009 21.25 6.6597 20.6807 6.54985 19.1627L6.54965 19.16L5.89965 9.09001C5.86965 8.68001 5.51965 8.37001 5.09965 8.39001C4.68965 8.42001 4.36965 8.78001 4.39965 9.19001L5.04965 19.26C5.15965 20.82 5.29965 22.75 8.78965 22.75ZM13.6598 17.25H10.3298C9.91983 17.25 9.57983 16.91 9.57983 16.5C9.57983 16.09 9.91983 15.75 10.3298 15.75H13.6598C14.0698 15.75 14.4098 16.09 14.4098 16.5C14.4098 16.91 14.0698 17.25 13.6598 17.25ZM9.49976 13.25H14.4998C14.9098 13.25 15.2498 12.91 15.2498 12.5C15.2498 12.09 14.9098 11.75 14.4998 11.75H9.49976C9.08976 11.75 8.74976 12.09 8.74976 12.5C8.74976 12.91 9.08976 13.25 9.49976 13.25Z" fill={props.fill}/>
        </svg>
    );
};

export default DeleteO;
