import { NavLink, useNavigate } from "react-router-dom";
import PageTitles from "../../components/ds/PageTitle";
import ArrowLeft2Outline from "../../assets/icons/Arrow-left2-outline";
import { useTheme } from "styled-components";
import TextField from "../../components/ds/Textfield";
import Button from "../../components/ds/Button";
import React, { useEffect, useState } from "react";
import BaseService from "../../services/base.service";
import TextAreaField from "../../components/ds/TextAreaField";
import { ErrorMessage, Formik } from "formik";
import TicketService from "../../services/ticket.service";
import Loading from "../../assets/icons/Loading";
import FileUpload from "../../components/ds/UploadFile";
import useAuth from "../../useAuth";
import toast from "react-hot-toast";

export default function AddTicket() {
  const navigate = useNavigate();
  const surface80Divider = useTheme().colors.surface80Divider;
  const error50Main = useTheme().colors.error50Main;
  const text50 = useTheme().colors.text50;
  const text30 = useTheme().colors.text30;
  const [resultDepartments, setResultDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const { token } = useAuth();
  const [selectDepartments, setSelectDepartments] = useState<any>(0)
  const primary50Main = useTheme().colors.primary50Main;
  const primary20 = useTheme().colors.primary20;

  useEffect(() => {
    BaseService.getDepartments(token)
      .then((response: any) => {
        setResultDepartments(response.data);
        setSelectDepartments(response.data[response.data.length - 1].id)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      });
  }, []);

  return (
    <>
      <NavLink to={"/tickets"}>
        <PageTitles
          text={"New Ticket"}
          arrow={<ArrowLeft2Outline fill={useTheme().colors.text50} />}
        />
      </NavLink>

      <div className={"w-full lg:w-4/5 2xl:w-3/5 mt-4"}>
        <Formik
          initialValues={{ subject: "", message: "" }}

          validate={(values) => {
            const errors: any = {};
            if (!values.subject) {
              errors.subject = "Required";
            }
            if (!values.message) {
              errors.message = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let form = new FormData();
            form.append("subject", values.subject);
            form.append("department_id", selectDepartments.toString());
            form.append("message", values.message);
            for (let item of files) {
              form.append("files[]", item);
            }
            setLoading(true);
            TicketService.create(form, token)
              .then((response: any) => {
                if (response) {
                  setLoading(false);
                  toast.success(response.data.message)
                  navigate("/tickets");
                }
              })
              .catch((err) => {
                toast.error(err.response.data.message)
              });
            setSubmitting(false);
          }}
        >
          {({
            values,
            isValid,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={"mb-6"}>
                <TextField
                  size={"Big"}
                  name={"subject"}
                  value={values.subject}
                  onChange={handleChange}
                  label={"Title"}
                  placeholder={"Ticket Title"}
                  required={true}
                />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className={`text-[${error50Main}]`}
                />
                <div className={"mt-2 mb-3"}>
                  <label className={`text-sm font-medium text-[${text50}]`}>
                    Department
                    <span className={`text-[${error50Main}]`}> *</span>
                  </label>
                  <div className={"mt-3 w-full xl:w-1/4 flex items-center gap-2 flex-wrap md:flex-nowrap"}>
                    {resultDepartments.map((item: any) => {
                      return (
                        <div
                          onClick={() => setSelectDepartments(item.id)}
                          className={`hover:cursor-pointer border  border-solid rounded-3xl flex items-center h-12 text-base font-normal px-[20px] 
                          ${selectDepartments !== item.id ? `text-[${text30}]` : `text-[${primary50Main}]`} 
                          ${selectDepartments !== item.id ? `bg-transparent` : `bg-[${primary20}]`} 
                          ${selectDepartments !== item.id ? `border-[${surface80Divider}]` : `border-[${primary50Main}]`
                            }`}>
                          {item.name}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <TextAreaField
                  size={"Big"}
                  name={"message"}
                  value={values.message}
                  onChange={handleChange}
                  label={"Message"}
                  placeholder={"Ticket Message"}
                  required={true}
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className={`text-[${error50Main}]`}
                />

                <FileUpload
                  selectFile={(event: any) => {
                    setFiles([...files, event]);
                  }}
                  setFile={(file: any) => setFiles(file)}
                />
              </div>

              <div
                className={
                  "flex lg:w-2/4 items-center lg:justify-between justify-end  mr-0 pl-auto py-8 gap-3 "
                }
              >
                <NavLink className={"lg:w-2/4"} to={"/tickets"}>
                  <Button
                    text={"Cancel"}
                    className={`px-6 rounded-3xl border border-solid font-medium text-base h-12 w-full text-[${text50}]`}
                  />
                </NavLink>
                <Button
                  text={"Add ticket"}
                  status={!isValid || loading}
                  loading={loading ? <Loading /> : ""}
                  buttonStyleType={"BPrimary"}
                  className={"lg:w-2/4 h-12"}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}
